import Iconify from 'src/components/Iconify';
import SummariesTable from './Table';

export const tabs = [
	{
		value: 'live',
		label: 'Live',
		icon: <Iconify icon='grommet-icons:play-fill' width={20} height={20} />,
		component: SummariesTable,
	},
	{
		value: 'frozen',
		label: 'Standby',
		icon: <Iconify icon='grommet-icons:pause-fill' width={20} height={20} />,
		component: SummariesTable,
	},
	{
		value: 'demo',
		label: 'Demo',
		icon: <Iconify icon='mingcute:toy-horse-fill' width={20} height={20} />,
		component: SummariesTable,
	},
	{
		value: 'deleted',
		label: 'Defunct',
		icon: <Iconify icon='la:skull-crossbones' width={25} height={25} />,
		component: SummariesTable,
	},
	{
		value: 'archived',
		label: 'Completions',
		icon: <Iconify icon='material-symbols:archive' width={20} height={20} />,
		component: SummariesTable,
	},
];
