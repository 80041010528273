import { Box } from '@mui/system';
import { FormProvider, useForm } from 'react-hook-form';
import RHFDateRangePicker from 'src/components/hook-form/RHFDateRangePicker';
import { LoadingButton } from '@mui/lab';
import { dispatch, useSelector } from 'src/redux/store';
import {
	getTransactionSummaries,
	selectTransactionsSummaries,
	updateTransactionSummariesFilters,
} from 'src/redux/slices/tableSlice';
import { FC, useEffect, useMemo } from 'react';
import { selectUserSettings } from 'src/redux/slices/auth';
import { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { forceUpdateUserData } from 'src/api/auth/user-change';
import { FiltersProps, TransactionSummariesFilters } from './types';
import { convertFiltersToForm, convertUsersToOptions } from './utils';

const Filters: FC<FiltersProps> = ({ handleClose }) => {
	const settings = useSelector(selectUserSettings);
	const { sellers, buyers } = useSelector(selectTransactionsSummaries);
	const form = useForm<TransactionSummariesFilters>();

	const sellersOptions = useMemo(() => convertUsersToOptions(sellers), [sellers]);
	const buyersOptions = useMemo(() => convertUsersToOptions(buyers), [buyers]);

	useEffect(() => {
		form.reset(convertFiltersToForm(settings.summariesFilters));
	}, [settings.summariesFilters]);

	const handleSubmit = async (data: TransactionSummariesFilters) => {
		await dispatch(updateTransactionSummariesFilters({ filters: data })).unwrap();
		await forceUpdateUserData();
		handleClose();
		dispatch(getTransactionSummaries());
	};

	const handleClear = () => {
		form.reset({
			// trackerStatus: {},
			instructionDate: {},
			buyers: {},
			sellers: {},
			name: { inc: '' },
			postcode: { inc: '' },
			uprn: { inc: '' },
		});
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<Box
					px='15px'
					py='15px'
					maxHeight='400px'
					overflow='auto'
					minWidth='300px'
					display='flex'
					flexDirection='column'
					gap='20px'
				>
					<RHFDateRangePicker name='instructionDate' label='Filter By Instruction Date' />
					<RHFDateRangePicker name='latestActionDate' label='Filter By Last Updated On' />
					{/* <RHFSelect name='trackerStatus.eq' loading={false} label='Filter by Tracker Status'>
						<option value='sellerDueDiligence'>Seller Due Diligence</option>
						<option value='listing'>Listing</option>
						<option value='saleAgreed'>Sale Agreed</option>
						<option value='buyerDueDiligence'>Buyer Due Diligence</option>
						<option value='exchange'>Exchange</option>
						<option value='completion'>Completion</option>
						<option value='empty'>Empty</option>
					</RHFSelect> */}
					<RHFAutocomplete
						name='sellers.inc'
						valueAttribute='id'
						options={sellersOptions}
						placeholder='Filter By Seller'
					/>
					<RHFAutocomplete
						name='buyers.inc'
						valueAttribute='id'
						options={buyersOptions}
						placeholder='Filter By Buyer'
					/>
					<RHFTextField name='name.inc' placeholder='Filter By Name' />
					<RHFTextField name='postcode.inc' placeholder='Filter By Postcode' />
					<RHFTextField name='uprn.inc' placeholder='Filter By UPRN' />
				</Box>
				<Box px='17px' pt='5px' pb='10px' display='flex' gap='20px' justifyContent='flex-end'>
					<LoadingButton variant='contained' loading={form.formState.isSubmitting} onClick={handleClear}>
						Clear all
					</LoadingButton>
					<LoadingButton variant='contained' loading={form.formState.isSubmitting} type='submit'>
						Save
					</LoadingButton>
				</Box>
			</form>
		</FormProvider>
	);
};

export default Filters;
