import { Branch, Business } from 'src/types';

export const firmsSorter = (firstFirm: Business, secondFirm: Business) => {
	const fn = firstFirm.tradingName.toLowerCase();
	const sn = secondFirm.tradingName.toLowerCase();

	if (fn < sn) return -1;
	if (sn > fn) return 1;

	return 0;
};

export const branchesSorter = (firstBranch: Branch, secondBranch: Branch) => {
	const fn = firstBranch.name.toLowerCase();
	const sn = secondBranch.name.toLowerCase();

	if (fn < sn) return -1;
	if (sn > fn) return 1;

	return 0;
};
