import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Box, Stack, Typography } from '@mui/material';
// components
import GoogleAddressAutocomplete from 'src/components/GoogleAddressAutoComplete';
import { FormProvider, RHFAutocomplete, RHFPhoneNumberInput, RHFSelect, RHFTextField } from 'src/components/hook-form';
// utils
import { Branch, Business } from 'src/types';
import { updateUser } from 'src/utils/authFirebase';
import { ninoRegExp } from 'src/constants';
import { userTitlesOptions } from 'src/pages/account-settings/constants';
import { noop } from 'lodash';
import { defaultValues, getSchema } from './consts';

import { AccountPhoto } from './AccountPhoto';
import { BoxAccountGeneralStyle, LoadingButtonStyle } from './style';
import PreferredMethodCategory from './PreferredMethodCategory';

// ----------------------------------------------------------------------

async function getData<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	SV extends (...args: any[]) => void,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	SF extends (value: any) => void,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	SB extends (value: any) => void,
>(userData: ReturnType<typeof selectUser>, setValue: SV, setDefaultValueFirms: SF, setDefaultValueBranches: SB) {
	try {
		if (userData) {
			const {
				givenNames,
				lastNames,
				knownAs,
				email,
				telephone: userTelephone,
				landline,
				title,
				preferredContactMethods,
				website,
			} = userData;
			setValue('givenNames', givenNames);
			setValue('lastNames', lastNames);
			setValue('knownAs', knownAs ?? '');
			setValue('email', email);
			setValue('website', website || '');
			if (userTelephone) setValue('telephone', userTelephone);
			if (landline) setValue('landline', landline);
			if (userData?.nino) setValue('nino', userData.nino);
			if (title) setValue('title', title);
			if (preferredContactMethods) setValue('preferredContactMethods', preferredContactMethods);
			userData
				.getAddress()
				.then(({ country, street, postcode, city, county, state, telephone }) => {
					setValue('address.country', country || 'United Kingdom');
					if (street) setValue('address.street', street);
					if (postcode) setValue('address.postcode', postcode);
					if (city) setValue('address.city', city);
					if (county) setValue('address.county', county);
					if (state) setValue('address.state', state);
					if (telephone) setValue('telephone', telephone);
				})
				.catch(console.error);
			userData
				.getBusiness()
				.then(({ business, branch }) => {
					if (business) {
						setValue('business', business.id || business.data.tradingName);
						setDefaultValueFirms(business.data);
					}
					if (branch) {
						setValue('branch', branch.id || branch.data.name);
						setDefaultValueBranches(branch.data);
					}
				})
				.catch(console.error);
		}
	} catch (error) {
		console.error(error);
	}
}

export default function AccountGeneral() {
	const userData = useSelector(selectUser);
	const { enqueueSnackbar } = useSnackbar();

	const [isEdited, setEdited] = useState(false);
	const [defaultValueBranches, setDefaultValueBranches] = useState<Branch | null>(null);
	const [defaultValueFirms, setDefaultValueFirms] = useState<Business | null>(null);
	const isBusinessRole =
		userData.isEstateAgent ||
		userData.isSolicitorConveyancer ||
		userData.isTradesPerson ||
		userData.isSurveyor ||
		userData.isMortgageBroker;

	const isCustomBusiness = typeof userData.business === 'string' && typeof userData.business === 'string';

	const methods = useForm({
		resolver: yupResolver(getSchema(isBusinessRole)),
		defaultValues,
	});
	const {
		handleSubmit,
		setValue,
		clearErrors,
		formState: { isSubmitting },
	} = methods;

	useEffect(() => {
		getData(userData, setValue, setDefaultValueFirms, setDefaultValueBranches);
	}, [userData]);

	// const handleFirmChange = async (data) => {
	// 	setDefaultValueBranches(null);
	// 	setValue('branch', '');
	// 	await fetchBranches(data.id.toString());
	// };

	// const handleBranchChange = async (data) => {
	// 	setDefaultValueBranches(data);
	// 	const city = await getDataByRef<TownCities>(data.townCity);
	// 	const state = await getDataByRef<StateRegions>(city?.stateRegion);
	// 	const street = data.streetAddress ? data.streetAddress : data.address;
	// 	setValue('address.street', street);
	// 	setValue('address.postcode', data.postcode);
	// 	setValue('address.city', city.name);
	// 	setValue('address.county', city.countyProvince);
	// 	setValue('address.state', state.name);
	// };

	const onSubmit = async (data) => {
		if (!isBusinessRole && !ninoRegExp.test(data.nino)) {
			enqueueSnackbar('Invalid NINO. Must be 2 letters, 6 numbers, 1 letter.', { variant: 'error' });
			return;
		}
		await updateUser(userData, data, setEdited, enqueueSnackbar);
	};

	const handleClearErrors = (val) => clearErrors(val);
	const handleSetValue = (name, val) => setValue(name, val);

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Stack flexDirection='row' columnGap='50px' justifyContent='space-between' alignItems='flex-start'>
				<Box>
					<AccountPhoto photo={userData?.profilePhoto} />
					<Box>
						<Typography fontSize='18px' fontWeight={600}>
							Preferred contact methods
						</Typography>
						<Typography color='grey.500' fontSize='15px'>
							Tell us how you'd like to hear from us and how you'd prefer to reach us.
						</Typography>
						<Typography color='grey.500' fontSize='15px'>
							Please choose at least one contact method for each category.
						</Typography>
						<Box mt='10px' display='flex' justifyContent='space-between'>
							<PreferredMethodCategory
								label='Voice'
								name='preferredContactMethods.voice'
								options={[
									{ label: 'Mobile', name: 'mobile' },
									{ label: 'Landline', name: 'landline' },
								]}
							/>
							<PreferredMethodCategory
								label='Message'
								name='preferredContactMethods.message'
								options={[
									{ label: 'WhatsApp', name: 'whatsApp' },
									{ label: 'Text', name: 'text' },
								]}
							/>
							<PreferredMethodCategory
								label='Mail'
								name='preferredContactMethods.mail'
								disabled
								options={[
									{ label: 'Email', name: 'email' },
									{ label: 'Post', name: 'post' },
								]}
							/>
						</Box>
					</Box>
				</Box>
				<Stack alignItems='flex-end' width='55%' pr='30px'>
					<BoxAccountGeneralStyle>
						<RHFSelect name='title' label='Select a title' loading={false}>
							{userTitlesOptions.map((title) => (
								<option value={title.key} key={title.key}>
									{title.name}
								</option>
							))}
						</RHFSelect>
						<RHFTextField name='givenNames' label='Given Name(s)' shrink disabled={userData.isNameLocked} />
						<RHFTextField name='knownAs' label='Known As' shrink />
						<RHFTextField name='lastNames' label='Last Name(s)' shrink disabled={userData.isNameLocked} />
						<RHFTextField disabled name='email' label='Email' shrink />

						<RHFTextField disabled name='address.country' label='Country' placeholder='Country' shrink />
						{isCustomBusiness ? (
							<RHFTextField
								name='business'
								label='Business'
								shrink
								placeholder={defaultValueFirms?.tradingName}
								disabled
							/>
						) : isBusinessRole ? (
							// eslint-disable-next-line
							<div onClick={noop}>
								<RHFAutocomplete
									handleChange={noop}
									disabled
									name='business'
									label='Firm'
									placeholder={defaultValueFirms?.tradingName || ''}
									options={[]}
									InputLabelProps={{ shrink: Boolean(defaultValueFirms?.legalName) }}
									labelAttribute='tradingName'
								/>
							</div>
						) : null}
						{isCustomBusiness ? (
							<RHFTextField name='branch' label='Branch' shrink placeholder={defaultValueBranches?.name} disabled />
						) : isBusinessRole ? (
							<RHFAutocomplete
								handleChange={noop}
								disabled
								name='branch'
								label='Branch'
								placeholder={defaultValueBranches?.name || ''}
								options={[]}
								InputLabelProps={{ shrink: Boolean(defaultValueBranches?.name) }}
							/>
						) : null}

						{isBusinessRole && (
							<GoogleAddressAutocomplete
								disabled
								name='address.street'
								label='Street'
								setValue={handleSetValue}
								clearErrors={handleClearErrors}
								isDisabled
							/>
						)}
						{isBusinessRole && (
							<RHFTextField disabled name='address.city' label='City' shrink placeholder='Enter a city' />
						)}
						{isBusinessRole && (
							<RHFTextField disabled name='address.postcode' label='Postcode' shrink placeholder='Enter a postcode' />
						)}

						{isBusinessRole && (
							<RHFTextField disabled name='address.state' label='Region' placeholder='e.g. England' shrink />
						)}
						{isBusinessRole && (
							<RHFTextField disabled name='address.county' label='County' placeholder='e.g. Dorset' shrink />
						)}
						<RHFPhoneNumberInput name='telephone' label='Mobile phone number' />
						<RHFPhoneNumberInput name='landline' label='Landline phone number' />
						{isBusinessRole && <RHFTextField name='website' label='Personal business website' />}
						{!isBusinessRole && <RHFTextField name='nino' label='National Insurance Number (NINO)' shrink />}
					</BoxAccountGeneralStyle>

					<LoadingButtonStyle type='submit' variant='contained' loading={isSubmitting} disabled={isEdited}>
						Save Changes
					</LoadingButtonStyle>
				</Stack>
			</Stack>
		</FormProvider>
	);
}
