import { requiredNumber, requiredString } from 'src/utils/schemas';
import * as Yup from 'yup';
import { companyBranchStatus, companyBranchType } from '../../constants';

export const getBranchAddressCoordinatesValidationSchema = (required = true) => {
	const fieldType = required ? requiredNumber : Yup.number().nullable().optional();

	return Yup.object().shape({ lat: fieldType, lng: fieldType });
};

export const getBranchAddressValidationSchema = (required = true) => {
	const fieldType = required ? requiredString : Yup.string().nullable().optional();
	const coordinatedFieldType = getBranchAddressCoordinatesValidationSchema(required);

	return Yup.object().shape({
		city: fieldType,
		coordinates: required ? coordinatedFieldType : coordinatedFieldType.nullable().optional(),
		country: fieldType,
		county: fieldType,
		formattedAddress: fieldType,
		houseNumber: fieldType,
		postcode: fieldType,
		state: fieldType,
		street: fieldType,
	});
};

export const branchTypeValidationSchema = requiredString.oneOf(Object.values(companyBranchType));
export const branchStatusValidationSchema = requiredString.oneOf(Object.values(companyBranchStatus));

export const updateBranchValidationSchema = Yup.object().shape({
	name: requiredString,
	address: getBranchAddressValidationSchema().typeError('Address is required'),
	telephone: requiredString,
	emails: Yup.array(requiredString.email('Emails must be valid email addresses')),
	website: requiredString,
	status: branchStatusValidationSchema,
	type: branchTypeValidationSchema,
});
