import { selectModal } from 'src/redux/slices/modal';
import { Task } from 'src/redux/types';

import { useSelector } from 'react-redux';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import useLoading from 'src/hooks/useLoading';
import { Box, Typography } from '@mui/material';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import ReactSignatureCanvas from 'react-signature-canvas';
import { LoadingButton } from '@mui/lab';
import { useRef } from 'react';
import { useSnackbar } from 'notistack';
import { selectUser } from 'src/redux/slices/auth';
import { updateDoc } from 'firebase/firestore';
import signTransactionDeed from 'src/api/transaction/sign-transaction-deed';
import { handleCloseModal } from 'src/utils/propertyHelpers';

const Content = () => {
	const { isLoading, setIsLoading } = useLoading();
	const user = useSelector(selectUser);
	const { payload } = useSelector(selectModal<{ task: Task }>);
	const { enqueueSnackbar } = useSnackbar();
	const signatureRef = useRef<ReactSignatureCanvas>(null);

	const handleClear = () => signatureRef.current?.clear();
	const handleSubmit = async () => {
		try {
			setIsLoading(true);

			if (!signatureRef.current) return;

			const base64Signature = signatureRef.current.toDataURL('image/png');

			await updateDoc(user.ref, { signature: base64Signature });

			await signTransactionDeed(payload.task.metadata.deedId);

			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<Typography sx={styledMainTitle}>ENTER YOUR SIGNATURE</Typography>
			<Box display='flex' justifyContent='center'>
				<ReactSignatureCanvas
					ref={signatureRef}
					canvasProps={{ width: 400, height: 250, className: 'sigCanvas', style: { border: '1px solid grey' } }}
				/>
			</Box>
			<Box display='flex' flexDirection='row' justifyContent='flex-end' gap='15px' mt='30px'>
				<LoadingButton loading={isLoading} size='large' onClick={handleClear}>
					Clear
				</LoadingButton>
				<LoadingButton loading={isLoading} variant='contained' size='large' onClick={handleSubmit}>
					Submit
				</LoadingButton>
			</Box>
		</Box>
	);
};

const EnterSignatureModal = () => (
	<Modal name={modals.enterSignature} cardSx={{ maxWidth: '500px', padding: '32px 27px', overflow: 'visible' }}>
		<Content />
	</Modal>
);

export default EnterSignatureModal;
