export const downloadCsv = (fileName: string, rows: Array<Array<string | number>>) => {
	const csvString = rows.map((row) => row.map((r) => `"${r}"`).join(',')).join('\n');

	const blob = new Blob([csvString], { type: 'text/csv' });

	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');

	link.href = url;
	link.download = fileName;

	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);

	URL.revokeObjectURL(url);
};
