import { noop } from 'lodash';
import { dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { selectProperty } from 'src/redux/slices/property';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { useNavigate } from 'react-router';
import { setCurrentTransactionTab } from 'src/redux/slices/transaction';
import { PATH_INSTRUMENTS, PATH_TRANSACTIONS } from 'src/routes/paths';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { ConfirmationModal } from '../../common';

const PropertyCompletionModal = () => {
	const property = useSelector(selectProperty);
	const navigate = useNavigate();

	const isRemortgage = property.onboarding?.activeSide === 'remortgage';

	const handleConfirm = async () => {
		if (!property.onboarding?.transactionRef) return;

		dispatch(setCurrentTransactionTab('participants'));
		navigate(
			isRemortgage
				? PATH_INSTRUMENTS.view(property.onboarding.transactionRef.id)
				: PATH_TRANSACTIONS.view(property.onboarding.transactionRef.id),
		);
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '650px' }}
			modalName={propertyOnboardingSteps.completion}
			title={`TRANSACTION CREATED 🎉`}
			withCancelButton={false}
			confirmText='Continue'
			handleConfirm={handleConfirm}
			handleClose={noop}
			description={
				<>
					You have successfully set up a new transaction for <b>{property.name}</b>.
					<br />
					<br />
					Next, you'll be directed to the <b>"Participants"</b> tab, where you can add all the relevant parties.
				</>
			}
		/>
	);
};

export default PropertyCompletionModal;
