import { getStorage, ref } from 'firebase/storage';
import { fileDownloader } from 'src/api/questionnaire/documents/utils';

const storage = getStorage();

export const downloadBankAccountDocument = async (fileName: string, path: string) => {
	const fileRef = ref(storage, `gs://${process.env.REACT_APP_BANK_ACCOUNTS_BUCKET}/${path}`);

	await fileDownloader(fileRef)(fileName);

	return true;
};
