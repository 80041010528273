import { FC } from 'react';
import { VerificationSessionStatus } from 'src/types';
import { dispatch } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { Button } from '@mui/material';
import { VerificationFieldProps } from './types';

const VerificationField: FC<VerificationFieldProps> = ({ user }) => {
	if (
		user.verificationStatus === VerificationSessionStatus.NAME_ADDRESS_MISMATCH ||
		user.verificationStatus === VerificationSessionStatus.NAME_MISMATCH
	) {
		const handleClick = () => dispatch(openModal({ name: modals.userNameConfirmationError, payload: user }));

		return <Button onClick={handleClick}>NAME ERROR</Button>;
	}

	if (user.verificationStatus === VerificationSessionStatus.ADDRESS_MISMATCH) {
		const handleClick = () => dispatch(openModal({ name: modals.userAddressConfirmationError, payload: user }));

		return <Button onClick={handleClick}>ADDRESS ERROR</Button>;
	}

	if (user.verificationStatus === VerificationSessionStatus.FAILED) {
		const handleClick = () => dispatch(openModal({ name: modals.userVerificationFailed, payload: user }));

		return <Button onClick={handleClick}>FAILED</Button>;
	}

	return <>{user.verificationStatus}</>;
};

export default VerificationField;
