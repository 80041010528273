import { Company } from 'src/redux/types';
import { call } from '../@';

export type ProgressStatus = 'done' | 'pending' | 'notStarted';

export type GetCompaniesDto = PaginationDto & {
	search?: string;
	sicCodes?: string[];
	status?: string[];
	condition?: string[];
};

export type GetCompaniesResponse = {
	companies: Company[];
	totalCount: number;
	totalPages: number;
	sicCodes: string[];
};

export default async function getCompanies(
	payload: GetCompaniesDto,
	signal: AbortSignal,
): Promise<GetCompaniesResponse> {
	return await call('companies-get', payload, { signal });
}
