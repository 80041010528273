import { call } from '../@';

export type AddUserBankAccountDto = {
	userIds: string[];
	bank: string;
	countryCode: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params: Record<PropertyKey, any>;
};

export type AddUserBankAccountResponse = {
	id: string;
};

export default async function addUserBankAccount(dto: AddUserBankAccountDto) {
	return await call('bankAccounts-add', dto);
}
