import { DocumentSnapshot, getDocs, query, where } from 'firebase/firestore';
import { getTransactionOnboardingsCollection, transactionOnboardingsCollectionGroup } from 'src/constants/firestore';
import { TransactionOnboarding } from 'src/types';
import { getUserRef } from '..';

export const findTransactionOnboarding = async (
	userId: string,
	transactionId: string,
): Promise<DocumentSnapshot<TransactionOnboarding> | null> => {
	const userRef = getUserRef(userId);

	const { docs } = await getDocs(
		query(getTransactionOnboardingsCollection(transactionId), where('userRef', '==', userRef)),
	);

	return docs[0] ?? null;
};

export const findAllTransactionOnboardings = async (
	userId: string,
): Promise<DocumentSnapshot<TransactionOnboarding>[]> => {
	const userRef = getUserRef(userId);

	const { docs } = await getDocs(query(transactionOnboardingsCollectionGroup, where('userRef', '==', userRef)));

	return docs;
};
