import { FC } from 'react';
import { TableCell } from 'src/pages/dashboard/styles';
import { TableSortLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUsers, sortUsers } from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import { HeadCellProps } from './types';

const HeadCell: FC<HeadCellProps> = ({ name, columnKey, ...props }) => {
	const { order, orderBy } = useSelector(selectUsers);

	const handleSort = () => {
		const isAsc = orderBy === columnKey && order === 'asc';

		dispatch(sortUsers({ order: isAsc ? 'desc' : 'asc', orderBy: columnKey }));
	};

	return (
		<TableCell {...props}>
			<TableSortLabel
				active={orderBy === columnKey}
				direction={orderBy === columnKey ? order : 'asc'}
				onClick={handleSort}
			>
				{name}
			</TableSortLabel>
		</TableCell>
	);
};

export default HeadCell;
