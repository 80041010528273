import { call } from '../@';

export type SearchCompanyDto = { regNum: string };

export type FoundCompany = {
	regNum: string;
	legalName: string;
	tradingName: string;
	isFromHousingApi: boolean;
	id?: string;
};

export default async function searchCompany(dto: SearchCompanyDto) {
	return await call('company-search', dto);
}
