import { call } from '../@';

export type Payload = {
	propertyId: string;
	isTransactionOnboarding?: boolean;
};

const getPropertyTitles = (payload: Payload) => call('hmlr-getPropertyTitles', payload);

export default getPropertyTitles;
