import { Box, Button, Popover } from '@mui/material';
import React, { useMemo, useState } from 'react';
import Iconify from 'src/components/Iconify';
import ColumnsSelector from './components/ColumnsSelector';
import { PopoverType } from './types';
import Filters from './components/Filters';
import { makePopoverClasses } from './styles';

const SummariesToolbar = () => {
	const [popover, setAnchorEl] = useState<{ el: HTMLButtonElement | null; type: PopoverType } | null>(null);

	const classes = makePopoverClasses();
	const open = !!popover?.el;

	const handleClose = () => setAnchorEl(null);

	const handleSelectColumns = (type: PopoverType) => (e: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl({ el: e.currentTarget, type });
	};

	const popoverContent = useMemo(() => {
		if (!popover?.type) return null;

		switch (popover.type) {
			case 'columns':
				return <ColumnsSelector handleClose={handleClose} />;
			case 'filters':
				return <Filters handleClose={handleClose} />;
			default:
				return 'default';
		}
	}, [popover?.type]);

	return (
		<Box display='flex' gap='15px' alignItems='center' pl='10px'>
			<Button variant='text' sx={{ minWidth: 'fit-content' }} onClick={handleSelectColumns('columns')}>
				<Iconify icon='mingcute:settings-6-fill' fontSize={23} />
			</Button>
			<Popover
				open={open}
				anchorEl={popover?.el}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				sx={{ overflow: 'visible' }}
				classes={classes}
			>
				{popoverContent}
			</Popover>
			<Button variant='text' sx={{ minWidth: 'fit-content' }} onClick={handleSelectColumns('filters')}>
				<Iconify icon='mdi:filter' fontSize={23} />
			</Button>
		</Box>
	);
};

export default SummariesToolbar;
