import { capitalCase } from 'change-case';
import { GeoPoint } from 'firebase/firestore';
import { extractAddress } from 'src/components/GoogleAddressAutoComplete/GooglePlaceAutocomlete';
import { VerificationSession, VerificationSessionDocument } from 'src/types';

export const extractVerificationDocumentWithAddress = (session: VerificationSession) =>
	[
		...(session.documents?.drivingLicences ?? []),
		...(session.documents?.other ?? []),
		...(session.documents?.passports ?? []),
	].find(
		// eslint-disable-next-line camelcase
		({ structured_postal_address }) => !!structured_postal_address,
	);

export const extractFullAddressFromVerificationDocument = (document: VerificationSessionDocument) => {
	if (!document.structured_postal_address) return '';

	return [
		capitalCase(document.structured_postal_address.building_number ?? ''),
		capitalCase(document.structured_postal_address.street ?? ''),
		capitalCase(document.structured_postal_address.town_city ?? ''),
		document.structured_postal_address.postal_code ?? '',
		capitalCase(document.structured_postal_address.state ?? ''),
		capitalCase(document.structured_postal_address.country ?? ''),
	]
		.filter(Boolean)
		.join(', ');
};

export const getFullVerificationAddress = async (
	address: Exclude<VerificationSessionDocument['structured_postal_address'], undefined>,
) => {
	const geocoder = new window.google.maps.Geocoder();

	const {
		results: [place],
	} = await geocoder.geocode({
		address: `${address.postal_code} ${address.building_number} ${address.street} ${address.town_city} ${address.state} ${address.country}`,
	});

	if (!place) return null;

	const extractedAddress = extractAddress(place);

	return {
		...extractedAddress,
		coordinates: new GeoPoint(extractedAddress.coordinates.lat, extractedAddress.coordinates.lng),
	};
};
