import Page from 'src/components/Page';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BrandCard, RHFRadioGroup } from 'src/components';
import {
	PROFESSION_ESTATE_AGENT,
	PROFESSION_MORTGAGE_BROKER,
	PROFESSION_PRINCIPAL,
	PROFESSION_SOLICITOR,
	PROFESSION_SURVEYOR,
	PROFESSION_TRADES_PERSON,
} from 'src/constants';
import { dispatch } from 'src/redux/store';
import { selectUser, updateUser, updateUserOnboarding } from 'src/redux/slices/auth';
import { getProfessionRef } from 'src/utils/firebase';
import { useSelector } from 'react-redux';
import { deleteField } from 'firebase/firestore';
import { AuthPage, ContinueButton } from '../styles';
import { onboardingProfessionValidationSchema } from './validation';
import { OnboardingProfessionFormType } from './types';
import OnboardingFooter from '../OnboardingFooter';

const OnboardingProfession = () => {
	const user = useSelector(selectUser);
	const form = useForm<OnboardingProfessionFormType>({
		resolver: yupResolver(onboardingProfessionValidationSchema),
		defaultValues: {
			professionId: user.professions[0]?.id,
		},
	});

	const handleSubmit = async (data: OnboardingProfessionFormType) => {
		await dispatch(updateUser({ professions: [getProfessionRef(data.professionId)] }));

		const isPrincipal = data.professionId === PROFESSION_PRINCIPAL.id;
		const isAgentOrSolicitor =
			data.professionId === PROFESSION_SOLICITOR.id || data.professionId === PROFESSION_ESTATE_AGENT.id;

		await dispatch(
			updateUserOnboarding({
				role: true,
				profession: true,
				...(!isPrincipal &&
					(isAgentOrSolicitor
						? { company: false, branch: deleteField(), newCompany: deleteField(), newBranch: deleteField() }
						: { company: deleteField(), branch: deleteField(), newBranch: deleteField(), newCompany: false })),
			}),
		);
	};

	return (
		<FormProvider {...form}>
			<Page title='Login'>
				<AuthPage>
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<BrandCard title="I'm a(n)...">
							<RHFRadioGroup
								name='professionId'
								row={false}
								align='flex-start'
								options={[
									{ name: 'Estate agent', key: PROFESSION_ESTATE_AGENT.id },
									{ name: 'Mortgage broker', key: PROFESSION_MORTGAGE_BROKER.id },
									{ name: 'Solicitor/conveyancer', key: PROFESSION_SOLICITOR.id },
									{ name: 'Surveyor', key: PROFESSION_SURVEYOR.id },
									{ name: 'Tradesperson', key: PROFESSION_TRADES_PERSON.id },
								]}
							/>
							<ContinueButton variant='contained' fullWidth type='submit' loading={form.formState.isSubmitting}>
								Continue
							</ContinueButton>
							<OnboardingFooter />
						</BrandCard>
					</form>
				</AuthPage>
			</Page>
		</FormProvider>
	);
};

export default OnboardingProfession;
