/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box } from '@mui/material';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { dispatch } from 'src/redux/store';
import { BrandModal } from 'src/components/common';
import { selectModal } from 'src/redux/slices/modal';
import useLoading from 'src/hooks/useLoading';
import { useSnackbar } from 'notistack';
import { Company } from 'src/redux/types';
import { getCompaniesThunk, updateCompanyThunk } from 'src/redux/slices/tableSlice';
import Field from 'src/components/common/Field';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFAutocomplete, RHFTextField } from 'src/components';
import { UpdateCompanyForm } from './types';
import { updateCompanyValidationSchema } from './validation';
import { companyStatusOptions } from '../../constants';

const Content = () => {
	const { payload } = useSelector(selectModal<Company>);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const form = useForm<UpdateCompanyForm>({
		resolver: yupResolver(updateCompanyValidationSchema),
		defaultValues: { status: payload.status, tradingName: payload.tradingName },
	});

	const handleUpdate = async (data: UpdateCompanyForm) => {
		try {
			setIsLoading(true);

			await dispatch(updateCompanyThunk({ ...data, companyId: payload.id })).unwrap();

			dispatch(getCompaniesThunk());
			enqueueSnackbar('Company updated!');
			handleCloseModal();
		} catch (e) {
			console.log('e', e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<FormProvider {...form}>
			<BrandModal.Title title='UPDATE COMPANY' />
			<Box py='15px'>
				<Box display='flex' flexDirection='column' gap='10px'>
					<Field label='Trading name' value={<RHFTextField name='tradingName' label='Enter a name' />} row={false} />
					<Field
						label='Status'
						value={<RHFAutocomplete name='status' label='Select a status' options={companyStatusOptions} />}
						row={false}
					/>
				</Box>
				<ActionButtons mt='20px'>
					<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
						Cancel
					</LoadingButton>
					<LoadingButton
						variant='contained'
						size='large'
						fullWidth
						onClick={form.handleSubmit(handleUpdate)}
						loading={isLoading}
					>
						Update
					</LoadingButton>
				</ActionButtons>
			</Box>
		</FormProvider>
	);
};

const UpdateCompanyModal = () => (
	<Modal name={modals.updateCompany} cardSx={{ overflow: 'visible', maxWidth: '600px' }}>
		<Content />
	</Modal>
);

export default UpdateCompanyModal;
