import { Box, Tab, Tabs } from '@mui/material';
import { dispatch } from 'src/redux/store';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { getStringAfterSymbols } from 'src/utils/strings';
import { useEffect } from 'react';
import { getTransactionSummaries, selectTransactionsSummaries, setSummariesType } from 'src/redux/slices/tableSlice';
import { useSelector } from 'react-redux';
import { SummaryType } from './types';
import { tabs } from './utils';
import SummariesToolbar from './Toolbar';

const TransactionSummaries = () => {
	const location = useLocation();
	const currentPathTab = getStringAfterSymbols(location.pathname, `dashboard/transactions/`);
	const navigate = useNavigate();
	const { type } = useSelector(selectTransactionsSummaries);

	useEffect(() => {
		if (!currentPathTab) navigate('/dashboard/transactions/live', { replace: true });
		else dispatch(setSummariesType(currentPathTab as SummaryType));
	}, [currentPathTab]);

	useEffect(() => {
		const thunk = dispatch(getTransactionSummaries({ withLoading: true }));

		return () => thunk.abort();
	}, [type]);

	const handleTabChange = (_, tab: string) => navigate(`/dashboard/transactions/${tab}`);

	return (
		<>
			<Box pt='10px'>
				<SummariesToolbar />
				<Tabs
					allowScrollButtonsMobile
					variant='scrollable'
					scrollButtons='auto'
					value={currentPathTab}
					onChange={handleTabChange}
				>
					{tabs.map((tab) => (
						<Tab disableRipple key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
					))}
				</Tabs>
			</Box>
			<Box height='100%' width='100%'>
				<Outlet />
			</Box>
		</>
	);
};

export default TransactionSummaries;
