import { Box, Typography } from '@mui/material';
import { ConfirmationModal } from 'src/components/modals';
import modals from 'src/constants/modals';
import useLoading from 'src/hooks/useLoading';
import { selectModal } from 'src/redux/slices/modal';
import {
	approveChangeEmailRequestThunk,
	getUsersThunk,
	rejectChangeEmailRequestThunk,
} from 'src/redux/slices/tableSlice';
import { dispatch, useSelector } from 'src/redux/store';
import { IDashboardUser } from 'src/redux/types';
import { handleCloseModal } from 'src/utils/propertyHelpers';

const ManageEmailChangeRequestModal = () => {
	const { payload } = useSelector(selectModal<IDashboardUser>);
	const { isLoading, setIsLoading } = useLoading();

	if (!payload.changeEmailRequest) return null;

	const handleApprove = async () => {
		setIsLoading(true);

		await dispatch(
			approveChangeEmailRequestThunk({ requestId: payload.changeEmailRequest?.id as string, userId: payload.uid }),
		);

		setIsLoading(false);
		dispatch(getUsersThunk());
		handleCloseModal();
	};

	const handleReject = async () => {
		setIsLoading(true);

		await dispatch(
			rejectChangeEmailRequestThunk({ requestId: payload.changeEmailRequest?.id as string, userId: payload.uid }),
		);

		setIsLoading(false);
		dispatch(getUsersThunk());
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			modalName={modals.manageEmailChangeRequest}
			title='AUTHORISE EMAIL CHANGE'
			confirmText='Approve'
			cancelText='Reject'
			handleConfirm={handleApprove}
			handleCancel={handleReject}
			isLoading={isLoading}
			description={
				<Box>
					<Typography>
						User:{' '}
						<b>
							{payload.givenNames} {payload.lastNames}
						</b>
					</Typography>
					<Typography>
						Old email: <b>{payload.email}</b>
					</Typography>
					<Typography>
						New email: <b>{payload.changeEmailRequest.email}</b>
					</Typography>
				</Box>
			}
		/>
	);
};

export default ManageEmailChangeRequestModal;
