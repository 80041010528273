import { Box } from '@mui/system';
import { FC } from 'react';
import { FieldProps } from './types';

const Field: FC<FieldProps> = ({ label, value, row = true }) => (
	<Box display='flex' gap={row ? '5px' : '12px'} flexDirection={row ? 'row' : 'column'}>
		{!!label && <b>{label}:</b>}
		{value}
	</Box>
);

export default Field;
