import { getDocs, query, where } from 'firebase/firestore';
import { getUserOnboardingsCollection } from 'src/constants/firestore';
import { UserOnboardingStatus } from 'src/types';

export const findUserOnboarding = async (userId: string) => {
	const { docs } = await getDocs(
		query(getUserOnboardingsCollection(userId), where('status', '==', UserOnboardingStatus.PENDING)),
	);

	if (!docs.length) return null;

	const [snapshot] = docs;

	return { snapshot, ref: snapshot.ref, data: snapshot.data() };
};
