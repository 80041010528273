import { useState } from 'react';
import { Branch, Business } from 'src/types';
import { getFirmBranches } from 'src/utils/firebase';
import { firmsCollection } from 'src/constants/firestore';
import { getDocs, query, where } from 'firebase/firestore';
import { companyCondition } from 'src/pages/dashboard/components/Companies/constants';
import { PROFESSION_ESTATE_AGENT, PROFESSION_SOLICITOR } from 'src/constants';
import useLoading from './useLoading';
import { branchesSorter, firmsSorter } from './utils';

const useFirms = () => {
	const { isLoading: areFirmsLoading, setIsLoading: setAreFirmsLoading } = useLoading();
	const { isLoading: areBranchesLoading, setIsLoading: setAreBranchesLoading } = useLoading();
	const [firms, setFirms] = useState<Business[]>([]);
	const [firmBranches, setFirmBranches] = useState<Branch[]>([]);

	const fetchFirms = async (professionId: string) => {
		try {
			setAreFirmsLoading(true);
			const isAgent = professionId === PROFESSION_ESTATE_AGENT.id;
			const isSolicitor = professionId === PROFESSION_SOLICITOR.id;

			const firmsQuery = query(
				firmsCollection,
				where('condition', '!=', companyCondition.dissolved),
				where(
					'sicCodes',
					'array-contains-any',
					isAgent ? ['68310', '68320'] : isSolicitor ? ['69102'] : ['64999', '66220', '82990', '64922'],
				),
			);

			const { docs } = await getDocs<Business>(firmsQuery);

			const foundFirms = docs.map((d) => ({ ...d.data(), id: d.id }));

			setFirms(foundFirms.sort(firmsSorter));
		} catch (e) {
			console.log('fetchFirms', e);
		} finally {
			setAreFirmsLoading(false);
		}
	};

	const fetchBranches = async (firmId: string) => {
		try {
			setAreBranchesLoading(true);

			const branches = await getFirmBranches(firmId);

			setFirmBranches(branches.sort(branchesSorter));
		} catch (e) {
			console.log('fetchBranches', e);
		} finally {
			setAreBranchesLoading(false);
		}
	};

	return { areFirmsLoading, areBranchesLoading, firms, firmBranches, fetchFirms, fetchBranches };
};

export default useFirms;
