import { FC } from 'react';
import { TableCell } from 'src/pages/dashboard/styles';
import { TableSortLabel } from '@mui/material';
import { HeadCellProps } from './types';

const HeadCell: FC<HeadCellProps> = ({
	name,
	columnKey,
	handleSortChange,
	orderBy,
	order,
	sortable = true,
	...props
}) => {
	const handleSort = () => {
		if (!columnKey) return;

		const isAsc = orderBy === columnKey && order === 'asc';

		handleSortChange?.({ order: isAsc ? 'desc' : 'asc', orderBy: columnKey });
	};

	return (
		<TableCell {...props}>
			{sortable ? (
				<TableSortLabel
					active={orderBy === columnKey}
					direction={orderBy === columnKey ? order : 'asc'}
					onClick={handleSort}
				>
					{name}
				</TableSortLabel>
			) : (
				name
			)}
		</TableCell>
	);
};

export default HeadCell;
