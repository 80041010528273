import { DocumentSnapshot, getDocs, query, where } from 'firebase/firestore';
import { getUserVerificationSessionCollection } from 'src/constants/firestore';
import { VerificationSession } from 'src/types';

export const findUserVerificationSession = async (
	userId: string,
): Promise<DocumentSnapshot<VerificationSession> | null> => {
	const { docs } = await getDocs(
		query(getUserVerificationSessionCollection(userId), where('status', '!=', 'ARCHIVED')),
	);

	return docs[0] ?? null;
};
