/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { UserData } from 'src/api/auth/@user-data';
import { deleteField, UpdateData, updateDoc } from 'firebase/firestore';
import UserProto from 'src/api/auth/@user-proto';
import { UserOnboarding, UserOnboardingStep } from 'src/types';
import { getPreviousUserOnboardingStep } from 'src/utils/users';
import userData from 'src/api/auth/@user-data';
import changeEmailRequest from 'src/api/user/change-email-request';
import verifyChangeEmailRequest from 'src/api/user/verify-change-email-request';
import confirmChangeEmailRequest from 'src/api/user/confirm-change-email-request';
import resendChangeEmailRequestVerification from 'src/api/user/resend-change-email-request-verification';
import cancelChangeEmailRequest from 'src/api/user/cancel-change-email-request';
import { findUserOnboarding } from 'src/utils/firebase/user-onboarding';
import uploadUserBankAccountFiles, {
	UploadUserBankAccountFilesDto,
	UploadUserBankAccountFilesResponse,
} from 'src/api/bank-accounts/upload-user-bank-account-files';
import updateUserBankAccount, {
	UpdateUserBankAccountDto,
	UpdateUserBankAccountResponse,
} from 'src/api/bank-accounts/update-user-bank-account';
import addUserBankAccount, {
	AddUserBankAccountDto,
	AddUserBankAccountResponse,
} from 'src/api/bank-accounts/add-user-bank-account';
import getUserBankAccounts, { GetUserBankAccountsResponse } from 'src/api/bank-accounts/get-user-bank-accounts';
import searchCompany, { FoundCompany, SearchCompanyDto } from 'src/api/company/search-company';
import addCompany, { AddCompanyDto, AddCompanyResponse } from 'src/api/company/add-company';
import addCompanyBranch, { AddCompanyBranchDto, AddCompanyBranchResponse } from 'src/api/company/add-company-branch';
import { FieldValue } from 'react-hook-form';
import { AuthState, UserBankAccountFile } from '../types/auth';
import { RootState } from '../store';

// ----------------------------------------------------------------------

export type ExtendedUserOnboarding = {
	currentPage: string;
	currentStep: UserOnboardingStep;
} & UserOnboarding;

const initialState = {
	isLoading: false,
	error: null,
	isAuthenticated: false,
	isInitialized: false,
	isAuthenticationLoading: false,
	isHint: true,
	user: null,
	isBlocked: false,
	bankAccounts: { isInitialized: false, data: [] },
} as AuthState;

export const updateUser = createAsyncThunk<UserData | null, UpdateData<UserProto>>(
	'user/update',
	async (payload, { getState }) => {
		const {
			auth: { user },
		} = getState() as RootState;

		if (!user) return null;

		await updateDoc(user.ref, payload);

		return await userData(user.ref.id);
	},
);

export const getUserBankAccountsThunk = createAsyncThunk<GetUserBankAccountsResponse>(
	'bank-accounts/get',
	async (_, { rejectWithValue }) => {
		try {
			return await getUserBankAccounts();
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const addUserBankAccountThunk = createAsyncThunk<AddUserBankAccountResponse, AddUserBankAccountDto>(
	'bank-account/add',
	async (payload, { rejectWithValue }) => {
		try {
			return await addUserBankAccount(payload);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const updateUserBankAccountThunk = createAsyncThunk<UpdateUserBankAccountResponse, UpdateUserBankAccountDto>(
	'bank-account/update',
	async (payload, { rejectWithValue }) => {
		try {
			return await updateUserBankAccount(payload);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const uploadUserBankAccountFilesThunk = createAsyncThunk<
	UploadUserBankAccountFilesResponse,
	UploadUserBankAccountFilesDto
>('bank-account/upload-files', async (payload, { rejectWithValue }) => {
	try {
		return await uploadUserBankAccountFiles(payload);
	} catch (e) {
		return rejectWithValue(e);
	}
});

export const searchCompanyThunk = createAsyncThunk<FoundCompany, SearchCompanyDto>(
	'company/search',
	async (payload, { rejectWithValue }) => {
		try {
			return await searchCompany(payload);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const addCompanyThunk = createAsyncThunk<AddCompanyResponse, AddCompanyDto>(
	'company/add',
	async (payload, { rejectWithValue }) => {
		try {
			return await addCompany(payload);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const addCompanyBranchThunk = createAsyncThunk<AddCompanyBranchResponse, AddCompanyBranchDto>(
	'company-branch/add',
	async (payload, { rejectWithValue }) => {
		try {
			return await addCompanyBranch(payload);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const updateUserOnboarding = createAsyncThunk<
	UserData | null,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Partial<{ [K in keyof UserOnboarding]: UserOnboarding[K] | FieldValue<any> }>
>('user-onboarding/update', async (payload, { getState }) => {
	const {
		auth: { user },
	} = getState() as RootState;

	if (!user) return null;

	const userOnboarding = await findUserOnboarding(user.uid);

	if (!userOnboarding) return null;

	await updateDoc(userOnboarding.ref, payload);

	return await userData(user.uid);
});

export const goBackOnUserOnboarding = createAsyncThunk<UserData | null>(
	'user-onboarding/go-back',
	async (_, { getState }) => {
		const {
			auth: { user },
		} = getState() as RootState;

		if (!user || !user.onboarding?.currentPage || !user.onboarding?.currentStep) return null;

		const userOnboardingSnapshot = await findUserOnboarding(user.uid);
		const previousStep = getPreviousUserOnboardingStep(user.onboarding, user.role?.id);

		if (!previousStep || !userOnboardingSnapshot) return null;

		if (user.onboarding?.currentStep === 'company') {
			await updateDoc<unknown>(user.ref, { business: null, branch: null });
		}

		await updateDoc(userOnboardingSnapshot.ref, {
			[previousStep]: false,
			[user.onboarding.currentStep]: deleteField(),
		});

		return await userData(user.uid);
	},
);

export const changeEmailRequestThunk = createAsyncThunk<{ id: string }, { email: string }>(
	'user/change-email-request',
	async (payload, { rejectWithValue }) => {
		try {
			return await changeEmailRequest(payload.email);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const verifyChangeEmailRequestThunk = createAsyncThunk<void, { requestId: string; code: string }>(
	'user/change-email-request',
	// eslint-disable-next-line consistent-return
	async (payload, { rejectWithValue }) => {
		try {
			await verifyChangeEmailRequest(payload.requestId, payload.code);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const confirmChangeEmailRequestThunk = createAsyncThunk<void, { requestId: string; userId: string }>(
	'user/confirm-change-email-request',
	// eslint-disable-next-line consistent-return
	async ({ requestId, userId }, { rejectWithValue }) => {
		try {
			await confirmChangeEmailRequest(requestId, userId);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const cancelChangeEmailRequestThunk = createAsyncThunk<void, { requestId: string }>(
	'user/cancel-change-email-request',
	// eslint-disable-next-line consistent-return
	async (payload, { rejectWithValue }) => {
		try {
			await cancelChangeEmailRequest(payload.requestId);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const resendChangeEmailRequestVerificationThunk = createAsyncThunk<void, { requestId: string }>(
	'user/resend-change-email-request-verification',
	// eslint-disable-next-line consistent-return
	async (payload, { rejectWithValue }) => {
		try {
			await resendChangeEmailRequestVerification(payload.requestId);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		// HINT
		toogleHint(state) {
			state.isHint = !state.isHint;
		},
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
		},
		// LOGIN
		login: (state, action: PayloadAction<UserData | null>) => {
			if (action.payload) {
				state.user = action.payload as UserData;
			} else {
				state.user = null;
			}

			state.isAuthenticated = true;
			state.isInitialized = true;
		},
		// LOGOUT
		logout: (state) => {
			state.user = null;
			state.isAuthenticated = false;
			state.isInitialized = true;
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},

		setIsAuthenticationLoading(state, action: PayloadAction<boolean>) {
			state.isAuthenticationLoading = action.payload;
		},
		setIsInitialized(state, action: PayloadAction<boolean>) {
			state.isInitialized = action.payload;
		},
		setIsBlocked(state, action: PayloadAction<boolean>) {
			state.isBlocked = action.payload;
		},

		addBankAccountFiles(state, { payload }: PayloadAction<{ id: string; files: UserBankAccountFile[] }>) {
			state.bankAccounts.data = state.bankAccounts.data.map((a) => {
				if (a.id === payload.id) return { ...a, files: [...payload.files, ...a.files] };

				return a;
			});
		},
	},
	extraReducers: (builder) => {
		builder.addCase(updateUserOnboarding.fulfilled, (state, { payload }) => {
			if (!state.user || !payload) return state;

			state.user = payload;

			return state;
		});

		builder.addCase(goBackOnUserOnboarding.fulfilled, (state, { payload }) => {
			if (!state.user || !payload) return state;

			state.user = payload;

			return state;
		});

		builder.addCase(updateUser.fulfilled, (state, { payload }) => {
			console.log('state.user', !!state.user);

			if (!state.user) return state;

			state.user = payload;

			return state;
		});

		builder.addCase(getUserBankAccountsThunk.pending, (state) => {
			state.bankAccounts.isInitialized = false;
		});
		builder.addCase(getUserBankAccountsThunk.fulfilled, (state, { payload }) => {
			state.bankAccounts.isInitialized = true;
			state.bankAccounts.data = payload;
		});
		builder.addCase(getUserBankAccountsThunk.rejected, (state, { error }) => {
			state.bankAccounts.isInitialized = true;
			state.bankAccounts.error = error;
		});
	},
});

export const {
	login,
	logout,
	toogleHint,
	setIsInitialized,
	setIsAuthenticationLoading,
	setIsBlocked,
	addBankAccountFiles,
} = slice.actions;

// selectors
export const selectUser = (state) => state.auth.user as UserData;
export const selectUserSettings = (state) => selectUser(state).settings;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectIsInitialized = (state) => state.auth.isInitialized;
export const selectIsBlocked = (state) => state.auth.isBlocked;
export const selectIsAuthenticationLoading = (state) => state.auth.isAuthenticationLoading;
export const selectIsHint = (state) => state.auth.isHint;
export const selectUserBankAccounts = (state: RootState) => state.auth.bankAccounts;

// Reducer
export default slice.reducer;
