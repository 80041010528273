import * as Yup from 'yup';
import { transactionBuyerRelationship } from 'src/pages/transactions/constants';
import { requiredString } from 'src/utils/schemas';

export const propertyRelationshipValidationSchema = Yup.object().shape({
	relationship: requiredString.oneOf([
		transactionBuyerRelationship.buyer.key,
		transactionBuyerRelationship.proxy.key,
		transactionBuyerRelationship.attorney.key,
	]),
});
