import { call } from '../@';

export type TransactionQuestionnaireFinanceBankAccount = { id: string; label: string };

export type TransactionQuestionnaireFinanceUser = {
	id: string;
	givenNames: string;
	lastNames: string;
	bankAccounts: TransactionQuestionnaireFinanceBankAccount[];
};

export type GetTransactionQuestionnaireFinanceResponse = {
	sellers: TransactionQuestionnaireFinanceUser[];
	buyers: TransactionQuestionnaireFinanceUser[];
	giftors: TransactionQuestionnaireFinanceUser[];
};

const getTransactionQuestionnaireFinance = (transactionId: string) =>
	call('transactionQuestionnaire-getFinance', { transactionId });

export default getTransactionQuestionnaireFinance;
