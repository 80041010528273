module.exports = {
	/** @type {'branches'} */
	branches: 'branches',
	/** @type {'countries'} */
	countries: 'countries',
	/** @type {'email-templates'} */
	emailTemplates: 'email-templates',
	/** @type {'epc'} */
	epc: 'epc',
	/** @type {'invitations'} */
	invitations: 'invitations',
	/** @type {'properties'} */
	properties: 'properties',
	/** @type {'property-questionnaires'} */
	questionnaireProperty: 'property-questionnaires',
	/** @type {'firms'} */
	firms: 'firms',
	/** @type {'transaction-questionnaires'} */
	questionnaireTransaction: 'transaction-questionnaires',
	/** @type {'roles'} */
	roles: 'roles',
	/** @type {'state-regions'} */
	stateRegions: 'state-regions',
	/** @type {'town-cities'} */
	townCities: 'town-cities',
	/** @type {'transaction-invitations'} */
	transactionInvitations: 'transaction-invitations',
	/** @type {'transactions'} */
	transactions: 'transactions',
	/** @type {'users'} */
	users: 'users',
	/** @type {'verification-session'} */
	verificationSessions: 'verification-sessions',
	/** @type {'professions'} */
	professions: 'professions',
};
