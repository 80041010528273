import { FormProvider, useForm } from 'react-hook-form';
import { Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import useLoading from 'src/hooks/useLoading';
import { addUserBankAccountThunk, getUserBankAccountsThunk, selectUser } from 'src/redux/slices/auth';
import { useSelector } from 'react-redux';
import { selectCountries } from 'src/redux/slices/tableSlice';
import { RHFAutocomplete, RHFTextField } from 'src/components';
import { Box } from '@mui/system';
import { dispatch } from 'src/redux/store';
import { AddBankAccountForm } from './types';
import { getValidationSchema } from './validation';

const Content = () => {
	const user = useSelector(selectUser);
	const countries = useSelector(selectCountries);
	const { enqueueSnackbar } = useSnackbar();
	const { isLoading, setIsLoading } = useLoading();
	const [countryCode, setCountryCode] = useState<string | undefined>('GB');

	const country = useMemo(() => {
		if (!countryCode) return undefined;

		return countries.find((c) => c['2LetterCode'] === countryCode);
	}, [countries, countryCode]);

	const form = useForm<AddBankAccountForm>({
		resolver: yupResolver(getValidationSchema(country)),
		defaultValues: { userIds: [user.uid], params: {}, bank: '', countryCode: 'GB' },
	});

	const handleCountryChange = (_, option?: { key: string }) => {
		const value = option?.key;

		setCountryCode(value);
		form.setValue('countryCode', value as string);
		form.setValue('params', {});
	};

	const handleSubmit = async (data: AddBankAccountForm) => {
		try {
			setIsLoading(true);

			await dispatch(addUserBankAccountThunk(data)).unwrap();

			enqueueSnackbar('Bank account added successfully');
			handleCloseModal();

			dispatch(getUserBankAccountsThunk());
		} catch (e) {
			console.log('e', e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const countriesOptions = useMemo(() => countries.map((c) => ({ name: c.name, key: c['2LetterCode'] })), [countries]);

	return (
		<FormProvider {...form}>
			<form
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(handleSubmit)(e);
				}}
			>
				<Typography sx={styledMainTitle} pt={0}>
					ADD BANK ACCOUNT
				</Typography>
				<RHFAutocomplete
					name='countryCode'
					label='Select a country'
					options={countriesOptions}
					valueAttribute='key'
					onChange={handleCountryChange}
				/>
				{!!country && (
					<Box mt='10px' display='flex' flexDirection='column' gap='10px'>
						<RHFTextField name='bank' label='Bank' />
						{country?.banking.fields.map((field) => (
							<RHFTextField
								key={field.key}
								name={`params.${field.key}`}
								placeholder={field.placeholder}
								label={field.name}
							/>
						))}
					</Box>
				)}
				<ActionButtons mt='20px'>
					<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
						Cancel
					</LoadingButton>
					<LoadingButton variant='contained' size='large' fullWidth type='submit' loading={isLoading}>
						Add
					</LoadingButton>
				</ActionButtons>
			</form>
		</FormProvider>
	);
};

const AddBankAccountModal = () => (
	<Modal name={modals.addBankAccount} cardSx={{ overflow: 'visible', maxWidth: '550px' }}>
		<Content />
	</Modal>
);

export default AddBankAccountModal;
