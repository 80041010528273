/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-unstable-nested-components */
import { Box, IconButton, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'src/components/common';
import { HandleSettingsChangeParams, HandleSortChangeParams, TableColumn } from 'src/components/common/Table/types';
import {
	changeCompaniesSettings,
	getCompaniesThunk,
	selectCompanies,
	setCompaniesFilters,
	sortCompanies,
} from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import { Company, CompanyBranch } from 'src/redux/types';
import { InviteLogo } from 'src/components/InviteCard/styles';
import { capitalize, debounce } from 'lodash';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import Iconify from 'src/components/Iconify';
import { FormProvider, useForm } from 'react-hook-form';
import { RHFAutocomplete } from 'src/components';
import { isOptionEqualToValue } from 'src/utils/common';
import CompanyStatusField from './components/CompanyStatusField';
import VerifyCompanyModal from './components/VerifyCompanyModal';
import VerifyCompanyBranchModal from './components/VerifyCompanyBranchModal';
import BranchStatusField from './components/BranchStatusField';
import UpdateCompanyBranchModal from './components/UpdateCompanyModal';
import { companyConditionOptions, companyStatusOptions } from './constants';

const CompaniesTable = () => {
	const companies = useSelector(selectCompanies);
	const form = useForm();

	useEffect(() => {
		const thunk = dispatch(getCompaniesThunk());

		return () => thunk.abort();
	}, [companies.filters]);

	const handleSortChange = (params: HandleSortChangeParams) => {
		dispatch(sortCompanies(params));
		dispatch(getCompaniesThunk());
	};

	const handleSettingsChange = (params: HandleSettingsChangeParams) => {
		dispatch(changeCompaniesSettings(params));
		dispatch(getCompaniesThunk());
	};

	const handleUpdateBranch = (b: CompanyBranch) => {
		dispatch(openModal({ name: modals.updateCompanyBranch, payload: b }));
	};

	const handleUpdateCompany = (c: Company) => {
		dispatch(openModal({ name: modals.updateCompany, payload: c }));
	};

	const columns: TableColumn<Company>[] = [
		{
			key: 'legalName',
			name: 'Legal Name',
			sortable: true,
			width: 450,
			getLabel: (row) => (
				<Box display='flex' alignItems='center' gap='7px'>
					<IconButton onClick={() => handleUpdateCompany(row)}>
						<Iconify icon='material-symbols:edit-outline-rounded' fontSize={25} color='#A3A3A3' />
					</IconButton>
					{row.legalName}
				</Box>
			),
		},
		{ key: 'tradingName', name: 'Trading Name', sortable: true, width: 400 },
		{ key: 'regNum', name: 'Number', sortable: true, width: 200 },
		{
			key: 'sicDesc',
			name: 'Categories',
			sortable: true,
			width: 200,
			getLabel: (row) => row.sicDesc?.join(', '),
		},
		{ key: 'status', name: 'Status', sortable: true, width: 200, getLabel: (row) => <CompanyStatusField {...row} /> },
		{ key: 'condition', name: 'Condition', sortable: true, width: 200 },
		{
			key: 'modifiedAt',
			name: 'Modified At',
			sortable: true,
			width: 150,
			getLabel: (row) => moment(row.modifiedAt).format('YYYY-MM-DD'),
		},
	];

	const subRowsColumns: TableColumn<CompanyBranch>[] = [
		{
			key: 'name',
			name: 'Name',
			width: 300,
			sortable: false,
			getLabel: (row) => (
				<Box display='flex' alignItems='center' gap='7px'>
					<IconButton onClick={() => handleUpdateBranch(row)}>
						<Iconify icon='material-symbols:edit-outline-rounded' fontSize={25} color='#A3A3A3' />
					</IconButton>
					{row.name}
				</Box>
			),
		},
		{ key: 'type', name: 'Type', width: 100, getLabel: (row) => capitalize(row.type), sortable: false },
		{ key: 'status', name: 'Status', width: 150, getLabel: (row) => <BranchStatusField {...row} />, sortable: false },
		{ key: 'address', name: 'Address', width: 350, sortable: false, getLabel: (row) => row.address.formattedAddress },
		{ key: 'telephone', name: 'Telephone', width: 200, sortable: false },
		{ key: 'website', name: 'Website', width: 200, sortable: false },
		{ key: 'emails', name: 'Emails', width: 200, getLabel: (row) => row.emails.join(', '), sortable: false },
		{
			key: 'logo',
			name: 'Logo',
			width: 200,
			getLabel: (row) => <InviteLogo src={row.logo} maxHeight='100px !important' objectFit='auto !important' />,
			sortable: false,
		},
		{
			key: 'createdAt',
			name: 'Created At',
			width: 150,
			getLabel: (row) => moment(row.createdAt).format('YYYY-MM-DD'),
			sortable: false,
		},
	];

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch(setCompaniesFilters({ search: e.target.value }));
	};
	const handleStatusChange = (_, options) => {
		form.setValue('status', options);

		dispatch(setCompaniesFilters({ status: options.map((o) => o.id) }));
	};
	const handleSicCodesChange = (_, options) => {
		form.setValue('sicCodes', options);

		dispatch(setCompaniesFilters({ sicCodes: options.map((o) => o.value) }));
	};
	const handleConditionChange = (_, options) => {
		form.setValue('condition', options);

		dispatch(setCompaniesFilters({ condition: options.map((o) => o.id) }));
	};

	return (
		<Box height='100%'>
			<Box>
				<Typography variant='h4' mb='15px'>
					Companies
				</Typography>
				<Box display='flex' alignItems='center' gap='10px' alignSelf='flex-start'>
					<TextField
						placeholder='Search companies'
						onChange={debounce(handleSearchChange, 500)}
						sx={{ width: '500px' }}
					/>
					<FormProvider {...form}>
						<RHFAutocomplete
							// @ts-ignore
							multiple
							name='sicCodes'
							label='Select categories'
							valueAttribute='value'
							labelAttribute='label'
							options={companies.sicCodes}
							value={form.watch('sicCodes') ?? []}
							sx={{ width: '300px' }}
							onChange={handleSicCodesChange}
							isOptionEqualToValue={isOptionEqualToValue()}
						/>
						<RHFAutocomplete
							// @ts-ignore
							multiple
							name='status'
							label='Select status'
							options={companyStatusOptions}
							value={form.watch('status') ?? []}
							sx={{ width: '300px' }}
							onChange={handleStatusChange}
							isOptionEqualToValue={isOptionEqualToValue('id')}
						/>
						<RHFAutocomplete
							// @ts-ignore
							multiple
							name='condition'
							label='Select condition'
							options={companyConditionOptions}
							value={form.watch('condition') ?? []}
							sx={{ width: '300px' }}
							onChange={handleConditionChange}
							isOptionEqualToValue={isOptionEqualToValue('id')}
						/>
					</FormProvider>
				</Box>
			</Box>
			<Table
				columns={columns}
				data={companies}
				handleSortChange={handleSortChange}
				handleSettingsChange={handleSettingsChange}
				subRowsColumns={subRowsColumns}
				getSubRowsData={(row) => row.branches}
				rowKeyField='id'
				width={1750}
			/>
			<VerifyCompanyModal />
			<VerifyCompanyBranchModal />
			<UpdateCompanyBranchModal />
		</Box>
	);
};

export default CompaniesTable;
