/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	and,
	deleteField,
	DocumentSnapshot,
	getDoc,
	getDocs,
	or,
	query,
	updateDoc,
	where,
	type QueryDocumentSnapshot,
} from 'firebase/firestore';

import { AuthErrorMessages } from 'src/api/@';
import { getEstateAgentOffers, getMyTransactions, getTransactionInvitations } from 'src/api/@db';
import { getDashboardTable } from 'src/api/@db/get-dashboard-table';
import { db } from 'src/firebaseConfig';
import {
	BuyerConfirmationRowData,
	EstateInviteRowData,
	IRowOffers,
	IRowPurchaseInvitation,
	ProspectiveBuyerRowData,
	RowData,
} from 'src/sections/@dashboard/table/types';
import {
	FRoles,
	InvitationStatus,
	Property,
	Transaction,
	TransactionInvitation,
	User,
	UserChangeEmailRequestStatusEnum,
} from 'src/types';
import {
	invitationsCollection,
	propertiesCollection,
	transactionInvitationsCollection,
	transactionsCollection,
} from 'src/constants/firestore';
import getTransactionsSummaries from 'src/api/transaction/get-transactions-summaries';
import { getCountries, getDataByRef, getProperty, getUser, getUserSettings } from 'src/utils/firebase';
import { convertSummaryToObjectByColumns } from 'src/pages/dashboard/components/summaries/Table/utils';
import { TransactionSummaryByColumns, TransactionSummaryColumn } from 'src/pages/dashboard/types/summary';
import { getSortingComparator } from 'src/utils/common';
import { TransactionSummariesFilters } from 'src/pages/dashboard/components/summaries/Toolbar/components/Filters/types';
import { filterTransactionSummaries } from 'src/pages/dashboard/components/summaries/Toolbar/components/Filters/utils';
import getUsers from 'src/api/user/get-users';
import approveChangeEmailRequest from 'src/api/user/approve-change-email-request';
import rejectChangeEmailRequest from 'src/api/user/reject-change-email-request';
import verifyUser from 'src/api/yoti/verify-user';
import { SummaryType } from 'src/pages/dashboard/components/summaries/types';
import { bool } from 'src/constants/common';
import { getUserTransactionSide } from 'src/utils/transaction';
import { transactionState } from 'src/components/modals/transaction/UpdateTransactionState/constants';
import getMonthlyReports, { GetMonthlyReportsResponse } from 'src/api/reports/get-monthly-reports';
import getSolicitorsReports, { GetSolicitorReportsResponse } from 'src/api/reports/get-solicitors-reports';
import { getMonthlyReportSortableRow } from 'src/pages/dashboard/components/Reports/components/Monthly/utils';
import { getSolicitorReportSortableRow } from 'src/pages/dashboard/components/Reports/components/Solicitors/utils';
import getProgressReports, { GetProgressReportsResponse } from 'src/api/reports/get-progress-reports';
import { getProgressReportSortableRow } from 'src/pages/dashboard/components/Reports/components/Progress/utils';
import getCompanies, { GetCompaniesResponse } from 'src/api/company/get-companies';
import { HandleSettingsChangeParams, HandleSortChangeParams } from 'src/components/common/Table/types';
import { SuccessResponse } from 'src/api/@/api-methods';
import approveCompany from 'src/api/company/approve-company';
import rejectCompany from 'src/api/company/reject-company';
import approveCompanyBranch from 'src/api/company/approve-company-branch';
import rejectCompanyBranch from 'src/api/company/reject-company-branch';
import updateCompanyBranch, { UpdateCompanyBranchDto } from 'src/api/company/update-company-branch';
import askForCompanyBranchHelp, { AskForCompanyBranchHelpDto } from 'src/api/company/ask-for-company-branch-help';
import askForCompanyHelp, { AskForCompanyHelpDto } from 'src/api/company/ask-for-company-help';
import updateCompany, { UpdateCompanyDto, UpdateCompanyResponse } from 'src/api/company/update-company';
import { RootState } from '../store';
import {
	Country,
	IDashboardProperty,
	IDashboardTransaction,
	IDashboardUser,
	TableSliceState,
} from '../types/tableSlice';
import { selectUser, selectUserSettings } from './auth';
import { ITransactionSummaryUser } from '../types/transaction';

// ----------------------------------------------------------------------

const initialState: TableSliceState = {
	tableBuyerConfirmationData: [],
	tableInvitedBuyersData: [],
	tableInvitedSellersData: [],
	tableDashboardData: [],
	tableSalesInvitationData: [],
	estateInvitesTableData: [],
	tableOffers: [],
	isVerifyPurchase: false,
	estateCount: {
		invites: 0,
		viewings: 0,
		offers: 0,
	},
	tablePurchaseInvitation: [],
	transactionsSummaries: {
		buyers: [],
		sellers: [],
		type: 'live',
		order: 'desc',
		orderBy: 'status',
		list: {
			data: [],
			isLoading: true,
		},
	},
	properties: { data: [], isLoading: true },
	countries: { data: [], isLoading: true },
	companies: {
		data: { data: [], limit: 20, page: 1, totalCount: 0, totalPages: 0, orderBy: 'modifiedAt', sortOrder: 'desc' },
		filters: { condition: [], status: [], sicCodes: [] },
		isInitialized: false,
		sicCodes: [],
	},
	transactions: { data: [], isLoading: true },
	binTransactions: { data: [], isLoading: true },
	archiveTransactions: { data: [], isLoading: true },
	monthlyReports: { data: { order: 'asc', orderBy: '', data: null }, isLoading: true },
	solicitorsReports: { data: { order: 'asc', orderBy: '', data: null }, isLoading: true },
	progressReports: { data: [], isLoading: true, order: 'asc', orderBy: '' },
	users: {
		list: {
			data: [],
			isLoading: true,
		},
	},
	loading: true,
};

export const selectTableBuyerConfirmationData = (state) => state.table.tableBuyerConfirmationData;
export const selectTableOffers = (state) => state.table.tableOffers;
export const selectTableDashboardData = (state) => state.table.tableDashboardData;
export const selectTableSalesInvitationData = (state) => state.table.tableSalesInvitationData;
export const selectTableInvitesData = (state) => state.table.estateInvitesTableData;
export const selectTablePurchasesData = (state) => state.table.tablePurchaseInvitation;
export const selecIsVerifyPurchase = (state) => state.table.isVerifyPurchase;
export const selectEstateCount = (state) => state.table.estateCount;
export const selectTransactionsSummaries = (state: RootState) => state.table.transactionsSummaries;
export const selectProperties = (state: RootState) => state.table.properties;
export const selectUsers = (state: RootState) => state.table.users;
export const selectMonthlyReports = (state: RootState) => state.table.monthlyReports;
export const selectSolicitorsReports = (state: RootState) => state.table.solicitorsReports;
export const selectProgressReports = (state: RootState) => state.table.progressReports;
export const selectCountries = (state: RootState) => state.table.countries.data;
export const selectCompanies = (state: RootState) => state.table.companies;

export const selectTransactions = (state: RootState) => state.table.transactions;
export const selectArchiveTransactions = (state: RootState) => state.table.archiveTransactions;
export const selectBinTransactions = (state: RootState) => state.table.binTransactions;

export const selectLoading = (state) => state.table.loading;

export const getEstateAgentTableLengths = createAsyncThunk(
	'data/getEstateAgentTableLengths',
	async (_, { getState }) => {
		const {
			auth: { user },
		} = getState() as RootState;
		const countOfInvitesRecived = (await getDashboardTable(db, user, false, true, [FRoles.ESTATE_AGENT]))?.length || 0;
		const countOfOffers = (await getEstateAgentOffers(db, user?.uid, false)).length;
		return {
			invites: countOfInvitesRecived,
			viewings: 0,
			offers: countOfOffers,
		};
	},
);

export const getTableOffers = createAsyncThunk('data/getTableOffers', async (_, { getState, rejectWithValue }) => {
	const {
		auth: { user },
	} = getState() as RootState;
	try {
		const userRef = user?.ref;
		const { docs: transactionInvDocs } = await getDocs(
			query(
				transactionInvitationsCollection,
				where('status', '==', InvitationStatus.REQUEST),
				where('isProspective', '==', true),
				where('invitationRole', '==', FRoles.BUYER),
				where('user', '==', userRef),
			),
		);
		const allRows = transactionInvDocs.map(async (trnsInv) => {
			const trnsInvData = trnsInv.data();
			const trsnRef = (await getDoc(trnsInvData.transaction)) as DocumentSnapshot<Transaction>;
			const trsnData = trsnRef.data() as Transaction;

			const estateAgentRef = await getDoc(trnsInvData.invitingUser);

			const propRef = await getDoc(trsnData.property);
			const propData = propRef.data();

			const { branch, business } = estateAgentRef.data() as User;
			const [branchData, businessData] = await Promise.all([getDoc(branch), getDoc(business)]);
			return {
				transactionInvitation: trnsInv,
				propertyName: propData?.name,
				estateAgent: `${businessData.data()?.tradingName || ''} | ${branchData.data()?.name || ''}`,
				createdAt: trnsInvData.createdAt,
				purchasePrice: 0,
			};
		});
		return (await Promise.all(allRows)).filter(Boolean) as IRowOffers[];
	} catch (error) {
		console.warn(error);
		return rejectWithValue({ error });
	}
});

export const getTableBuyerConfirmationData = createAsyncThunk(
	'data/getTableBuyerConfirmationData',
	async (_, thunkAPI) => {
		try {
			const transactionInvDocs = await getEstateAgentOffers(db);
			const allRows: Promise<BuyerConfirmationRowData>[] = transactionInvDocs.map(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				async (transactionInvitation: QueryDocumentSnapshot<any>) => {
					const data = transactionInvitation.data() as TransactionInvitation;
					const [userSnap, invitingUser, transaction]: [
						DocumentSnapshot<User>,
						DocumentSnapshot<User>,
						DocumentSnapshot<Transaction>,
					] = await Promise.all([getDoc(data.user), getDoc(data.invitingUser), getDoc(data.transaction)]);
					const userBusiness = invitingUser.data()?.business;
					const property = transaction.exists() ? await getDoc(transaction.data().property) : null;
					const business = userBusiness ? await getDoc(userBusiness) : null;
					// const trnsData = transaction.data();
					// const bidPrice = trnsData?.prices.bidPrices.find(({ userRef: u }) => u.id === userSnap.id);

					return {
						id: transactionInvitation.id,
						status: data.status,
						user: userSnap,
						transactionInvitation,
						invitationRole: data.invitationRole,
						businessName: business?.data()?.tradingName || '',
						invitingUser,
						transaction,
						property,
						createdAt: data.createdAt,
						purchasePrice: undefined,
					};
				},
			);
			return await Promise.all(allRows);
		} catch (error) {
			console.warn(error);
			return thunkAPI.rejectWithValue({ error });
		}
	},
);

export const getTableSalesInvitationData = createAsyncThunk('data/getTableSalesInvitationData', async (_, thunkAPI) => {
	const {
		auth: { user },
	} = thunkAPI.getState() as RootState;
	try {
		if (!user) throw new Error(AuthErrorMessages.noLogin);
		const userRef = user.ref;
		const { docs: transactionInvDocs } = await getDocs(
			query(
				transactionInvitationsCollection,
				where('status', '==', InvitationStatus.PENDING),
				where('invitationRole', '==', ''),
				where('user', '==', userRef),
			),
		);

		const allRows: Promise<RowData>[] = transactionInvDocs.map(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			async (transactionInvitation: QueryDocumentSnapshot<any>) => {
				const data = transactionInvitation.data() as TransactionInvitation;
				const [userSnap, invitingUser, transaction]: [
					DocumentSnapshot<User>,
					DocumentSnapshot<User>,
					DocumentSnapshot<Transaction>,
				] = await Promise.all([getDoc(data.user), getDoc(data.invitingUser), getDoc(data.transaction)]);
				const userBusiness = invitingUser.data()?.business;
				const property = transaction.exists() ? await getDoc(transaction.data().property) : null;
				const business = userBusiness ? await getDoc(userBusiness) : null;
				return {
					user: userSnap,
					transactionInvitation,
					businessName: business?.data()?.tradingName || '',
					invitingUser,
					transaction,
					property,
					createdAt: data.createdAt,
					id: transactionInvitation.id,
					invitationRole: data.invitationRole,
				};
			},
		);
		return (await Promise.all(allRows)).filter((item) => item.user.id === user.uid);
	} catch (error) {
		console.warn(error);
		return thunkAPI.rejectWithValue({ error });
	}
});

export const getEstateInvitesTableData = createAsyncThunk('data/getEstateInvitesTableData', async (_, thunkAPI) => {
	const {
		auth: { user },
	} = thunkAPI.getState() as RootState;

	try {
		if (!user) throw new Error(AuthErrorMessages.noLogin);

		const { docs: invitationDocs } = await getDocs(
			query(
				invitationsCollection,
				where('isVisible', '==', true),
				where('invitingUser', '==', user.ref),
				where('status', 'in', [InvitationStatus.ACCEPTED, InvitationStatus.PENDING]),
			),
		);
		const allRows: Promise<EstateInviteRowData>[] = invitationDocs.map(async (transactionInvitation) => {
			const data = transactionInvitation.data() as TransactionInvitation;
			const [userSnap, invitingUser]: [User, User] = await Promise.all([
				getDataByRef<User>(data.user),
				getDataByRef<User>(data.invitingUser),
			]);

			const transaction = data?.transaction && (await getDataByRef<Transaction>(data.transaction));
			const property = transaction?.property && (await getDataByRef<Property>(transaction.property));

			return {
				user: userSnap,
				timestamp: data.createdAt,
				invitationRole: data.invitationRole,
				invitingUser,
				id: transactionInvitation.id,
				status: data.status,
				property,
			};
		});
		return await Promise.all(allRows);
	} catch (error) {
		return thunkAPI.rejectWithValue({ error });
	}
});

export const getTablePurchaseInvitation = createAsyncThunk('data/getTablePurchaseInvitation', async (_, thunkAPI) => {
	const {
		auth: { user },
	} = thunkAPI.getState() as RootState;

	try {
		if (!user) throw new Error(AuthErrorMessages.noLogin);
		const userRef = user.ref;

		const { docs: transactionInvDocs } = await getDocs(
			query(
				transactionInvitationsCollection,
				where('status', '==', InvitationStatus.OFFER_SENT),
				where('isVisible', '==', true),
				where('invitationRole', '==', FRoles.BUYER),
				where('user', '==', userRef),
			),
		);
		const allRows = transactionInvDocs.map(async (trnsInv) => {
			const trnsInvData = trnsInv.data();
			const trsnRef = (await getDoc(trnsInvData.transaction)) as DocumentSnapshot<Transaction>;
			const trsnData = trsnRef.data() as Transaction;
			const estateAgentRef = await getDoc(trnsInvData.invitingUser);

			const propRef = await getDoc(trsnData.property);
			const propData = propRef.data();

			const { givenNames, lastNames, business: businessRef } = estateAgentRef.data() as User;
			const company = await getDoc(businessRef);
			// const bidPrice = trsnData.prices.bidPrices.find(({ userRef: u }) => u.id === user.uid);

			return {
				transactionInvitation: trnsInv,
				transaction: trsnRef,
				propertyName: propData?.name,
				estateAgent: `${givenNames} ${lastNames}`,
				company,
				purchasePrice: 0,
				createdAt: trnsInvData.createdAt,
			} as IRowPurchaseInvitation;
		});
		return await Promise.all(allRows);
	} catch (error) {
		console.warn(error);
		return thunkAPI.rejectWithValue({ error });
	}
});

export const getTableDashboardData = createAsyncThunk<
	ProspectiveBuyerRowData[],
	{ isProspective?: boolean; roles: FRoles[]; onlyVisible?: boolean }
>('data/getTableDashboardData', async ({ isProspective, roles, onlyVisible = true }, thunkAPI) => {
	const {
		auth: { user },
	} = thunkAPI.getState() as RootState;

	try {
		if (!user) throw new Error(AuthErrorMessages.noLogin);
		const userRef = user.ref;

		const transactions = await getMyTransactions(db);
		const transactionInvDocs = await getTransactionInvitations(
			db,
			isProspective || false,
			transactions.map(({ ref }) => ref),
			onlyVisible,
		);

		const allRows: Promise<ProspectiveBuyerRowData | undefined>[] = transactionInvDocs.reduce(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(acc, transactionInvitation: QueryDocumentSnapshot<any>) => {
				const data = transactionInvitation.data() as TransactionInvitation;
				const res: Promise<ProspectiveBuyerRowData | undefined>[] = [
					...acc,
					Promise.all([getDoc(data.user), getDoc(data.invitingUser), getDoc(data.transaction)]).then(
						async ([userSnap, invitingUser, transaction]: [
							DocumentSnapshot<User>,
							DocumentSnapshot<User>,
							DocumentSnapshot<Transaction>,
						]) => {
							if (isProspective && invitingUser.id !== userRef.id) return;

							const userBusiness = invitingUser.data()?.business;
							const property = transaction.exists() ? await getDoc(transaction.data().property) : null;
							const business = userBusiness ? await getDoc(userBusiness) : null;

							// eslint-disable-next-line consistent-return
							return {
								user: userSnap,
								status: data.status,
								transactionInvitation,
								businessName: business ? business.data()?.tradingName : '',
								invitingUser,
								transaction,
								property,
								// transaction.exists() ? transaction.data().prices.agreedPrice?.price : null
								purchasePrice: null,
								invitationRole: data.invitationRole,
								isProspective: data.isProspective,
								id: transactionInvitation.id,
								createdAt: data.createdAt,
							} as ProspectiveBuyerRowData;
						},
					),
				];
				return res;
			},
			[] as Promise<ProspectiveBuyerRowData | undefined>[],
		);
		const filteredList = (await Promise.all(allRows)).filter(
			(item) => item && roles.includes(item.invitationRole) && (isProspective || item.user.id === user.uid),
		);
		return filteredList as ProspectiveBuyerRowData[];
	} catch (error) {
		console.warn(error);
		return thunkAPI.rejectWithValue({ error });
	}
});

export const getTransactionSummaries = createAsyncThunk<
	{ list: TransactionSummaryByColumns[]; buyers: ITransactionSummaryUser[]; sellers: ITransactionSummaryUser[] },
	{ withLoading?: boolean } | undefined
>('transaction-summaries/get', async (_, { getState, signal }) => {
	const state = getState() as RootState;

	const { pinnedTransactions, summariesFilters } = selectUserSettings(state);
	const { type } = selectTransactionsSummaries(state);
	const summaries = await getTransactionsSummaries(signal);

	const filteredSummaries = summaries.filter((s) => {
		if (type === 'live') return s.general.state === transactionState.live || !s.general.state;
		if (type === 'archived') return s.general.state === transactionState.archived;
		if (type === 'frozen') return s.general.state === transactionState.standBy;
		if (type === 'deleted') {
			return (
				s.general.state === transactionState.defunct ||
				s.general.state === transactionState.fallThrough ||
				s.general.state === transactionState.withdrawal
			);
		}
		if (type === 'demo') return s.general.state === transactionState.demo;

		return true;
	});

	const sortedSummaries = filteredSummaries.sort((a, b) => {
		const isFirstPinned = pinnedTransactions.some(({ id }) => id === a.transactionId);
		const isSecondPinned = pinnedTransactions.some(({ id }) => id === b.transactionId);

		return isFirstPinned && isSecondPinned ? -1 : isFirstPinned ? -1 : 1;
	});

	const listByColumns = sortedSummaries.map(convertSummaryToObjectByColumns);
	const filteredListByColumns = filterTransactionSummaries(summariesFilters ?? {}, listByColumns);

	const { buyers, sellers } = filteredSummaries.reduce<{
		sellers: ITransactionSummaryUser[];
		buyers: ITransactionSummaryUser[];
	}>(
		(acc, summary) => {
			summary.sellSide.sellers.forEach((seller) => {
				const isExistingSeller = acc.sellers.some(({ id }) => id === seller.id);

				if (!isExistingSeller) acc.sellers.push(seller);
			});

			summary.buySide.buyers.forEach((buyer) => {
				const isExistingBuyer = acc.buyers.some(({ id }) => id === buyer.id);

				if (!isExistingBuyer) acc.buyers.push(buyer);
			});

			return acc;
		},
		{ sellers: [], buyers: [] },
	);

	return { list: filteredListByColumns, buyers, sellers };
});

export const updateTransactionSummariesColumns = createAsyncThunk<void, { columns: TransactionSummaryColumn[] }>(
	'transaction-summaries-columns/update',
	async ({ columns }, { getState }) => {
		const {
			auth: { user },
		} = getState() as RootState;

		if (!user) return;

		await updateDoc<unknown>(user.ref, {
			'settings.summariesColumns': columns,
		});
	},
);

export const updateTransactionSummariesFilters = createAsyncThunk<void, { filters: TransactionSummariesFilters }>(
	'transaction-summaries-filters/update',
	async ({ filters }, { getState }) => {
		try {
			const {
				auth: { user },
			} = getState() as RootState;

			if (!user) return;

			const formattedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
				const isExistingValue = Object.values(value ?? {}).filter(Boolean).length;

				acc[`settings.summariesFilters.${key}`] = isExistingValue ? value : deleteField();

				return acc;
			}, {});

			await updateDoc<unknown>(user.ref, formattedFilters);
		} catch (e) {
			console.log('error occurred', e);
			throw e;
		}
	},
);

export const getProperties = createAsyncThunk<IDashboardProperty[], { withLoading?: boolean } | undefined>(
	'properties/get',
	async (_, { getState }) => {
		const { uid } = selectUser(getState());
		const settings = await getUserSettings(uid);
		const { docs: propertiesSnapshots } = await getDocs(query(propertiesCollection, where('onSale', '==', false)));

		return await Promise.all(
			propertiesSnapshots
				.sort((a, b) => {
					const isFirstPinned = settings?.pinnedProperties.some(({ id }) => id === a.id);
					const isSecondPinned = settings?.pinnedProperties.some(({ id }) => id === b.id);

					return isFirstPinned && isSecondPinned ? -1 : isFirstPinned ? -1 : 1;
				})
				.map(async (snapshot) => {
					const data = snapshot.data();
					const proprietors = await Promise.all(
						(data.proprietors ?? []).map((proprietor) =>
							getUser(proprietor.id).then(
								(user) => ({ givenNames: user.data.givenNames, lastNames: user.data.lastNames, id: user.id }),
								() => ({ givenNames: 'Deleted', lastNames: 'User', id: '' }),
							),
						),
					);

					return { ...data, proprietors, id: snapshot.id };
				}),
		);
	},
);

export const getTransactions = createAsyncThunk<IDashboardTransaction[], { withLoading?: boolean } | undefined>(
	'transactions/get',
	async (_, { getState }) => {
		try {
			const { uid } = selectUser(getState());
			const { docs } = await getDocs(
				query(
					transactionsCollection,
					or(
						where(`parties.${uid}.sell.seller.approved`, 'in', bool),
						where(`parties.${uid}.buy.buyer.approved`, 'in', bool),
						where(`parties.${uid}.view.buyer.approved`, 'in', bool),
						where(`parties.${uid}.sell.agent.approved`, 'in', bool),
						where(`parties.${uid}.buy.agent.approved`, 'in', bool),
						where(`parties.${uid}.sell.solicitor.approved`, 'in', bool),
						where(`parties.${uid}.buy.solicitor.approved`, 'in', bool),
						where(`parties.${uid}.sell.tradesPerson.approved`, 'in', bool),
						where(`parties.${uid}.buy.tradesPerson.approved`, 'in', bool),
						where(`parties.${uid}.sell.mortgageBroker.approved`, 'in', bool),
						where(`parties.${uid}.buy.mortgageBroker.approved`, 'in', bool),
					),
				),
			);

			const transactions: IDashboardTransaction[] = [];

			await Promise.all(
				docs.map(async (d) => {
					const data = d.data();

					if (data.state === transactionState.defunct) return;

					const side = getUserTransactionSide(data, uid);

					if (!side) return;

					const property = await getProperty(data.property.id).catch(() => null);

					if (!property) return;

					transactions.push({ ...data, side, id: d.id, displayAddress: property.data.address.displayAddress });
				}),
			);

			return transactions;
		} catch (e) {
			console.log(e);

			throw e;
		}
	},
);

export const getArchiveTransactions = createAsyncThunk<IDashboardTransaction[], { withLoading?: boolean } | undefined>(
	'archive-transactions/get',
	async (_, { getState }) => {
		try {
			const { uid, hiddenTransactions } = selectUser(getState());
			const { docs } = await getDocs(
				query(
					transactionsCollection,
					and(
						or(
							where(`parties.${uid}.sell.seller.approved`, 'in', bool),
							where(`parties.${uid}.buy.buyer.approved`, 'in', bool),
							where(`parties.${uid}.view.buyer.approved`, 'in', bool),
							where(`parties.${uid}.sell.agent.approved`, 'in', bool),
							where(`parties.${uid}.buy.agent.approved`, 'in', bool),
							where(`parties.${uid}.sell.solicitor.approved`, 'in', bool),
							where(`parties.${uid}.buy.solicitor.approved`, 'in', bool),
							where(`parties.${uid}.sell.tradesPerson.approved`, 'in', bool),
							where(`parties.${uid}.buy.tradesPerson.approved`, 'in', bool),
							where(`parties.${uid}.sell.mortgageBroker.approved`, 'in', bool),
							where(`parties.${uid}.buy.mortgageBroker.approved`, 'in', bool),
						),
						where('state', '==', transactionState.archived),
					),
				),
			);

			const transactions: IDashboardTransaction[] = [];

			await Promise.all(
				docs.map(async (d) => {
					const isHidden = hiddenTransactions?.some((t) => t.id === d.id);

					if (isHidden) return;

					const data = d.data();

					const side = getUserTransactionSide(data, uid);

					if (!side) return;

					const property = await getProperty(data.property.id).catch(() => null);

					if (!property) return;

					transactions.push({ ...data, side, id: d.id, displayAddress: property.data.address.displayAddress });
				}),
			);

			return transactions;
		} catch (e) {
			console.log(e);

			throw e;
		}
	},
);

export const getBinTransactions = createAsyncThunk<IDashboardTransaction[], { withLoading?: boolean } | undefined>(
	'bin-transactions/get',
	async (_, { getState }) => {
		try {
			const { uid, hiddenTransactions } = selectUser(getState());
			const { docs } = await getDocs(
				query(
					transactionsCollection,
					and(
						or(
							where(`parties.${uid}.sell.seller.approved`, 'in', bool),
							where(`parties.${uid}.buy.buyer.approved`, 'in', bool),
							where(`parties.${uid}.view.buyer.approved`, 'in', bool),
							where(`parties.${uid}.sell.agent.approved`, 'in', bool),
							where(`parties.${uid}.buy.agent.approved`, 'in', bool),
							where(`parties.${uid}.sell.solicitor.approved`, 'in', bool),
							where(`parties.${uid}.buy.solicitor.approved`, 'in', bool),
							where(`parties.${uid}.sell.tradesPerson.approved`, 'in', bool),
							where(`parties.${uid}.buy.tradesPerson.approved`, 'in', bool),
							where(`parties.${uid}.sell.mortgageBroker.approved`, 'in', bool),
							where(`parties.${uid}.buy.mortgageBroker.approved`, 'in', bool),
						),
						where('state', '==', transactionState.defunct),
					),
				),
			);

			const transactions: IDashboardTransaction[] = [];

			await Promise.all(
				docs.map(async (d) => {
					const isHidden = hiddenTransactions?.some((t) => t.id === d.id);

					if (isHidden) return;

					const data = d.data();

					const side = getUserTransactionSide(data, uid);

					if (!side) return;

					const property = await getProperty(data.property.id).catch(() => null);

					if (!property) return;

					transactions.push({ ...data, side, id: d.id, displayAddress: property.data.address.displayAddress });
				}),
			);

			return transactions;
		} catch (e) {
			console.log(e);

			throw e;
		}
	},
);

export const getUsersThunk = createAsyncThunk<IDashboardUser[]>('users/get', async () => await getUsers());

export const getMonthlyReportsThunk = createAsyncThunk<GetMonthlyReportsResponse>(
	'reports/monthly',
	async () => await getMonthlyReports(),
);

export const getSolicitorsReportsThunk = createAsyncThunk<GetSolicitorReportsResponse>(
	'reports/solicitors',
	async () => await getSolicitorsReports(),
);

export const getProgressReportsThunk = createAsyncThunk<GetProgressReportsResponse>(
	'reports/progress',
	async () => await getProgressReports(),
);

export const getCompaniesThunk = createAsyncThunk<GetCompaniesResponse>(
	'companies/get',
	async (_, { getState, rejectWithValue, signal }) => {
		try {
			const state = getState() as RootState;

			const companies = selectCompanies(state);

			return await getCompanies(
				{
					page: companies.data.page,
					limit: companies.data.limit,
					orderBy: companies.data.orderBy,
					sortOrder: companies.data.sortOrder,
					search: companies.filters.search,
					sicCodes: companies.filters.sicCodes,
					status: companies.filters.status,
					condition: companies.filters.condition,
				},
				signal,
			);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const approveCompanyThunk = createAsyncThunk<SuccessResponse, string>(
	'company/approve',
	async (id, { rejectWithValue }) => {
		try {
			return await approveCompany(id);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const rejectCompanyThunk = createAsyncThunk<SuccessResponse, string>(
	'company/reject',
	async (id, { rejectWithValue }) => {
		try {
			return await rejectCompany(id);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const approveCompanyBranchThunk = createAsyncThunk<SuccessResponse, { companyId: string; id: string }>(
	'company-branch/approve',
	async ({ companyId, id }, { rejectWithValue }) => {
		try {
			return await approveCompanyBranch(companyId, id);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const rejectCompanyBranchThunk = createAsyncThunk<SuccessResponse, { companyId: string; id: string }>(
	'company-branch/reject',
	async ({ companyId, id }, { rejectWithValue }) => {
		try {
			return await rejectCompanyBranch(companyId, id);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const updateCompanyThunk = createAsyncThunk<UpdateCompanyResponse, UpdateCompanyDto>(
	'company/update',
	async (payload, { rejectWithValue }) => {
		try {
			return await updateCompany(payload);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const updateCompanyBranchThunk = createAsyncThunk<SuccessResponse, UpdateCompanyBranchDto>(
	'company-branch/update',
	async (payload, { rejectWithValue }) => {
		try {
			return await updateCompanyBranch(payload);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const askForCompanyHelpThunk = createAsyncThunk<SuccessResponse, AskForCompanyHelpDto>(
	'company/ask-for-help',
	async (payload, { rejectWithValue }) => {
		try {
			return await askForCompanyHelp(payload);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const askForCompanyBranchHelpThunk = createAsyncThunk<SuccessResponse, AskForCompanyBranchHelpDto>(
	'company-branch/ask-for-help',
	async (payload, { rejectWithValue }) => {
		try {
			return await askForCompanyBranchHelp(payload);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const approveChangeEmailRequestThunk = createAsyncThunk<void, { requestId: string; userId: string }>(
	'change-email-request/approve',
	async ({ requestId, userId }) => {
		await approveChangeEmailRequest(requestId, userId);
	},
);

export const rejectChangeEmailRequestThunk = createAsyncThunk<void, { requestId: string; userId: string }>(
	'change-email-request/reject',
	async ({ requestId, userId }) => {
		await rejectChangeEmailRequest(requestId, userId);
	},
);

export const verifyUserThunk = createAsyncThunk<void, { userId: string }>('user/verify', async ({ userId }) => {
	await verifyUser(userId);
});

export const getCountriesThunk = createAsyncThunk<Country[]>('countries/get', async () => await getCountries());

const tableSlice = createSlice({
	name: 'data',
	initialState,
	reducers: {
		tooglePurchaseBuyer: (state) => {
			state.isVerifyPurchase = !state.isVerifyPurchase;
		},
		setSummariesType: (state, { payload }: PayloadAction<SummaryType>) => {
			state.transactionsSummaries.type = payload;
		},
		sortTransactionSummaries: (
			state,
			{ payload: { order, orderBy } }: PayloadAction<{ order: 'asc' | 'desc'; orderBy: TransactionSummaryColumn }>,
		) => {
			state.transactionsSummaries.order = order;
			state.transactionsSummaries.orderBy = orderBy;
			state.transactionsSummaries.list.data = state.transactionsSummaries.list.data
				.slice()
				.sort(getSortingComparator(order, (summary) => summary[orderBy].value));
		},
		sortUsers: (
			state,
			{ payload: { order, orderBy } }: PayloadAction<{ order: 'asc' | 'desc'; orderBy: keyof IDashboardUser }>,
		) => {
			state.users.order = order;
			state.users.orderBy = orderBy;
			state.users.list.data = state.users.list.data.slice().sort(
				getSortingComparator(order, (u) => {
					if (orderBy === 'professions') return u.professions[0];
					if (orderBy === 'status' && u.changeEmailRequest?.status === UserChangeEmailRequestStatusEnum.ACTIVE) {
						return 'EMAIL CHANGE REQUESTED';
					}
					if (orderBy === 'status' && u.changeEmailRequest?.status === UserChangeEmailRequestStatusEnum.APPROVED) {
						return 'PENDING EMAIL CHANGE';
					}

					return u[orderBy];
				}),
			);
		},

		sortMonthlyReports: (
			state,
			{ payload: { order, orderBy } }: PayloadAction<{ order: 'asc' | 'desc'; orderBy: string }>,
		) => {
			state.monthlyReports.data.order = order;
			state.monthlyReports.data.orderBy = orderBy;

			if (state.monthlyReports.data?.data) {
				state.monthlyReports.data.data.rows = state.monthlyReports.data.data.rows.sort(
					getSortingComparator(order, (row) => row[orderBy].value),
				);
			}
		},
		sortSolicitorsReports: (
			state,
			{ payload: { order, orderBy } }: PayloadAction<{ order: 'asc' | 'desc'; orderBy: string }>,
		) => {
			state.solicitorsReports.data.order = order;
			state.solicitorsReports.data.orderBy = orderBy;

			if (state.solicitorsReports.data?.data) {
				state.solicitorsReports.data.data.rows = state.solicitorsReports.data.data.rows.sort(
					getSortingComparator(order, (row) => row[orderBy].value),
				);
			}
		},
		sortProgressReports: (
			state,
			{ payload: { order, orderBy } }: PayloadAction<{ order: 'asc' | 'desc'; orderBy: string }>,
		) => {
			state.progressReports.order = order;
			state.progressReports.orderBy = orderBy;

			if (state.progressReports.data) {
				state.progressReports.data = state.progressReports.data.sort(
					getSortingComparator(order, (row) => row[orderBy].value),
				);
			}
		},
		sortCompanies: (state, { payload: { order, orderBy } }: PayloadAction<HandleSortChangeParams>) => {
			state.companies.data.orderBy = orderBy;
			state.companies.data.sortOrder = order;
		},
		changeCompaniesSettings: (state, { payload: { page, limit } }: PayloadAction<HandleSettingsChangeParams>) => {
			state.companies.data.page = page;
			state.companies.data.limit = limit;
		},
		setCompaniesFilters: (state, { payload }: PayloadAction<TableSliceState['companies']['filters']>) => {
			state.companies.filters = { ...state.companies.filters, ...payload };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getEstateAgentTableLengths.fulfilled, (state, { payload }) => {
			state.estateCount = payload;
		});
		builder.addCase(getTableBuyerConfirmationData.fulfilled, (state, action) => {
			state.tableBuyerConfirmationData = action.payload;
			state.loading = false;
		});
		builder.addCase(getTableBuyerConfirmationData.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(getTableOffers.fulfilled, (state, action) => {
			state.tableOffers = action.payload;
			state.loading = false;
		});

		builder.addCase(getTableOffers.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(getTableDashboardData.fulfilled, (state, action) => {
			state.tableDashboardData = action.payload;
			state.loading = false;
		});
		builder.addCase(getTableDashboardData.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(getTableSalesInvitationData.fulfilled, (state, action) => {
			state.tableSalesInvitationData = action.payload;
			state.loading = false;
		});
		builder.addCase(getTableSalesInvitationData.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getEstateInvitesTableData.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getEstateInvitesTableData.fulfilled, (state, action) => {
			state.loading = false;
			state.estateInvitesTableData = action.payload;
		});

		builder.addCase(getTablePurchaseInvitation.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getTablePurchaseInvitation.fulfilled, (state, action) => {
			state.loading = false;
			state.tablePurchaseInvitation = action.payload;
		});

		builder.addCase(getTransactionSummaries.pending, (state, { meta }) => {
			state.transactionsSummaries.list.isLoading = !!meta.arg?.withLoading;
		});
		builder.addCase(getTransactionSummaries.fulfilled, (state, { payload }) => {
			let sortedList = payload.list;
			const { order, orderBy } = state.transactionsSummaries;

			if (order && orderBy) {
				sortedList = sortedList.slice(0).sort(getSortingComparator(order, (summary) => summary[orderBy].value));
			}

			state.transactionsSummaries.list.data = sortedList;
			state.transactionsSummaries.sellers = payload.sellers;
			state.transactionsSummaries.buyers = payload.buyers;
			state.transactionsSummaries.list.isLoading = false;
		});
		builder.addCase(getTransactionSummaries.rejected, (state, { error }) => {
			if (error.name !== 'AbortError') {
				state.transactionsSummaries.list.error = error;
				state.transactionsSummaries.list.isLoading = false;
			}
		});

		builder.addCase(getProperties.pending, (state, { meta }) => {
			state.properties.isLoading = !!meta.arg?.withLoading;
		});
		builder.addCase(getProperties.fulfilled, (state, { payload }) => {
			state.properties.data = payload;
			state.properties.isLoading = false;
		});
		builder.addCase(getProperties.rejected, (state, { error }) => {
			state.properties.isLoading = false;
			state.properties.error = error;
		});

		builder.addCase(getCountriesThunk.pending, (state) => {
			state.countries.isLoading = true;
		});
		builder.addCase(getCountriesThunk.fulfilled, (state, { payload }) => {
			state.countries.data = payload;
			state.countries.isLoading = false;
		});
		builder.addCase(getCountriesThunk.rejected, (state, { error }) => {
			state.countries.isLoading = false;
			state.countries.error = error;
		});

		builder.addCase(getTransactions.pending, (state, { meta }) => {
			state.transactions.isLoading = !!meta.arg?.withLoading;
		});
		builder.addCase(getTransactions.fulfilled, (state, { payload }) => {
			state.transactions.data = payload;
			state.transactions.isLoading = false;
		});
		builder.addCase(getTransactions.rejected, (state, { error }) => {
			state.transactions.isLoading = false;
			state.transactions.error = error;
		});

		builder.addCase(getBinTransactions.pending, (state, { meta }) => {
			state.binTransactions.isLoading = !!meta.arg?.withLoading;
		});
		builder.addCase(getBinTransactions.fulfilled, (state, { payload }) => {
			state.binTransactions.data = payload;
			state.binTransactions.isLoading = false;
		});
		builder.addCase(getBinTransactions.rejected, (state, { error }) => {
			state.binTransactions.isLoading = false;
			state.binTransactions.error = error;
		});

		builder.addCase(getArchiveTransactions.pending, (state, { meta }) => {
			state.archiveTransactions.isLoading = !!meta.arg?.withLoading;
		});
		builder.addCase(getArchiveTransactions.fulfilled, (state, { payload }) => {
			state.archiveTransactions.data = payload;
			state.archiveTransactions.isLoading = false;
		});
		builder.addCase(getArchiveTransactions.rejected, (state, { error }) => {
			state.archiveTransactions.isLoading = false;
			state.archiveTransactions.error = error;
		});

		builder.addCase(getUsersThunk.pending, (state) => {
			state.users.list.isLoading = true;
		});
		builder.addCase(getUsersThunk.fulfilled, (state, { payload }) => {
			state.users.list.data = payload;
			state.users.list.isLoading = false;
		});
		builder.addCase(getUsersThunk.rejected, (state, { error }) => {
			state.users.list.isLoading = false;
			state.users.list.error = error;
		});

		builder.addCase(getMonthlyReportsThunk.pending, (state) => {
			state.monthlyReports.isLoading = true;
		});
		builder.addCase(getMonthlyReportsThunk.fulfilled, (state, { payload }) => {
			state.monthlyReports.data.data = {
				total: payload.total,
				rows: payload.rows.map(getMonthlyReportSortableRow),
			};
			state.monthlyReports.isLoading = false;
		});
		builder.addCase(getMonthlyReportsThunk.rejected, (state, { error }) => {
			state.monthlyReports.isLoading = false;
			state.monthlyReports.error = error;
		});

		builder.addCase(getProgressReportsThunk.pending, (state) => {
			state.progressReports.isLoading = true;
		});
		builder.addCase(getProgressReportsThunk.fulfilled, (state, { payload }) => {
			state.progressReports.data = payload.progress.map(getProgressReportSortableRow);
			state.progressReports.isLoading = false;
		});
		builder.addCase(getProgressReportsThunk.rejected, (state, { error }) => {
			state.progressReports.isLoading = false;
			state.progressReports.error = error;
		});

		builder.addCase(getSolicitorsReportsThunk.pending, (state) => {
			state.solicitorsReports.isLoading = true;
		});
		builder.addCase(getSolicitorsReportsThunk.fulfilled, (state, { payload }) => {
			state.solicitorsReports.data.data = {
				total: payload.total,
				rows: payload.rows.map(getSolicitorReportSortableRow),
			};
			state.solicitorsReports.isLoading = false;
		});
		builder.addCase(getSolicitorsReportsThunk.rejected, (state, { error }) => {
			state.solicitorsReports.isLoading = false;
			state.solicitorsReports.error = error;
		});

		builder.addCase(getCompaniesThunk.pending, (state) => {
			state.companies.isInitialized = false;
		});
		builder.addCase(getCompaniesThunk.fulfilled, (state, { payload }) => {
			state.companies.data = { ...state.companies.data, ...payload, data: payload.companies };
			state.companies.sicCodes = payload.sicCodes;
			state.companies.isInitialized = true;
		});
		builder.addCase(getCompaniesThunk.rejected, (state, { error }) => {
			if (error.name !== 'AbortError') {
				state.companies.isInitialized = true;
				state.companies.error = error;
			}
		});
	},
});

export const {
	tooglePurchaseBuyer,
	sortTransactionSummaries,
	sortUsers,
	setSummariesType,
	sortMonthlyReports,
	sortSolicitorsReports,
	sortProgressReports,
	sortCompanies,
	changeCompaniesSettings,
	setCompaniesFilters,
} = tableSlice.actions;

export default tableSlice.reducer;
