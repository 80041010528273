import { call } from '../@';
import { SuccessResponse } from '../@/api-methods';

export type UpdateTransactionDto = {
	id: string;
	propertyId?: string;
};

export type UpdateTransactionResponse = SuccessResponse;

export default async function updateTransaction(dto: UpdateTransactionDto) {
	return await call('transaction-update', dto);
}
