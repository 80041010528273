import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import resetUserVerification from 'src/api/user/reset-verification';
import verificationUpdateNotification from 'src/api/user/verification-update-notification';
import { ConfirmationModal } from 'src/components/modals';
import modals from 'src/constants/modals';
import useLoading from 'src/hooks/useLoading';
import { selectModal } from 'src/redux/slices/modal';
import { getUsersThunk } from 'src/redux/slices/tableSlice';
import { dispatch, useSelector } from 'src/redux/store';
import { IDashboardUser } from 'src/redux/types';
import { OnboardingStepStatusEnum, VerificationSessionStatus } from 'src/types';
import { getUserRef } from 'src/utils/firebase';
import { findAllPropertyOnboardings } from 'src/utils/firebase/property-onboarding';
import { findAllTransactionOnboardingSnapshots } from 'src/utils/onboarding';
import { handleCloseModal } from 'src/utils/propertyHelpers';

const Content = () => {
	const { payload: user } = useSelector(selectModal<IDashboardUser>);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();

	const userRef = getUserRef(user.uid);

	const handleCancel = () => handleCloseModal();

	const handleReset = async () => {
		try {
			setIsLoading(true);

			const [propertyOnboardings, transactionOnboardings] = await Promise.all([
				findAllPropertyOnboardings(user.uid),
				findAllTransactionOnboardingSnapshots(user.uid),
			]);

			const update = {
				verification: OnboardingStepStatusEnum.PENDING,
				verificationInProgress: OnboardingStepStatusEnum.PENDING,
				verificationFail: OnboardingStepStatusEnum.PENDING,
				verificationIssue: OnboardingStepStatusEnum.PASSED,
				verificationIntroduction: OnboardingStepStatusEnum.PASSED,
			};

			await Promise.all([
				updateDoc(userRef, { verificationStatus: VerificationSessionStatus.PENDING }),
				...propertyOnboardings.map((o) => updateDoc(o.ref, update)),
				...transactionOnboardings.map((o) => updateDoc(o.ref, update)),
			]);

			resetUserVerification(user.uid);
			dispatch(getUsersThunk());

			setIsLoading(false);
			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		}
	};

	const handleApprove = async () => {
		try {
			setIsLoading(true);

			const [propertyOnboardings, transactionOnboardings] = await Promise.all([
				findAllPropertyOnboardings(user.uid),
				findAllTransactionOnboardingSnapshots(user.uid),
			]);

			const update = { verificationFail: OnboardingStepStatusEnum.PASSED };

			await Promise.all([
				updateDoc(userRef, { verificationStatus: VerificationSessionStatus.VERIFIED }),
				...propertyOnboardings.map((o) => updateDoc(o.ref, update)),
				...transactionOnboardings.map((o) => updateDoc(o.ref, update)),
			]);

			await verificationUpdateNotification(user.uid, 'verificationError');
			dispatch(getUsersThunk());

			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Box>
				<Typography>
					Are you sure you want to manually verify{' '}
					<b>
						{user.givenNames} {user.lastNames}
					</b>
					?
				</Typography>
			</Box>
			<Box display='flex' gap='15px' justifyContent='flex-end' mt='15px'>
				<LoadingButton onClick={handleCancel} variant='outlined' size='large' loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton variant='contained' size='large' loading={isLoading} onClick={handleReset}>
					Reset
				</LoadingButton>
				<LoadingButton variant='contained' size='large' loading={isLoading} onClick={handleApprove}>
					Approve
				</LoadingButton>
			</Box>
		</>
	);
};

const UserVerificationFailedModal = () => (
	<ConfirmationModal
		sx={{ maxWidth: '550px' }}
		modalName={modals.userVerificationFailed}
		title='VERIFY USER'
		confirmText='Confirm'
		cancelText='Cancel'
		withButtons={false}
		description={<Content />}
	/>
);

export default UserVerificationFailedModal;
