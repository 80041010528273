import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { ErrorViewProps } from './types';
import Iconify from '../Iconify';

const ErrorView: FC<ErrorViewProps> = ({ boxProps, typographyProps, error, children, withMeta = true }) => {
	if (!error) return <>{children}</>;

	return (
		<Box
			height='100%'
			width='100%'
			display='flex'
			alignItems='center'
			flexDirection='column'
			justifyContent='center'
			gap='20px'
			{...boxProps}
		>
			{withMeta && (
				<>
					<Iconify icon='bx:error' fontSize={150} color='red' />
					<Typography fontSize={40} fontWeight={700} color='error'>
						Unexpected error occurred:
					</Typography>
				</>
			)}
			<Typography fontSize={25} fontWeight={600} color='error' {...typographyProps}>
				{error.message}
			</Typography>
		</Box>
	);
};

export default ErrorView;
