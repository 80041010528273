import { FirestoreError, GeoPoint, updateDoc } from 'firebase/firestore';
import { UserData } from 'src/api/auth/@user-data';
import { getBranchRef, getFirmRef } from './firebase';

export const updateUser = async (userData: UserData, originalNewData, setSuccess, enqueueSnackbar) => {
	const newData = { ...originalNewData };

	if (!userData.isPrincipal && newData.business && newData.branch) {
		if (typeof userData.business !== 'string' && typeof userData.branch !== 'string') {
			newData.branch = getBranchRef(newData.business, newData.branch);
			newData.business = getFirmRef(newData.business);
		}
	}
	if (newData.address.coordinates) {
		newData.address.coordinates = new GeoPoint(newData.address.coordinates.lat, newData.address.coordinates.lng);
	}
	if (userData.isAncillary) {
		delete newData.address;
	}
	if (userData.isPrincipal) {
		delete newData.branch;
		delete newData.business;
	}

	try {
		await updateDoc(userData.ref, newData);

		setSuccess(true);
	} catch (error) {
		if ((error as FirestoreError).code === 'permission-denied') {
			enqueueSnackbar('Something went wrong', {
				variant: 'error',
			});
		}
		console.warn(error);
	}
};
