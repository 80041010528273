import { useSelector } from 'react-redux';
import { selectTransactionsSummaries, sortTransactionSummaries } from 'src/redux/slices/tableSlice';
import { transactionSummaryColumnsByKeys } from 'src/pages/dashboard/constants/summary';
import { TableSortLabel } from '@mui/material';
import { dispatch } from 'src/redux/store';
import { TransactionSummaryColumn } from 'src/pages/dashboard/types/summary';
import { selectUserSettings } from 'src/redux/slices/auth';
import { TableCell, TableHead, TableRow } from '../../../styles';

const SummariesTableHead = () => {
	const { orderBy, order } = useSelector(selectTransactionsSummaries);
	const settings = useSelector(selectUserSettings);

	const handleSort = (key: TransactionSummaryColumn) => () => {
		const isAsc = orderBy === key && order === 'asc';
		dispatch(sortTransactionSummaries({ order: isAsc ? 'desc' : 'asc', orderBy: key }));
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell width={50} />
				{settings.summariesColumns.map((columnName) => {
					const column = transactionSummaryColumnsByKeys[columnName];

					return (
						<TableCell minWidth={column.width} key={column.key} sx={{ pt: 0 }}>
							<TableSortLabel
								active={orderBy === column.key}
								direction={orderBy === column.key ? order : 'asc'}
								onClick={handleSort(column.key)}
							>
								{column.name}
							</TableSortLabel>
						</TableCell>
					);
				})}
			</TableRow>
		</TableHead>
	);
};

export default SummariesTableHead;
