import { Box, Table, TableBody, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import HeadCell from 'src/components/HeadCell';
import Progress from 'src/components/Progress';
import { TableCell, TableHead, TableRow } from 'src/pages/dashboard/styles';
import { getSolicitorsReportsThunk, selectSolicitorsReports, sortSolicitorsReports } from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import Iconify from 'src/components/Iconify';
import useLoading from 'src/hooks/useLoading';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { downloadCsv } from 'src/utils/csv';
import { getSolicitorReportSortableRow } from './utils';

const SolicitorsTable = () => {
	const { data: reports, isLoading: areReportsLoading, error } = useSelector(selectSolicitorsReports);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();

	const handleSortChange = ({ order, orderBy }) => dispatch(sortSolicitorsReports({ order, orderBy }));

	useEffect(() => {
		dispatch(getSolicitorsReportsThunk());
	}, []);

	if (areReportsLoading) return <Progress />;
	if (error || !reports.data) {
		return (
			<Box p='15px'>
				<Typography color='red' variant='h4'>
					{error?.message ?? 'Something went wrong'}
				</Typography>
			</Box>
		);
	}

	const totalRow = getSolicitorReportSortableRow(reports.data.total);

	const handleDownload = () => {
		try {
			if (!reports.data) return;

			setIsLoading(true);

			const rows = [
				[
					'Solicitor',
					'Firm',
					'Pending',
					'Live',
					// 'Total transactions (pending)',
					'total',
					'Total quoted (incl VAT)',
					'Total paid out (incl VAT)',
					'Avg transaction time (sellside)',
					'Avg transaction time (buyside)',
				],
				...(reports.data.rows.map((r) => [
					r.name.label,
					r.firm.label,
					r.livePendingTransactions.label,
					r.liveApprovedTransactions.label,
					// r.totalPendingTransactions.label,
					r.totalApprovedTransactions.label,
					r.totalRevenue.label,
					r.totalPaidOut.label,
					r.averageSellSideTransactionTime.label,
					r.averageBuySideTransactionTime.label,
				]) ?? []),
				[
					'TOTAL',
					totalRow.firm.label,
					totalRow.livePendingTransactions.label,
					totalRow.liveApprovedTransactions.label,
					// totalRow.totalPendingTransactions.label,
					totalRow.totalApprovedTransactions.label,
					totalRow.totalRevenue.label,
					totalRow.totalPaidOut.label,
					totalRow.averageSellSideTransactionTime.label,
					totalRow.averageBuySideTransactionTime.label,
				],
			];

			const date = moment().format('YYYY_MM_DD');

			downloadCsv(`solicitors_report_${date}.csv`, rows);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<Table>
				<TableHead>
					<TableRow>
						<HeadCell
							name='Solicitor'
							columnKey='name'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
							minWidth={200}
						/>
						<HeadCell
							name='Firm'
							columnKey='firm'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
							minWidth={175}
						/>
						<HeadCell
							name='Pending'
							columnKey='livePendingTransactions'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
							minWidth={200}
						/>
						<HeadCell
							name='Live'
							columnKey='liveApprovedTransactions'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
							minWidth={200}
						/>
						{/* <HeadCell
							name='Total transactions (pending)'
							columnKey='totalPendingTransactions'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
							minWidth={200}
						/> */}
						<HeadCell
							name='Total'
							columnKey='totalApprovedTransactions'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
							minWidth={200}
						/>
						<HeadCell
							name='Total quoted (incl VAT)'
							columnKey='totalRevenue'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
							minWidth={200}
						/>
						<HeadCell
							name='Total paid out (incl VAT)'
							columnKey='totalPaidOut'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
							minWidth={200}
						/>
						<HeadCell
							name='Avg transaction time (sellside)'
							columnKey='averageSellSideTransactionTime'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
							minWidth={200}
						/>
						<HeadCell
							name='Avg transaction time (buyside)'
							columnKey='averageBuySideTransactionTime'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
							minWidth={200}
						/>
					</TableRow>
				</TableHead>
				<TableBody>
					{reports.data.rows?.map((row) => (
						<TableRow key={row.id.label}>
							<TableCell>{row.name.label}</TableCell>
							<TableCell>{row.firm.label}</TableCell>
							<TableCell>{row.livePendingTransactions.label}</TableCell>
							<TableCell>{row.liveApprovedTransactions.label}</TableCell>
							{/* <TableCell>{row.totalPendingTransactions.label}</TableCell> */}
							<TableCell>{row.totalApprovedTransactions.label}</TableCell>
							<TableCell>{row.totalRevenue.label}</TableCell>
							<TableCell>{row.totalPaidOut.label}</TableCell>
							<TableCell>{row.averageSellSideTransactionTime.label}</TableCell>
							<TableCell>{row.averageBuySideTransactionTime.label}</TableCell>
						</TableRow>
					))}
					{!!reports.data.rows.length && (
						<TableRow isActive>
							<TableCell>TOTAL</TableCell>
							<TableCell>{totalRow.firm.label}</TableCell>
							<TableCell>{totalRow.livePendingTransactions.label}</TableCell>
							<TableCell>{totalRow.liveApprovedTransactions.label}</TableCell>
							{/* <TableCell>{totalRow.totalPendingTransactions.label}</TableCell> */}
							<TableCell>{totalRow.totalApprovedTransactions.label}</TableCell>
							<TableCell>{totalRow.totalRevenue.label}</TableCell>
							<TableCell>{totalRow.totalPaidOut.label}</TableCell>
							<TableCell>{totalRow.averageSellSideTransactionTime.label}</TableCell>
							<TableCell>{totalRow.averageBuySideTransactionTime.label}</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<Box pt='10px'>
				<LoadingButton variant='contained' onClick={handleDownload} loading={isLoading}>
					Download CSV
					<Iconify icon='mdi:file' fontSize={20} ml='5px' />
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default SolicitorsTable;
