import Page from 'src/components/Page';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { dispatch } from 'src/redux/store';
import { selectUser, updateUser, updateUserOnboarding } from 'src/redux/slices/auth';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import useFirms from 'src/hooks/useFirms';
import { getBranchRef } from 'src/utils/firebase';
import { useNavigate } from 'react-router';
import { AuthPage, Autocomplete, CompanyBrandCard, ContinueButton } from '../styles';
import { OnboardingCompanyFormType } from './types';
import { onboardingCompanyValidationSchema } from './validation';
import OnboardingFooter from '../OnboardingFooter';

const OnboardingBranch = () => {
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	const { areBranchesLoading, firmBranches, fetchBranches } = useFirms();
	const form = useForm<OnboardingCompanyFormType>({
		resolver: yupResolver(onboardingCompanyValidationSchema),
		defaultValues: { branch: user.branch?.id },
	});

	const handleSubmit = async (data: OnboardingCompanyFormType) => {
		if (!user.business) return;

		await Promise.all([
			dispatch(updateUserOnboarding({ branch: true, info: false })),
			dispatch(updateUser({ branch: getBranchRef(user.business.id, data.branch) })),
		]);
	};

	useEffect(() => {
		if (user.business) fetchBranches(user.business.id);
	}, [user.business]);

	const handleCantFindBranch = async () => {
		await dispatch(updateUserOnboarding({ branch: true, newBranch: false }));
		navigate('/onboarding/new-branch');
	};

	return (
		<FormProvider {...form}>
			<Page title='Login'>
				<AuthPage>
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<CompanyBrandCard title='I work at...'>
							<Autocomplete
								name='branch'
								label='Branch'
								options={firmBranches}
								loading={areBranchesLoading}
								sx={{ mt: '5px' }}
								labelAttribute='name'
							/>
							<ContinueButton variant='contained' fullWidth type='submit' loading={form.formState.isSubmitting}>
								Continue
							</ContinueButton>
							<OnboardingFooter withBranchNotFound handleBranchNotFoundClick={handleCantFindBranch} />
						</CompanyBrandCard>
					</form>
				</AuthPage>
			</Page>
		</FormProvider>
	);
};

export default OnboardingBranch;
