import { Box, Typography } from '@mui/material';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import useLoading from 'src/hooks/useLoading';
import { useSelector } from 'react-redux';
import { openModal, selectModal } from 'src/redux/slices/modal';
import { noop } from 'lodash';
import { askForCompanyHelpThunk } from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import { updateUser } from 'src/redux/slices/auth';
import { ModalErrorAlert } from 'src/sections/property/components/styled';

const Content = () => {
	const { payload } = useSelector(selectModal<{ companyName: string; companyId: string }>);
	const { enqueueSnackbar } = useSnackbar();
	const { isLoading, setIsLoading } = useLoading();

	const handleAskForHelp = async () => {
		try {
			setIsLoading(true);

			await dispatch(askForCompanyHelpThunk({ companyId: payload.companyId })).unwrap();

			await dispatch(updateUser({ isHelpRequested: true }));

			dispatch(openModal({ name: modals.helpRequested }));
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<Typography sx={styledMainTitle} pt={0}>
				RESTRICTED COMPANY
			</Typography>
			<ModalErrorAlert severity='error'>
				{payload.companyName} has not been approved for use on Conveyo. Please contact support for further assistance.
			</ModalErrorAlert>
			<Box width='100%' display='flex' justifyContent='flex-end'>
				<LoadingButton variant='contained' loading={isLoading} onClick={handleAskForHelp}>
					Ask for help
				</LoadingButton>
			</Box>
		</Box>
	);
};

const RejectedCompanyModal = () => (
	<Modal name={modals.rejectedCompany} cardSx={{ overflow: 'visible', maxWidth: '550px' }} onClose={noop}>
		<Content />
	</Modal>
);

export default RejectedCompanyModal;
