import { Box, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { logout as logoutApi } from 'src/api';
import { dispatch } from 'src/redux/store';
import { goBackOnUserOnboarding, logout, selectUser } from 'src/redux/slices/auth';
import { useSelector } from 'react-redux';
import useLoading from 'src/hooks/useLoading';
import { FC } from 'react';
import { GoBackButton, GoBackButtonProgress } from './styled';
import { OnboardingFooterProps } from './types';

const OnboardingFooter: FC<OnboardingFooterProps> = ({
	withFirmNotFound,
	withBranchNotFound,
	handleFirmNotFoundClick,
	handleBranchNotFoundClick,
}) => {
	const { isLoading, setIsLoading } = useLoading();
	const user = useSelector(selectUser);
	const location = useLocation();

	const showBackButton =
		location.pathname !== '/onboarding/verification' && location.pathname !== '/onboarding/select-role';

	const handleLogout = () => {
		logoutApi();
		dispatch(logout());
	};

	const handleGoBack = async () => {
		// everything else is handled by OnboardingGuard
		setIsLoading(true);
		await dispatch(goBackOnUserOnboarding());
		setIsLoading(false);
	};

	return (
		<>
			<Box display='flex' justifyContent='space-around' gap='10px' alignItems='center' color='grey.700' mt='10px'>
				{withFirmNotFound && (
					<>
						<GoBackButton disableRipple onClick={handleFirmNotFoundClick}>
							Can't find your firm?
						</GoBackButton>
						•
					</>
				)}
				{withBranchNotFound && (
					<>
						<GoBackButton disableRipple onClick={handleBranchNotFoundClick}>
							Can't find your branch?
						</GoBackButton>
						•
					</>
				)}
				{showBackButton && (
					<GoBackButton disabled={isLoading} disableRipple onClick={handleGoBack}>
						Go back{isLoading && <GoBackButtonProgress size='11px' />}
					</GoBackButton>
				)}
			</Box>
			<Typography variant='caption' display='flex' gap='10px'>
				<b>{user.email}</b>
				<Typography
					component={Link}
					to='/auth/login'
					variant='caption'
					color='info.dark'
					sx={{ textDecoration: 'none' }}
					onClick={handleLogout}
				>
					Logout
				</Typography>
			</Typography>
		</>
	);
};

export default OnboardingFooter;
