import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PhotoDashedWrapper = styled(Box)(({ theme }) => ({
	width: '250px',
	height: '250px',
	borderRadius: '50%',
	border: `1px dashed ${theme.palette.custom.bidyText}`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	cursor: 'pointer',
}));

export const PhotoWrapper = styled(Box)<{ hasPhoto: boolean }>(({ hasPhoto }) => ({
	position: 'relative',
	width: '220px',
	overflow: 'hidden',
	height: '220px',
	borderRadius: '50%',
	backgroundColor: '#E7E7E7',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	...(hasPhoto && { '&:hover > *:last-child': { opacity: 1 } }),
}));

export const LabelWithPhoto = styled(Box)(() => ({
	transition: 'opacity .3s ease-in-out',
	backgroundColor: 'rgba(33, 43, 54, 0.6)',
	position: 'absolute',
	top: '0',
	left: '0',
	zIndex: 2,
	width: '100%',
	height: '100%',
	opacity: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
}));

export const TypoUpload = styled(Typography)<{ textColor: string }>(({ textColor }) => ({
	fontFamily: "'Roboto', sans-serif",
	color: textColor,
	textAlign: 'center',
	marginTop: '15px',
}));

export const CommonTypo = styled(Typography)<{ hasError?: boolean }>(({ theme, hasError }) => ({
	fontFamily: "'Roboto', sans-serif",
	color: hasError ? '#E05252' : theme.palette.custom.bidyText,
	textAlign: 'center',
}));
