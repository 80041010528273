import { call } from '../@';
import { AddUserBankAccountDto } from './add-user-bank-account';

export type UpdateUserBankAccountDto = { id: string } & AddUserBankAccountDto;

export type UpdateUserBankAccountResponse = {
	id: string;
};

export default async function updateUserBankAccount(dto: UpdateUserBankAccountDto) {
	return await call('bankAccounts-update', dto);
}
