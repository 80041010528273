import Iconify from 'src/components/Iconify';
import { useDispatch } from 'src/redux/store';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import IconWithLoading from 'src/components/IconWithLoading';
import { selectMenuLoading, setLoading, unsetLoading } from 'src/redux/slices/headerSlice';
import { useSnackbar } from 'notistack';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { getProperty, selectProperty, updatePropertyOnboarding } from 'src/redux/slices/property';
import { syncPropertyOnboarding } from 'src/api/property-onboarding';
import PropertyAlreadyOnMarket from 'src/components/modals/property/PropertyAlreadyOnMarket';
import checkIsPropertyOnSale from 'src/api/property/check-is-not-on-sale';
import { ActionButtonItem, ActionButtons } from '../styles';

const PropertyActionButtons = () => {
	const { id = '' } = useParams();
	const dispatch = useDispatch();
	const isLoading = useSelector(selectMenuLoading);
	const property = useSelector(selectProperty);
	const { enqueueSnackbar } = useSnackbar();

	const handleAction = (action: 'sell' | 'buy' | 'delete' | 'remortgage') => async () => {
		try {
			dispatch(setLoading());

			if (action !== 'delete') {
				const onSale = await checkIsPropertyOnSale(property.uprn);

				if (onSale) {
					dispatch(openModal({ name: modals.propertyAlreadyOnMarket }));

					return;
				}
			}

			switch (action) {
				case 'sell': {
					if (!property.onboarding || !property.onboarding.activeSide) {
						await syncPropertyOnboarding(property.id, 'sell');
					}
					if (property.onboarding?.activeSide && property.onboarding?.activeSide !== 'sell') {
						dispatch(openModal({ name: modals.onboardingSaleConfirmation }));

						return;
					}

					await dispatch(getProperty({ id: property.id }));
					await dispatch(updatePropertyOnboarding({ activeSide: 'sell', moveToNextModal: true }));

					break;
				}
				case 'buy': {
					if (!property.onboarding?.activeSide || !property.onboarding.activeSide) {
						await syncPropertyOnboarding(property.id, 'buy');
					}

					if (property.onboarding?.activeSide && property.onboarding?.activeSide !== 'buy') {
						dispatch(openModal({ name: modals.onboardingPurchaseConfirmation }));

						return;
					}

					await dispatch(getProperty({ id: property.id }));
					await dispatch(updatePropertyOnboarding({ activeSide: 'buy', moveToNextModal: true }));

					break;
				}
				case 'remortgage': {
					if (!property.onboarding || !property.onboarding.activeSide) {
						await syncPropertyOnboarding(property.id, 'remortgage');
					}

					if (property.onboarding?.activeSide && property.onboarding?.activeSide !== 'remortgage') {
						dispatch(openModal({ name: modals.onboardingRemortgageConfirmation }));

						return;
					}

					await dispatch(getProperty({ id: property.id }));
					await dispatch(updatePropertyOnboarding({ activeSide: 'remortgage', moveToNextModal: true }));

					break;
				}
				case 'delete': {
					dispatch(openModal({ name: modals.dropProperty, payload: { propertyId: id } }));

					break;
				}
				default: {
					break;
				}
			}
		} catch (e) {
			if (e instanceof Error) {
				enqueueSnackbar(e.message, { variant: 'error' });
			}
		} finally {
			dispatch(unsetLoading());
		}
	};

	return (
		<ActionButtons>
			<ActionButtonItem onClick={handleAction('sell')} disabled={isLoading}>
				<IconWithLoading isLoading={isLoading}>
					<Iconify icon='material-symbols:sell' fontSize={18} />
				</IconWithLoading>
				Sell
			</ActionButtonItem>
			<ActionButtonItem onClick={handleAction('buy')} disabled={isLoading}>
				<IconWithLoading isLoading={isLoading}>
					<Iconify icon='icons8:buy' fontSize={20} />
				</IconWithLoading>
				Buy
			</ActionButtonItem>
			{/* <ActionButtonItem onClick={handleAction('remortgage')} disabled={isLoading}>
				<IconWithLoading isLoading={isLoading}>
					<Iconify icon='material-symbols:wifi-home-outline' fontSize={20} />
				</IconWithLoading>
				Remortgage
			</ActionButtonItem> */}
			{!property?.isProprietor && (
				<ActionButtonItem onClick={handleAction('delete')} disabled={isLoading}>
					<IconWithLoading isLoading={isLoading}>
						<Iconify icon='material-symbols:delete' fontSize={18} />
					</IconWithLoading>
					Delete
				</ActionButtonItem>
			)}
			<PropertyAlreadyOnMarket />
		</ActionButtons>
	);
};

export default PropertyActionButtons;
