export const userTitles = {
	mr: {
		key: 'mr',
		name: 'Mr',
	},
	mrs: {
		key: 'mrs',
		name: 'Mrs',
	},
	miss: {
		key: 'miss',
		name: 'Miss',
	},
	ms: {
		key: 'ms',
		name: 'Ms',
	},
	mx: {
		key: 'mx',
		name: 'Mx',
	},
	dr: {
		key: 'dr',
		name: 'Dr',
	},
	prof: {
		key: 'prof',
		name: 'Prof',
	},
	rev: {
		key: 'rev',
		name: 'Rev',
	},
	hon: {
		key: 'hon',
		name: 'Hon',
	},
	sir: {
		key: 'sir',
		name: 'Sir',
	},
	madam: {
		key: 'madam',
		name: 'Madam',
	},
	esq: {
		key: 'esq',
		name: 'Esq',
	},
	ind: {
		key: 'ind',
		name: 'Ind',
	},
	m: {
		key: 'm',
		name: 'M',
	},
	mre: {
		key: 'mre',
		name: 'Mre',
	},
	msr: {
		key: 'msr',
		name: 'Msr',
	},
	myr: {
		key: 'myr',
		name: 'Myr',
	},
	pr: {
		key: 'pr',
		name: 'Pr',
	},
	sai: {
		key: 'sai',
		name: 'Sai',
	},
	ser: {
		key: 'ser',
		name: 'Ser',
	},
	misc: {
		key: 'misc',
		name: 'Misc',
	},
} as const;

export const userTitlesOptions = Object.values(userTitles);
export const userTitlesKeys = userTitlesOptions.map(({ key }) => key);
