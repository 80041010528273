import { getBranchAddressValidationSchema } from 'src/pages/dashboard/components/Companies/components/UpdateCompanyBranchModal/validation';
import { requiredString, urlString } from 'src/utils/schemas';
import * as Yup from 'yup';

export const onboardingNewBranchValidationSchema = Yup.object().shape({
	name: requiredString,
	address: getBranchAddressValidationSchema(),
	telephone: requiredString,
	email: requiredString.email('Email must be valid email address'),
	website: urlString,
});
