import { CompanyStatus } from 'src/redux/types';
import { call } from '../@';
import { SuccessResponse } from '../@/api-methods';

export type UpdateCompanyDto = {
	companyId: string;
	tradingName: string;
	status: CompanyStatus;
};

export type UpdateCompanyResponse = SuccessResponse;

export default async function updateCompany(dto: UpdateCompanyDto) {
	return await call('company-update', dto);
}
