import { getDocs, query, where } from 'firebase/firestore';
import { transactionOnboardingsCollectionGroup } from 'src/constants/firestore';
import { getUserRef } from './firebase';

export const findAllTransactionOnboardingSnapshots = async (userId: string) => {
	const userRef = getUserRef(userId);

	const { docs } = await getDocs(query(transactionOnboardingsCollectionGroup, where('userRef', '==', userRef)));

	return docs;
};
