import { FormProvider, useForm } from 'react-hook-form';
import { Button, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { RHFAutocomplete } from 'src/components/hook-form';
import { transactionDocumentsOptions } from 'src/pages/transactions/constants';
import { useState } from 'react';
import generateDocument from 'src/api/transaction/generate-document';
import { useSelector } from 'react-redux';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { useSnackbar } from 'notistack';
import { ModalErrorAlert } from 'src/sections/property/components/styled';
import { LoadingButton } from '@mui/lab';
import { GenerateDocumentForm } from './types';
import { validationSchema } from './validation';

const Content = () => {
	const { enqueueSnackbar } = useSnackbar();
	const transaction = useSelector(selectTransactionOverview);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const form = useForm<GenerateDocumentForm>({
		resolver: yupResolver(validationSchema),
	});

	const handleSubmit = async (data: GenerateDocumentForm) => {
		try {
			setError(null);
			setIsLoading(true);

			// if (data.form === 'fix') {
			// 	const response = await testPayment();

			// 	console.log('response', response);
			// } else {
			const { message } = await generateDocument(data.form, transaction.id);

			enqueueSnackbar(message);
			// }

			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) {
				setError(e.message);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<FormProvider {...form}>
			<form
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(handleSubmit)(e);
				}}
			>
				<Typography sx={styledMainTitle} pt={0}>
					GENERATE DOCUMENT
				</Typography>
				{!!error && (
					<ModalErrorAlert variant='filled' severity='error'>
						{error}
					</ModalErrorAlert>
				)}
				<Typography fontSize='17px' fontWeight={500} mb={'15px'}>
					Select document to be generated
				</Typography>
				<RHFAutocomplete
					name='form'
					label='Select a document'
					options={transactionDocumentsOptions}
					valueAttribute='key'
				/>
				<ActionButtons mt={'20px'}>
					<Button variant='contained' size='large' fullWidth onClick={handleCloseModal}>
						Cancel
					</Button>
					<LoadingButton variant='contained' size='large' fullWidth type='submit' loading={isLoading}>
						Generate
					</LoadingButton>
				</ActionButtons>
			</form>
		</FormProvider>
	);
};

const GenerateDocumentModal = () => (
	<Modal name={modals.generateDocument} cardSx={{ overflow: 'visible', maxWidth: '550px' }}>
		<Content />
	</Modal>
);

export default GenerateDocumentModal;
