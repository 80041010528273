import { CompanyBranchStatus } from 'src/redux/types';
import { call } from '../@';

export type UpdateCompanyBranchDto = {
	id: string;
	companyId: string;
	name: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	address: any;
	telephone: string;
	emails: string[];
	website: string;
	status: CompanyBranchStatus;
};

export default async function updateCompanyBranch(dto: UpdateCompanyBranchDto) {
	return await call('company-branch-update', dto);
}
