import modals from 'src/constants/modals';
import Modal from 'src/components/customModal';
import { noop } from 'lodash';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectModal } from 'src/redux/slices/modal';

const CompanyUnderReviewModal = () => {
	const { payload } = useSelector(selectModal<{ companyName: string }>);

	return (
		<Modal name={modals.companyUnderReview} cardSx={{ overflow: 'visible', maxWidth: '600px' }} onClose={noop}>
			<Typography sx={styledMainTitle} pt={0}>
				ALMOST THERE!
			</Typography>
			<Typography pt={0} fontWeight={500} mb='15px'>
				Your company, {payload.companyName}, is currently undergoing review. This can take up to a few hours. Once
				approved, you'll be able to create or join transactions on Conveyo.
			</Typography>
		</Modal>
	);
};

export default CompanyUnderReviewModal;
