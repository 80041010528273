import { Box, Table, TableBody, TableHead, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Progress from 'src/components/Progress';
import { TableCell, TableRow } from 'src/pages/dashboard/styles';
import { getProgressReportsThunk, selectProgressReports, sortProgressReports } from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import { Link } from 'react-router-dom';
import HeadCell from 'src/components/HeadCell';
import ProgressStatus from '../ProgressStatus';
import { cellBorderLeft } from './constants';

const ProgressTable = () => {
	const reports = useSelector(selectProgressReports);

	useEffect(() => {
		dispatch(getProgressReportsThunk());
	}, []);

	if (reports.isLoading) return <Progress />;
	if (reports.error || !reports.data) {
		return (
			<Box p='15px'>
				<Typography color='red' variant='h4'>
					{reports.error?.message ?? 'Something went wrong'}
				</Typography>
			</Box>
		);
	}

	const handleSortChange = ({ order, orderBy }) => dispatch(sortProgressReports({ order, orderBy }));

	return (
		<Box>
			<Table>
				<TableHead>
					<HeadCell
						name='Transaction name'
						minWidth={350}
						columnKey='name'
						handleSortChange={handleSortChange}
						order={reports.order}
						orderBy={reports.orderBy}
					/>
					<HeadCell
						name='Handler assigned'
						columnKey='handler'
						handleSortChange={handleSortChange}
						order={reports.order}
						orderBy={reports.orderBy}
					/>
					<HeadCell name='Sellers added' sortable={false} sx={cellBorderLeft} />
					<HeadCell name='Solicitors added' sortable={false} />
					<HeadCell name='Solicitors onboarded' sortable={false} />
					<HeadCell name='Sellers onboarded' sortable={false} />
					<HeadCell name='Buyers added' sortable={false} sx={cellBorderLeft} />
					<HeadCell name='Solicitors added' sortable={false} />
					<HeadCell name='Solicitors onboarded' sortable={false} />
					<HeadCell name='Buyers onboarded' sortable={false} />
					<HeadCell name='MOS available' sortable={false} sx={cellBorderLeft} />
					<HeadCell name='Seller forms available' sortable={false} />
					<HeadCell name='Contract pack available' sortable={false} />
					<HeadCell name='Searches ordered' sortable={false} />
					<HeadCell name='Searches uploaded' sortable={false} />
					<HeadCell name='Enquiries started' sortable={false} />
					<HeadCell name='Enquiries completed' sortable={false} />
					<HeadCell name='Exchange date agreed' sortable={false} />
					<HeadCell name='Exchanged' sortable={false} />
				</TableHead>
				<TableBody>
					{reports.data?.map((row) => (
						<TableRow key={row.id.label}>
							<TableCell>
								<Link to={`/transactions/${row.id.value}`}>
									<b>{row.name.label}</b>
								</Link>
							</TableCell>
							<TableCell>{row.handler.label || <ProgressStatus status='pending' />}</TableCell>
							<TableCell sx={cellBorderLeft}>
								<ProgressStatus status={row.sellersAdded.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.sellSolicitorsAdded.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.sellSolicitorsOnboarded.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.sellersOnboarded.value} />
							</TableCell>
							<TableCell sx={cellBorderLeft}>
								<ProgressStatus status={row.buyersAdded.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.buySolicitorsAdded.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.buySolicitorsOnboarded.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.buyersOnboarded.value} />
							</TableCell>
							<TableCell sx={cellBorderLeft}>
								<ProgressStatus status={row.memorandumOfSaleAvailable.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.sellerFormsAvailable.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.contractPackAvailable.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.searchesOrdered.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.searchesUploaded.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.enquiriesStarted.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.enquiriesCompleted.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.exchangeDateAgreed.value} />
							</TableCell>
							<TableCell>
								<ProgressStatus status={row.exchanged.value} />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Box>
	);
};

export default ProgressTable;
