import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Stack, Card, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFOtpInput, RHFTextField } from 'src/components/hook-form';
import { dispatch } from 'src/redux/store';
import {
	cancelChangeEmailRequestThunk,
	changeEmailRequestThunk,
	resendChangeEmailRequestVerificationThunk,
	selectUser,
	verifyChangeEmailRequestThunk,
} from 'src/redux/slices/auth';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import useLoading from 'src/hooks/useLoading';
import { useSelector } from 'react-redux';
import { UserChangeEmailRequestStatusEnum } from 'src/types';
import { changeEmailRequestValidationSchema } from './validation';

export default function AccountChangeEmail() {
	const { enqueueSnackbar } = useSnackbar();
	const user = useSelector(selectUser);
	const [requestId, setRequestId] = useState<string | null>(null);
	const { isLoading, setIsLoading } = useLoading();
	const methods = useForm({
		defaultValues: { email: '', code: '', step: 0 },
		resolver: yupResolver(changeEmailRequestValidationSchema),
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
		setValue,
		watch,
		reset,
	} = methods;

	const [step, newEmail] = watch(['step', 'email']);

	const handleSendChangeEmailRequest = async ({ email }) => {
		try {
			const { id } = await dispatch(changeEmailRequestThunk({ email })).unwrap();

			setRequestId(id);
			setValue('step', 1);

			enqueueSnackbar('Confirmation code sent!');
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		}
	};

	const handleVerifyChangeEmailRequest = async ({ code }) => {
		try {
			if (!requestId) return;

			await dispatch(verifyChangeEmailRequestThunk({ requestId, code })).unwrap();
			setValue('step', 2);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		}
	};

	const handleResend = async () => {
		try {
			if (!requestId) return;

			setIsLoading(true);

			await dispatch(resendChangeEmailRequestVerificationThunk({ requestId })).unwrap();

			enqueueSnackbar('Confirmation code resent!');
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const handleCancel = async () => {
		try {
			if (!requestId) return;

			setIsLoading(true);

			await dispatch(cancelChangeEmailRequestThunk({ requestId })).unwrap();

			reset();

			enqueueSnackbar('Email change request canceled!');
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (!user.changeEmailRequest) return;

		setRequestId(user.changeEmailRequest.id);

		if (
			user.changeEmailRequest.status === UserChangeEmailRequestStatusEnum.ARCHIVED ||
			user.changeEmailRequest.status === UserChangeEmailRequestStatusEnum.REJECTED ||
			user.changeEmailRequest.status === UserChangeEmailRequestStatusEnum.CONFIRMED
		) {
			return;
		}

		setValue('email', user.changeEmailRequest.email);
		setValue(
			'step',
			user.changeEmailRequest.status === UserChangeEmailRequestStatusEnum.PENDING
				? 1
				: user.changeEmailRequest.status === UserChangeEmailRequestStatusEnum.ACTIVE
				? 3
				: 2,
		);
	}, [user.changeEmailRequest]);

	return (
		<Card sx={{ p: 3 }}>
			<FormProvider
				methods={methods}
				onSubmit={handleSubmit(step === 0 ? handleSendChangeEmailRequest : handleVerifyChangeEmailRequest)}
			>
				<Stack spacing={3} alignItems='center'>
					{step === 0 ? (
						<>
							<Typography variant='h5'>Request email address change</Typography>
							<RHFTextField name='email' label='Enter new email address' sx={{ width: '400px' }} />

							<LoadingButton type='submit' variant='contained' loading={isSubmitting || isLoading}>
								Submit
							</LoadingButton>
						</>
					) : step === 1 ? (
						<>
							<Typography variant='h5'>Enter confirmation code</Typography>
							<Typography>
								A confirmation code has been sent to <b>{newEmail}</b>. Please check your inbox.
							</Typography>
							<RHFOtpInput name='code' />

							<LoadingButton type='submit' variant='contained' loading={isSubmitting || isLoading}>
								Submit
							</LoadingButton>
							<Box display='flex' gap='15px' mt='15px'>
								<LoadingButton variant='outlined' loading={isSubmitting || isLoading} onClick={handleResend}>
									Resend
								</LoadingButton>
								<LoadingButton variant='outlined' loading={isSubmitting || isLoading} onClick={handleCancel}>
									Cancel Request
								</LoadingButton>
							</Box>
						</>
					) : (
						<Box width='100%' display='flex' flexDirection='column' gap='15px' alignItems='center'>
							<Iconify icon='icon-park-solid:success' fontSize={100} color='green' />
							<Typography textAlign='center'>
								Your request to change your email to <b>{newEmail}</b> has been submitted! <br /> A notification has
								been sent to our team for review. You will receive an email once the review is complete.
							</Typography>
							<LoadingButton variant='outlined' loading={isSubmitting || isLoading} onClick={handleCancel}>
								Cancel Request
							</LoadingButton>
						</Box>
					)}
				</Stack>
			</FormProvider>
		</Card>
	);
}
