import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { buyerQuestions } from 'src/constants/surveyJson/buyersQuestions';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import {
	selectTransactionFinance,
	selectTransactionOverview,
	selectTransactionParticipants,
} from 'src/redux/slices/transaction';
import { useEffect, useMemo, useState } from 'react';
import { QuestionnaireSubmittingError } from 'src/components/QuestionnarieModal/types';
import { formatBidValue } from 'src/utils/formatBid';
import { selectUser } from 'src/redux/slices/auth';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { useQuestionnaire } from '../useQuestionnaire';
import { FinancingAttributesEnum, FinancingSourcesEnum } from './types';
import { DescriptionBlock } from '../styles';

const QuestionnaireBuyerCard = () => {
	const transaction = useSelector(selectTransactionOverview);
	const user = useSelector(selectUser);
	const { fullQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const participants = useSelector(selectTransactionParticipants);
	const finance = useSelector(selectTransactionFinance);
	const [trigger, setTrigger] = useState(false);
	const { onSubmit, onPageChanged, onComplete, isHidden, isNotAllowed, notAllowedMessage } = useQuestionnaire({
		type: QuestionnaireTransactionEnum.buyer_questions,
		questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
	});

	const buyersQuestionnaire = fullQuestionnaire?.buyer_questions;

	const submittingError: QuestionnaireSubmittingError | null = useMemo(() => {
		const hasGiftSource = buyersQuestionnaire.funds?.sources?.includes('gift');

		if (hasGiftSource) {
			const giftors = participants.participants.buyers.filter((b) => b.relationship === 'giftor');

			const areGiftsAdded = giftors.every((g) =>
				buyersQuestionnaire.funds?.gift?.some((gift) => gift.giftors.includes(g.uid)),
			);

			if (!giftors.length || !areGiftsAdded) {
				return {
					title: 'MISSING GIFTORS',
					children:
						"You can't submit this questionnaire until all giftors are added. You can save your answers by clicking Continue and return to complete it later. Remember to navigate back to the first page to add any giftors.",
					handleContinueClick: ({ handleClose }) => handleClose(),
				};
			}
		}

		if (transaction.askPrice) {
			const totalEnteredFunds = [
				buyersQuestionnaire.funds?.mortgage_amount,
				buyersQuestionnaire.funds?.compensation_amount,
				buyersQuestionnaire.funds?.equity_release_amount,
				buyersQuestionnaire.funds?.gambling_amount,
				buyersQuestionnaire.funds?.inheritance_amount,
				buyersQuestionnaire.funds?.pension_amount,
				buyersQuestionnaire.funds?.property_sale_amount,
				buyersQuestionnaire.funds?.remortgage_amount,
				buyersQuestionnaire.funds?.savings_amount,
				...(buyersQuestionnaire.funds?.gift?.map((g) => g.amount) ?? []),
			].reduce((acc, amount) => {
				if (!amount) return acc;
				// eslint-disable-next-line no-param-reassign
				acc += +amount;

				return acc;
			}, 0);

			if (totalEnteredFunds > transaction.askPrice.price) {
				return {
					title: 'FUNDS EXCEED AGREED PRICE',
					children: (
						<>
							The total declared funds (<b>£{formatBidValue(totalEnteredFunds)}</b>) exceed the agreed price (
							<b>£{formatBidValue(transaction.askPrice.price)}</b>) by{' '}
							<b>£{formatBidValue(totalEnteredFunds - transaction.askPrice.price)}</b>. Is this expected?
							<br />
							<br />
							Please review the amounts or confirm.
						</>
					),
					handleCancelClick: ({ handleClose }) => handleClose(),
					handleContinueClick: ({ handleConfirm }) => handleConfirm(),
				};
			}

			if (totalEnteredFunds < transaction.askPrice.price) {
				return {
					title: 'INSUFFICIENT FUNDS',
					children: (
						<>
							The total declared funds (<b>£{formatBidValue(totalEnteredFunds)}</b>) fall short of the agreed price (
							<b>£{formatBidValue(transaction.askPrice.price)}</b>) by{' '}
							<b>£{formatBidValue(transaction.askPrice.price - totalEnteredFunds)}</b>. <br />
							<br />
							Please review and adjust the amounts.
						</>
					),
					handleContinueClick: ({ setCurrentPage, setIsSubmitting }) => {
						setCurrentPage(1);
						setIsSubmitting(false);
					},
				};
			}
		}

		return null;
	}, [participants.participants, buyersQuestionnaire, transaction.askPrice]);

	useEffect(() => {
		setTrigger((t) => !t);
	}, [fullQuestionnaire?.buyer_questions?.finance?.bank_accounts]);

	if (!fullQuestionnaire || isHidden || !finance.data) return null;

	const completedBuyers = fillQuestionnaireJsonWithCompletedData(
		buyerQuestions(participants.participants, participants.placeholders, user, finance.data),
		buyersQuestionnaire,
	);

	return (
		<>
			<QuestionnarieModal
				initialCurrentPage={buyersQuestionnaire.currentPage}
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				questionnaireKey={QuestionnaireTransactionEnum.buyer_questions}
				surveyJson={completedBuyers}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				status={buyersQuestionnaire.status}
				title='BUYER FINANCE'
				submittingError={submittingError}
				trigger={trigger}
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				title='Buyer Finance'
				type={QuestionnaireTransactionEnum.buyer_questions}
				notAllowed={isNotAllowed}
				notAllowedMessage={notAllowedMessage}
			>
				{({ details }) => {
					const bankAccountsAmount = details?.finance?.bank_accounts?.length;
					const validFinancingAttributesEntries = Object.values(details?.funds?.attributes || {}).filter(
						(attribute) => attribute === 'Yes',
					);

					return (
						<>
							{!!validFinancingAttributesEntries.length && (
								<DescriptionBlock>
									Financing:{' '}
									<b>
										{Object.entries(details?.funds?.attributes || {}).reduce(
											(acc, curr) =>
												curr[1] === 'Yes' ? `${acc}${acc ? ', ' : ''}${FinancingAttributesEnum[curr[0]]}` : acc,
											'',
										)}
									</b>
								</DescriptionBlock>
							)}
							{!!details?.funds?.sources?.length && (
								<DescriptionBlock>
									Source of funds: <b>{details.funds?.sources.map((s) => FinancingSourcesEnum[s]).join(', ')}</b>
								</DescriptionBlock>
							)}
							{details?.funds?.giftors &&
								(details?.funds?.giftors || []).some(({ giftor: { given_names: fn } }) => !!fn) && (
									<DescriptionBlock>
										Giftors:{' '}
										<b>
											{details.funds?.giftors.reduce(
												(acc, { giftor: { given_names: gn, last_names: ln } }) =>
													gn ? `${acc}${acc ? ', ' : ''}${gn} ${ln}` : acc,
												'',
											)}
										</b>
									</DescriptionBlock>
								)}
							{!!bankAccountsAmount && (
								<DescriptionBlock pt='4px'>
									{bankAccountsAmount} bank account{bankAccountsAmount >= 2 && 's'} provided
								</DescriptionBlock>
							)}
						</>
					);
				}}
			</QuestionnaireCard>
		</>
	);
};

export default QuestionnaireBuyerCard;
