import { TablePagination, tablePaginationClasses } from '@mui/material';
import { styled } from '@mui/system';

const blue = {
	200: '#A5D8FF',
	400: '#3399FF',
};

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
};

export const CustomTablePagination = styled(TablePagination)(
	({ theme }) => `
	& .${tablePaginationClasses.spacer} {
	  display: none;
	}
  
	& .${tablePaginationClasses.select} {
	  padding: 2px 0 2px 4px;
	  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
	  border-radius: 6px; 
	  background-color: transparent;
	  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
	  transition: all 100ms ease;
  
	  &:hover {
		background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
		border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
	  }
  
	  &:focus {
		outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
		border-color: ${blue[400]};
	  }
	}
  
	& .${tablePaginationClasses.displayedRows} {
	  margin: 0;
  
	  @media (min-width: 768px) {
		margin-left: auto;
	  }
	}
`,
);
