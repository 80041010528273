import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'src/components';
import { ConfirmationModal } from 'src/components/modals';
import modals from 'src/constants/modals';
import useLoading from 'src/hooks/useLoading';
import { selectModal } from 'src/redux/slices/modal';
import { dispatch, useSelector } from 'src/redux/store';
import { IDashboardUser } from 'src/redux/types';
import { OnboardingStepStatusEnum, VerificationSessionStatus } from 'src/types';
import { getUserRef } from 'src/utils/firebase';
import { findAllTransactionOnboardingSnapshots } from 'src/utils/onboarding';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { getNameVerificationDocument } from 'src/utils/verification';
import { useEffect } from 'react';
import { getUsersThunk } from 'src/redux/slices/tableSlice';
import verificationUpdateNotification from 'src/api/user/verification-update-notification';
import { findAllPropertyOnboardings } from 'src/utils/firebase/property-onboarding';
import { UserNameConfirmationErrorForm } from './types';
import { userNameConfirmationErrorValidationSchema } from './validation';

const Content = () => {
	const { payload: user } = useSelector(selectModal<IDashboardUser>);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const methods = useForm<UserNameConfirmationErrorForm>({
		resolver: yupResolver(userNameConfirmationErrorValidationSchema),
	});

	const document = user.verificationSession ? getNameVerificationDocument(user.verificationSession) : null;

	useEffect(() => {
		methods.reset({
			givenNames: user.verificationSession?.requestedGivenNames ?? document?.given_names,
			lastNames: user.verificationSession?.requestedFamilyNames ?? document?.family_name,
		});
	}, [user.verificationSession, document]);

	if (!user.verificationSession || !document) return <>Something went wrong</>;

	const userRef = getUserRef(user.uid);

	const handleCancel = () => handleCloseModal();

	const handleSubmit = async (data: UserNameConfirmationErrorForm) => {
		try {
			setIsLoading(true);

			const [propertyOnboardings, transactionOnboardings] = await Promise.all([
				findAllPropertyOnboardings(user.uid),
				findAllTransactionOnboardingSnapshots(user.uid),
			]);

			const areNameAndAddressMismatched = user.verificationStatus === VerificationSessionStatus.NAME_ADDRESS_MISMATCH;

			const update = {
				nameConfirmation: OnboardingStepStatusEnum.PASSED,
				nameIssue: OnboardingStepStatusEnum.PASSED,
				...(!areNameAndAddressMismatched && { verificationIssue: OnboardingStepStatusEnum.PASSED }),
			};

			const newVerificationStatus = areNameAndAddressMismatched
				? VerificationSessionStatus.ADDRESS_MISMATCH
				: VerificationSessionStatus.VERIFIED;

			await Promise.all([
				updateDoc(userRef, {
					isNameLocked: true,
					givenNames: data.givenNames,
					lastNames: data.lastNames,
					verificationStatus: newVerificationStatus,
				}),
				...propertyOnboardings.map((o) => updateDoc(o.ref, update)),
				...transactionOnboardings.map((o) => updateDoc(o.ref, update)),
			]);

			if (newVerificationStatus === VerificationSessionStatus.VERIFIED) {
				await verificationUpdateNotification(user.uid, 'nameError');
			}
			dispatch(getUsersThunk());

			handleCloseModal();
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSubmit)}>
			<Box display='flex' flexDirection='column' gap='15px'>
				<RHFTextField name='' label='Full Name (document)' value={document.full_name} disabled shrink />
				<Box display='flex' flexDirection='column' gap='10px'>
					<RHFTextField name='' label='Given Name(s) (document)' value={document.given_names} disabled shrink />
					<RHFTextField name='givenNames' label='Given Name(s)' placeholder='Please enter your given name(s)' />
				</Box>
				<Box display='flex' flexDirection='column' gap='10px'>
					<RHFTextField name='' label='Family Name(s) (document)' value={document.family_name} disabled shrink />
					<RHFTextField name='lastNames' label='Family Name(s)' placeholder='Please enter your family name(s)' />
				</Box>
			</Box>
			<br />
			<Box display='flex' gap='15px' justifyContent='flex-end' mt='15px'>
				<LoadingButton onClick={handleCancel} variant='outlined' size='large' loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton variant='contained' size='large' loading={isLoading} type='submit'>
					Submit
				</LoadingButton>
			</Box>
		</FormProvider>
	);
};

const UserNameConfirmationErrorModal = () => (
	<ConfirmationModal
		sx={{ maxWidth: '550px' }}
		modalName={modals.userNameConfirmationError}
		title='FIX NAME ERROR'
		withButtons={false}
		description={<Content />}
	/>
);

export default UserNameConfirmationErrorModal;
