import { FC } from 'react';
import { Company } from 'src/redux/types';
import { Button } from '@mui/material';
import { dispatch } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { companyStatus, labelByCompanyStatus } from '../../constants';

const CompanyStatusField: FC<Company> = (company) => {
	const { status } = company;

	if (status === companyStatus.pending) {
		return (
			<Button variant='contained' onClick={() => dispatch(openModal({ name: modals.verifyCompany, payload: company }))}>
				{labelByCompanyStatus[status]}
			</Button>
		);
	}

	return <>{labelByCompanyStatus[status]}</>;
};

export default CompanyStatusField;
