import { Box, Table as MuiTable, TableBody, TableHead, TableRow } from '@mui/material';
import HeadCell from 'src/components/HeadCell';
import Progress from 'src/components/Progress';
import { TableProps } from './types';
import { CustomTablePagination } from './styled';
import Row from './components/Row';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Table = <T extends Array<any>>({
	columns,
	data,
	handleSortChange,
	handleSettingsChange,
	rowKeyField,
	width,
	subRowsColumns,
	getSubRowsData,
	withPagination = true,
}: TableProps<T>) => {
	const handlePageChange = (_, page) => handleSettingsChange?.({ page: page + 1, limit: data.data.limit });
	const handleRowsPerPageChange = (e) => handleSettingsChange?.({ page: data.data.page, limit: e.target.value });

	return (
		<Box width='100%' height='100%' position='relative'>
			{!data.isInitialized && (
				<Box height='100%' width='100%' position='absolute' sx={{ background: '#FFFFFF80' }} zIndex={99}>
					<Progress />
				</Box>
			)}
			<MuiTable sx={{ width }}>
				<TableHead>
					<TableRow>
						{columns.map((c) => (
							<HeadCell
								key={c.key}
								name={c.name}
								columnKey={c.key}
								handleSortChange={handleSortChange}
								order={data.data.sortOrder}
								orderBy={data.data.orderBy}
								sortable={c.sortable}
								sx={{ width: c.width, minWidth: c.width }}
							/>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.data.data.map((row) => (
						<Row
							key={row[rowKeyField]}
							subRowsColumns={subRowsColumns}
							getSubRowsData={getSubRowsData}
							columns={columns}
							row={row}
						/>
					))}
				</TableBody>
			</MuiTable>
			{!!data.data.totalCount && withPagination && (
				<CustomTablePagination
					sx={{ minWidth: width }}
					rowsPerPageOptions={[10, 20, 50]}
					colSpan={3}
					count={data.data.totalCount}
					rowsPerPage={data.data.limit}
					page={data.data.page - 1}
					onPageChange={handlePageChange}
					onRowsPerPageChange={handleRowsPerPageChange}
				/>
			)}
		</Box>
	);
};

export default Table;
