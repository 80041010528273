import { Box } from '@mui/material';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { dispatch } from 'src/redux/store';
import { BrandModal } from 'src/components/common';
import { selectModal } from 'src/redux/slices/modal';
import useLoading from 'src/hooks/useLoading';
import { useSnackbar } from 'notistack';
import { Company } from 'src/redux/types';
import { approveCompanyThunk, getCompaniesThunk, rejectCompanyThunk } from 'src/redux/slices/tableSlice';
import Field from 'src/components/common/Field';
import moment from 'moment';

const Content = () => {
	const { payload } = useSelector(selectModal<Company>);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();

	const handleApprove = async () => {
		try {
			setIsLoading(true);

			await dispatch(approveCompanyThunk(payload.id)).unwrap();

			dispatch(getCompaniesThunk());
			enqueueSnackbar('Company approved!');
			handleCloseModal();
		} catch (e) {
			console.log('e', e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const handleReject = async () => {
		try {
			setIsLoading(true);

			await dispatch(rejectCompanyThunk(payload.id)).unwrap();

			dispatch(getCompaniesThunk());
			enqueueSnackbar('Company rejected!');
			handleCloseModal();
		} catch (e) {
			console.log('e', e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<BrandModal.Title title='VERIFY COMPANY' />
			<Box py='15px'>
				<Box display='flex' flexDirection='column' gap='10px'>
					<Field label='Legal Name' value={payload.legalName} />
					<Field label='Trading Name' value={payload.tradingName} />
					<Field label='Reg Num' value={payload.regNum} />
					<Field label='Type' value={payload.type} />
					<Field label='Created At' value={moment(payload.createdAt).format('YYYY-MM-DD')} />
				</Box>
				<ActionButtons mt='20px'>
					<LoadingButton variant='outlined' size='large' fullWidth onClick={handleReject} loading={isLoading}>
						Reject
					</LoadingButton>
					<LoadingButton variant='contained' size='large' fullWidth onClick={handleApprove} loading={isLoading}>
						Approve
					</LoadingButton>
				</ActionButtons>
			</Box>
		</>
	);
};

const VerifyCompanyModal = () => (
	<Modal name={modals.verifyCompany} cardSx={{ overflow: 'visible', maxWidth: '600px' }}>
		<Content />
	</Modal>
);

export default VerifyCompanyModal;
