import { Table, TableBody } from '@mui/material';
import { useSelector } from 'react-redux';
import ErrorView from 'src/components/ErrorView';
import LoadingView from 'src/components/LoadingView';
import { selectTransactionsSummaries } from 'src/redux/slices/tableSlice';
import { selectUserSettings } from 'src/redux/slices/auth';
import { TableCell, TableRow } from '../../../styles';
import PinCell from '../../PinCell';
import SummariesHead from '../Head';
import SummariesTotal from '../Total';

const SummariesTable = () => {
	const { list } = useSelector(selectTransactionsSummaries);
	const settings = useSelector(selectUserSettings);

	return (
		<ErrorView error={list.error}>
			<LoadingView isLoading={list.isLoading} pt='15px'>
				<Table>
					<SummariesHead />
					<TableBody>
						{list.data.map((summary) => {
							const isPinned = settings.pinnedTransactions.some(({ id }) => id === summary.transactionId);

							return (
								<TableRow key={summary.transactionId} isActive={isPinned} whiteSpace='nowrap'>
									<PinCell isPinned={isPinned} id={summary.transactionId} isTransaction />
									{settings.summariesColumns.map((column) => (
										<TableCell key={column}>{summary[column].label}</TableCell>
									))}
								</TableRow>
							);
						})}
						<SummariesTotal />
					</TableBody>
				</Table>
			</LoadingView>
		</ErrorView>
	);
};

export default SummariesTable;
