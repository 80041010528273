import { emailString, requiredString } from 'src/utils/schemas';
import * as Yup from 'yup';

export const changeEmailRequestValidationSchema = Yup.object().shape({
	email: emailString,
	step: Yup.number(),
	code: Yup.string().when('step', {
		is: 1,
		then: requiredString.length(6, 'Verification code must be 6 characters length'),
	}),
});
