import Iconify from 'src/components/Iconify';
import { useSelector } from 'react-redux';
import modals from 'src/constants/modals';
import { handleOpenModal } from 'src/utils/propertyHelpers';
import { PropertyCreateModal } from 'src/sections/property/components';
import { selectUser } from 'src/redux/slices/auth';
import AddTransactionModal from 'src/pages/transactions/modals/AddTransactionModal';
import AddRemortgageModal from 'src/pages/transactions/modals/AddRemortgageModal';
// import { useSnackbar } from 'notistack';
// import syncTasks from 'src/api/user/sync-tasks';
// import useLoading from 'src/hooks/useLoading';
import { ActionButtonItem, ActionButtons } from '../styles';

const DashboardActionButtons = () => {
	const user = useSelector(selectUser);
	// const { enqueueSnackbar } = useSnackbar();
	// const { isLoading, setIsLoading } = useLoading();

	const handleAddProperty = () => handleOpenModal(modals.addProperty);
	const handleAddTransaction = () => handleOpenModal(modals.addTransaction);
	// const handleAddRemortgage = () => handleOpenModal(modals.addRemortgage);

	// const handleSyncTasks = async () => {
	// 	try {
	// 		setIsLoading(true);

	// 		await syncTasks();

	// 		enqueueSnackbar('Tasks synced successfully!');
	// 	} catch (e) {
	// 		if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };

	return (
		<ActionButtons>
			{(user.isPrincipal || user.isAdmin) && (
				<ActionButtonItem onClick={handleAddProperty}>
					<Iconify icon='material-symbols:add-circle-outline-rounded' fontSize={20} />
					Add Property
				</ActionButtonItem>
			)}
			{(user.isAncillary || user.isAdmin) && (
				<ActionButtonItem onClick={handleAddTransaction}>
					<Iconify icon='material-symbols:add-circle-outline-rounded' fontSize={20} />
					Add transaction
				</ActionButtonItem>
			)}
			{/* {user.isAdmin && (
				<ActionButtonItem onClick={handleSyncTasks} loading={isLoading}>
					<Iconify icon='material-symbols:sync-outline' fontSize={20} />
					Sync transactions tasks
				</ActionButtonItem>
			)} */}
			<PropertyCreateModal />
			<AddTransactionModal />
			<AddRemortgageModal />
		</ActionButtons>
	);
};

export default DashboardActionButtons;
