// @mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import { useState } from 'react';

// hooks
import { Outlet } from 'react-router';

// config
import { HEADER, NAVBAR } from 'src/config';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import useSettings from 'src/hooks/useSettings';
import useAppBarHeight from 'src/hooks/useAppBarHeight';
import MainHeader from './MainHeader';
import NavbarVertical from './navbar/NavbarVertical';
import { IMainLayout } from './types';
import MainActionButtons from './MainActionButtons';

// ----------------------------------------------------------------------

interface IMainStyle {
	collapseClick: boolean;
}

const MainStyle = styled('main', {
	shouldForwardProp: (prop) => prop !== 'collapseClick',
})<IMainStyle>(({ collapseClick, theme }) => ({
	display: 'flex',
	flexGrow: 1,
	paddingBottom: HEADER.MOBILE_HEIGHT + 24,
	paddingLeft: 16,
	[theme.breakpoints.up('lg')]: {
		paddingRight: 16,
		paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
		width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
		transition: theme.transitions.create('margin-left', {
			duration: theme.transitions.duration.shorter,
		}),
		...(collapseClick && {
			marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
		}),
	},
}));

// ----------------------------------------------------------------------

export default function MainLayout({ withHeader = true, withActions = false, children }: IMainLayout) {
	const { collapseClick } = useCollapseDrawer();
	const appBarHeight = useAppBarHeight();

	const { themeLayout } = useSettings();
	const [open, setOpen] = useState(false);

	const verticalLayout = themeLayout === 'vertical';

	return (
		<Box display='flex' minHeight={1}>
			<NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
			{withHeader && <MainHeader verticalLayout={verticalLayout} />}
			{withActions && <MainActionButtons />}
			<MainStyle collapseClick={collapseClick} sx={{ pt: `${appBarHeight + 7}px` }}>
				{children ?? <Outlet />}
			</MainStyle>
		</Box>
	);
}
