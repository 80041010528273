import Page from 'src/components/Page';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BrandCard, RHFRadioGroup } from 'src/components';
import { PROFESSION_PRINCIPAL, ROLES_ANCILLARY, ROLES_PRINCIPAL } from 'src/constants';
import { dispatch } from 'src/redux/store';
import { selectUser, updateUser, updateUserOnboarding } from 'src/redux/slices/auth';
import { getProfessionRef, getRoleRef } from 'src/utils/firebase';
import { useSelector } from 'react-redux';
import { deleteField } from 'firebase/firestore';
import { OnboardingRoleFormType } from './types';
import { AuthPage, ContinueButton } from '../styles';
import { onboardingRoleValidationSchema } from './validation';
import OnboardingFooter from '../OnboardingFooter';

const OnboardingRole = () => {
	const user = useSelector(selectUser);
	const form = useForm<OnboardingRoleFormType>({
		resolver: yupResolver(onboardingRoleValidationSchema),
		defaultValues: {
			roleId: user.role?.id,
		},
	});

	const handleSubmit = async (data: OnboardingRoleFormType) => {
		await dispatch(
			updateUser({
				role: getRoleRef(data.roleId),
				...(data.roleId === ROLES_PRINCIPAL.id && { professions: [getProfessionRef(PROFESSION_PRINCIPAL.id)] }),
			}),
		);

		if (data.roleId === ROLES_ANCILLARY.id) {
			await dispatch(updateUserOnboarding({ role: true, profession: false }));

			return;
		}

		await dispatch(
			updateUserOnboarding({
				role: true,
				info: false,
				profession: deleteField(),
				company: deleteField(),
				branch: deleteField(),
				newCompany: deleteField(),
				newBranch: deleteField(),
			}),
		);
	};

	return (
		<FormProvider {...form}>
			<Page title='Login'>
				<AuthPage>
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<BrandCard title="I'm here to...">
							<RHFRadioGroup
								name='roleId'
								options={[
									{ name: 'Sell or buy property', key: ROLES_PRINCIPAL.id },
									{ name: 'Provide services to sellers and buyers', key: ROLES_ANCILLARY.id },
								]}
							/>
							<ContinueButton variant='contained' fullWidth type='submit' loading={form.formState.isSubmitting}>
								Continue
							</ContinueButton>
							<OnboardingFooter />
						</BrandCard>
					</form>
				</AuthPage>
			</Page>
		</FormProvider>
	);
};

export default OnboardingRole;
