import { UserBankAccountFile } from 'src/redux/types';
import { call } from '../@';

export type UploadUserBankAccountFilesDto = {
	id: string;
	files: File[];
};

export type UploadUserBankAccountFilesResponse = UserBankAccountFile[];

export default async function uploadUserBankAccountFiles(dto: UploadUserBankAccountFilesDto) {
	return await call('bankAccounts-uploadFiles', dto);
}
