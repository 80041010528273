import { UserOnboardingStep } from 'src/types';

// order matters
export const userOnboardingSteps: UserOnboardingStep[] = [
	'verification',
	'role',
	'profession',
	'company',
	'newCompany',
	'branch',
	'newBranch',
	'info',
];

export const routesByUserOnboardingSteps: Record<UserOnboardingStep, string> = {
	verification: '/onboarding/verification',
	role: '/onboarding/select-role',
	profession: '/onboarding/select-profession',
	company: '/onboarding/select-company',
	newCompany: '/onboarding/new-company',
	branch: '/onboarding/select-branch',
	newBranch: '/onboarding/new-branch',
	info: '/onboarding/fill-info',
};
