import Iconify from 'src/components/Iconify';
import { CompanyBranchStatus, CompanyBranchType, CompanyCondition, CompanyStatus } from 'src/redux/types';
import { LocalOptions } from 'src/components/hook-form/types';
import MonthlyTable from './components/Monthly';
import SolicitorsTable from './components/Solicitors';
import ProgressTable from './components/Progress';

export const tabs = [
	{
		value: 'monthly',
		label: 'Monthly',
		icon: <Iconify icon='material-symbols:calendar-month-outline' width={20} height={20} />,
		component: MonthlyTable,
	},
	{
		value: 'solicitors',
		label: 'Solicitors',
		icon: <Iconify icon='material-symbols:person' width={20} height={20} />,
		component: SolicitorsTable,
	},
	{
		value: 'progress',
		label: 'Progress',
		icon: <Iconify icon='ri:progress-1-line' width={20} height={20} />,
		component: ProgressTable,
	},
];

export const companyStatus = {
	active: 'ACTIVE',
	pending: 'PENDING',
	rejected: 'REJECTED',
} as const;

export const companyCondition = {
	active: 'ACTIVE',
	dissolved: 'DISSOLVED',
	open: 'OPEN',
	closed: 'CLOSED',
	convertedClosed: 'CONVERTED-CLOSED',
	receivership: 'RECEIVERSHIP',
	administration: 'ADMINISTRATION',
	liquidation: 'LIQUIDATION',
	insolvencyProceedings: 'INSOLVENCY-PROCEEDINGS',
	voluntaryArrangement: 'VOLUNTARY-ARRANGEMENT',
	registered: 'REGISTERED',
	removed: 'REMOVED',
} as const;

export const labelByCompanyCondition: Record<CompanyCondition, string> = {
	'CONVERTED-CLOSED': 'Converted closed',
	'INSOLVENCY-PROCEEDINGS': 'Insolvency proceedings',
	'VOLUNTARY-ARRANGEMENT': 'Voluntary arrangement',
	ACTIVE: 'Active',
	ADMINISTRATION: 'Administration',
	CLOSED: 'Closed',
	DISSOLVED: 'Dissolved',
	LIQUIDATION: 'Liquidation',
	OPEN: 'Open',
	RECEIVERSHIP: 'Receivership',
	REGISTERED: 'Registered',
	REMOVED: 'Removed',
};

export const labelByCompanyStatus: Record<CompanyStatus, string> = {
	ACTIVE: 'Active',
	PENDING: 'Pending',
	REJECTED: 'Rejected',
};

export const companyBranchStatus = {
	active: 'ACTIVE',
	pending: 'PENDING',
	rejected: 'REJECTED',
} as const;

export const companyBranchType = {
	realty: 'realty',
	law: 'law',
} as const;

export const labelByCompanyBranchStatus: Record<CompanyBranchStatus, string> = {
	ACTIVE: 'Active',
	PENDING: 'Pending',
	REJECTED: 'Rejected',
};

export const labelByCompanyBranchType: Record<CompanyBranchType, string> = {
	law: 'Law',
	realty: 'Realty',
};

export const companyStatusOptions = Object.values(companyStatus).reduce<LocalOptions[]>((acc, status) => {
	acc.push({ id: status, name: labelByCompanyStatus[status] });

	return acc;
}, []);

export const companyConditionOptions = Object.values(companyCondition).reduce<LocalOptions[]>((acc, condition) => {
	acc.push({ id: condition, name: labelByCompanyCondition[condition] });

	return acc;
}, []);
