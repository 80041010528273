import { VerificationSessionSide } from 'src/hooks/types';
import { call } from '../@';
import { IVerificationSessionResponse } from './types';

export default async function verificationSession({
	side,
	propertyId,
}: {
	color?: string;
	l10n?: string;
	side: VerificationSessionSide;
	propertyId: string;
}): Promise<IVerificationSessionResponse> {
	return await call('yoti-verificationSession', { side, propertyId });
}
