import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Router from './routes';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import FullVersionModal from './components/FullVersionModal';
import {
	TermsAndConditionsModal,
	VerificationRequiredModal,
	PrivacyPolicyModal,
	AcceptableUsePolicyModal,
} from './components/modals';
import { endSession, logout as logoutApi, onUserDataChange, resumeSession } from './api';
import { dispatch } from './redux/store';
import { setIsInitialized, login, logout, selectIsAuthenticated, setIsBlocked, selectUser } from './redux/slices/auth';
import { getNav } from './utils/propertyHelpers';
import { UserStatus } from './types';
import { getCountriesThunk } from './redux/slices/tableSlice';
import { openModal } from './redux/slices/modal';
import modals from './constants/modals';
import RejectedCompanyModal from './components/modals/common/RejectedCompanyModal';
import RejectedBranchModal from './components/modals/common/RejectedBranchModal';
import HelpRequestedModal from './components/modals/common/HelpRequestedModal';
import userData from './api/auth/@user-data';
import CompanyUnderReviewModal from './components/modals/common/CompanyUnderReviewModal';
import BranchUnderReviewModal from './components/modals/common/BranchUnderReviewModal';
import { companyStatus } from './pages/dashboard/components/Companies/constants';

const App = () => {
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const user = useSelector(selectUser);
	const navigate = useNavigate();

	useEffect(() => {
		onUserDataChange(async (data) => {
			console.log('data', data);

			if (data?.status === UserStatus.BLOCKED) {
				dispatch(logout());
				logoutApi();

				dispatch(setIsBlocked(true));

				navigate('/auth/login');

				return;
			}

			if (data) {
				const extendedUser = await userData(data.uid);

				dispatch(login(extendedUser));
				getNav();
			} else {
				dispatch(logout());
				logoutApi();
			}

			dispatch(setIsInitialized(true));
		});
	}, []);

	useEffect(() => {
		const setup = async () => {
			const currentSessionId = sessionStorage.getItem('sessionId');

			if (!currentSessionId) return;

			await resumeSession(currentSessionId).catch((e) => {
				sessionStorage.removeItem('sessionId');

				console.log(e);
			});
		};

		if (isAuthenticated) setup();

		const handleUnload = async () => {
			const currentSessionId = sessionStorage.getItem('sessionId');

			if (!currentSessionId) return;

			await endSession(currentSessionId).catch(console.log);
		};

		window.addEventListener('unload', handleUnload);

		return () => {
			window.removeEventListener('unload', handleUnload);
		};
	}, [isAuthenticated]);

	useEffect(() => {
		const setup = async () => {
			dispatch(getCountriesThunk());

			if (!user || user.status === UserStatus.ONBOARDING) return;

			const { business, branch } = await user.getBusiness();

			if (business?.data.status === companyStatus.pending) {
				dispatch(openModal({ name: modals.companyUnderReview, payload: { companyName: business.data.legalName } }));

				return;
			}

			if (business && branch?.data.status === companyStatus.pending) {
				dispatch(
					openModal({
						name: modals.branchUnderReview,
						payload: { companyName: business.data.legalName, branchName: branch.data.name },
					}),
				);

				return;
			}

			if (user.isHelpRequested && user.status === UserStatus.REJECTED_COMPANY && business) {
				dispatch(openModal({ name: modals.helpRequested, payload: { companyName: business.data.legalName } }));

				return;
			}

			if (user.isHelpRequested && user.status === UserStatus.REJECTED_BRANCH && business && branch) {
				dispatch(
					openModal({
						name: modals.helpRequested,
						payload: { companyName: business.data.legalName, branchName: branch.data.name },
					}),
				);

				return;
			}

			if (business && user.status === UserStatus.REJECTED_COMPANY) {
				dispatch(
					openModal({
						name: modals.rejectedCompany,
						payload: { companyName: business.data.legalName, companyId: business.id },
					}),
				);

				return;
			}

			if (business && branch && user.status === UserStatus.REJECTED_BRANCH) {
				dispatch(
					openModal({
						name: modals.rejectedBranch,
						payload: {
							companyName: business.data.legalName,
							branchName: branch.data.name,
							companyId: business.id,
							branchId: branch.id,
						},
					}),
				);
			}
		};

		setup();
	}, [user, user?.status]);

	return (
		<>
			<ProgressBarStyle />
			<ScrollToTop />
			<FullVersionModal />
			<VerificationRequiredModal />
			<PrivacyPolicyModal />
			<TermsAndConditionsModal />
			<AcceptableUsePolicyModal />
			<RejectedCompanyModal />
			<RejectedBranchModal />
			<HelpRequestedModal />
			<CompanyUnderReviewModal />
			<BranchUnderReviewModal />
			<Router />
		</>
	);
};

export default App;
