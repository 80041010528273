import { FC } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { RHFCheckbox } from 'src/components';
import { PreferredMethodCategoryProps } from './types';

const PreferredMethodCategory: FC<PreferredMethodCategoryProps> = ({ label, name, options, disabled }) => {
	const { formState } = useFormContext();

	const error = get(formState.errors, name)?.message?.toString();

	return (
		<Box position='relative'>
			<Typography fontSize='17px' fontWeight={600}>
				{label}
			</Typography>
			<Box display='flex' flexDirection='column' mt='5px' gap='3px'>
				{options.map((o) => (
					<RHFCheckbox
						key={o.name}
						name={`${name}.${o.name}`}
						label={o.label}
						sx={{ padding: '5px', pl: '0' }}
						labelControlProps={{ labelPlacement: 'end' }}
						disabled={disabled}
						disableRipple
					/>
				))}
			</Box>
			{!!error && (
				<Typography color='error' fontSize='13px' position='absolute' whiteSpace='nowrap'>
					{error}
				</Typography>
			)}
		</Box>
	);
};

export default PreferredMethodCategory;
