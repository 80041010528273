import { useSelector } from 'react-redux';
import { selectTransactionsSummaries } from 'src/redux/slices/tableSlice';
import { useMemo } from 'react';
import { selectUserSettings } from 'src/redux/slices/auth';
import { TableCell, TableRow } from '../../../styles';
import { calculateTransactionSummariesTotals } from './utils';

const SummariesTableTotal = () => {
	const { list } = useSelector(selectTransactionsSummaries);
	const settings = useSelector(selectUserSettings);

	const totals = useMemo(() => calculateTransactionSummariesTotals(list.data), [list.data]);

	if (!list.data.length) return null;

	return (
		<TableRow isActive>
			<TableCell />
			{settings.summariesColumns.map((column) => (
				<TableCell key={column}>{totals[column]}</TableCell>
			))}
		</TableRow>
	);
};

export default SummariesTableTotal;
