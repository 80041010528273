import { call } from '../@';

const startSession = async (isFromLogin = false) => {
	const existingSessionId = sessionStorage.sessionId;

	if (existingSessionId) return;

	const { sessionId } = await call('sessions-start', { isFromLogin }).catch(() => ({ sessionId: null }));

	if (sessionId) sessionStorage.setItem('sessionId', sessionId);
};

export default startSession;
