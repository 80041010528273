/* eslint-disable func-names */
import { getPhoneNumberString, optionalString, requiredString } from 'src/utils/schemas';
import * as Yup from 'yup';

const PreferredContactMethodsCategorySchema = Yup.object().test(
	'atLeastOneMethodSelected',
	'This field is required',
	(value) => Object.values(value ?? {}).some((v) => v === true),
);

const PreferredContactMethodsSchema = Yup.object().shape({
	voice: PreferredContactMethodsCategorySchema,
	message: PreferredContactMethodsCategorySchema,
	mail: PreferredContactMethodsCategorySchema,
});

const IndividualRegisterSchema = Yup.object().shape({
	givenNames: requiredString,
	lastNames: requiredString,
	email: requiredString.email(),
	telephone: getPhoneNumberString(),
	landline: getPhoneNumberString(false),
	nino: Yup.string().required('This field is required'),
	preferredContactMethods: PreferredContactMethodsSchema,
});

const BusinessRegisterSchema = Yup.object().shape({
	givenNames: requiredString,
	lastNames: requiredString,
	email: requiredString.email(),
	business: Yup.string().required('Business name is required'),
	branch: Yup.string().required('Branch is required'),
	telephone: getPhoneNumberString(),
	landline: getPhoneNumberString(false),
	address: Yup.object().required().shape({
		country: optionalString,
		street: optionalString,
		city: optionalString,
		postcode: optionalString,
		state: optionalString,
		county: optionalString,
	}),
	preferredContactMethods: PreferredContactMethodsSchema,
	website: Yup.string().nullable().optional().url('Website must be valid url string'),
});

export const defaultValues = {
	address: {
		country: 'United Kingdom',
		street: '',
		city: '',
		postcode: '',
		state: '',
		county: '',
	},
	givenNames: '',
	lastNames: '',
	knownAs: '',
	email: '',
	telephone: '',
	landline: '',
	business: '',
	branch: '',
	nino: '',
	title: '',
	preferredContactMethods: {
		mail: { email: true, post: true },
		message: { whatsApp: false, text: false },
		voice: { mobile: false, landline: false },
	},
};

export const getSchema = (isBusinessRole) => {
	if (!isBusinessRole) return IndividualRegisterSchema;

	return BusinessRegisterSchema;
};
