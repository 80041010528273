import { FC } from 'react';
import { Typography } from '@mui/material';
import { dispatch } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { SignButtonProps } from './types';

const SignButton: FC<SignButtonProps> = ({ disabled, task, viewOnly = false }) => {
	const handleClick = () => dispatch(openModal({ name: modals.signDeed, payload: { task, viewOnly } }));

	return (
		<Typography
			variant='link'
			onClick={handleClick}
			sx={{
				pointerEvents: disabled ? 'none' : 'auto',
				display: 'inline-flex',
				alignItems: 'center',
				gap: '3px',
			}}
			{...(disabled && { color: 'gray' })}
		>
			{viewOnly ? '[View]' : '[Review & Sign]'}
		</Typography>
	);
};

export default SignButton;
