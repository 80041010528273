import { Country } from 'src/redux/types';
import { requiredString } from 'src/utils/schemas';
import * as Yup from 'yup';

const commonValidationFields = {
	userIds: Yup.array(requiredString).min(1, 'Users must not be empty'),
	bank: requiredString,
	countryCode: requiredString,
	params: Yup.object(),
};

export const getValidationSchema = (country?: Country) => {
	const fields = { ...commonValidationFields };

	if (country) {
		const paramsFields = country?.banking.fields.reduce((acc, f) => {
			acc[f.key] = requiredString.matches(new RegExp(f.regex), { message: f.error });

			return acc;
		}, {});

		fields.params = Yup.object().shape(paramsFields);
	}

	return Yup.object().shape(fields);
};
