import { FC } from 'react';
import { CompanyBranch } from 'src/redux/types';
import { Button } from '@mui/material';
import { dispatch } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { companyBranchStatus, labelByCompanyBranchStatus } from '../../constants';

const BranchStatusField: FC<CompanyBranch> = (branch) => {
	const { status } = branch;

	if (status === companyBranchStatus.pending) {
		return (
			<Button
				variant='contained'
				onClick={() => dispatch(openModal({ name: modals.verifyCompanyBranch, payload: branch }))}
			>
				{labelByCompanyBranchStatus[status]}
			</Button>
		);
	}

	return <>{labelByCompanyBranchStatus[status]}</>;
};

export default BranchStatusField;
