import type { FC } from 'react';
import { createFilterOptions } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TransactionInvitationRoleItem } from 'src/pages/transactions/types';
import type { IRHFInvitationRoleAutocomplete } from './types';
import { RHFAutocomplete } from '.';
import { getTransactionInvitationRoleItemLabel } from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterOptions = createFilterOptions({ stringify: ({ role }: any) => role });

const RHFInvitationRoleAutocomplete: FC<IRHFInvitationRoleAutocomplete> = ({
	options,
	name,
	setInnerRole,
	...other
}) => {
	const { watch, setValue } = useFormContext();
	const [side, role, relationship] = watch(['side', 'role', 'relationship']);
	const formattedOptions = options.reduce<TransactionInvitationRoleItem[]>((acc, o) => {
		if (!side || o.side === side) acc.push(o);

		return acc;
	}, []);

	const handleRoleClick = (value) => {
		if (!value) {
			setValue('role', null);
			setValue('relationship', null);

			return;
		}

		setValue('role', value.role);
		setValue('relationship', value.relationship ?? null);

		setInnerRole?.(value.role);
	};

	return (
		<RHFAutocomplete
			onChange={(_, value) => handleRoleClick(value)}
			filterOptions={filterOptions}
			options={formattedOptions}
			name={name}
			value={formattedOptions.find((user) => user.role === role && user.relationship === relationship)}
			getOptionLabel={getTransactionInvitationRoleItemLabel}
			renderOption={(props, option: TransactionInvitationRoleItem) => (
				<li {...props} key={option.role}>
					{getTransactionInvitationRoleItemLabel(option)}
				</li>
			)}
			{...other}
		/>
	);
};

export default RHFInvitationRoleAutocomplete;
