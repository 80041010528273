import { DocumentReference, getDoc, type DocumentSnapshot } from 'firebase/firestore';
import {
	PROFESSION_ESTATE_AGENT,
	PROFESSION_MORTGAGE_BROKER,
	PROFESSION_PRINCIPAL,
	PROFESSION_SOLICITOR,
	PROFESSION_SURVEYOR,
	PROFESSION_TRADES_PERSON,
} from 'src/constants';
import {
	type VerificationSession,
	type Branch,
	type User,
	VerificationSessionStatus,
	UserChangeEmailRequest,
} from 'src/types';
import { getDataByRef, getUser } from 'src/utils/firebase';
import { TransactionRole } from 'src/pages/transactions/types';
import { convertProfessionIdToTransactionRole, getCurrentUserOnboardingStep } from 'src/utils/users';
import { transactionSummaryColumnsKeys } from 'src/pages/dashboard/constants/summary';
import { findUserVerificationSession } from 'src/utils/firebase/verification-session';
import { routesByUserOnboardingSteps } from 'src/pages/auth/constants';
import { ExtendedUserOnboarding } from 'src/redux/slices/auth';
import { findUserChangeEmailRequest } from 'src/utils/firebase/user-change-email-request';
import { findUserOnboarding } from 'src/utils/firebase/user-onboarding';
import UserProto from './@user-proto';
import { roles } from '../@db';

class UserData extends UserProto {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	ref: DocumentReference<UserProto>;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	onboarding: ExtendedUserOnboarding | null;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	changeEmailRequest: UserChangeEmailRequest | null;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	verificationSession: VerificationSession | null;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	verificationSessionRef: DocumentReference<VerificationSession> | null;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	role: RRole;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	professions: RProfession[];

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	roleName: string;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	defaultRoleId: string;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	isAdmin: boolean;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	isEstateAgent: boolean;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	isSolicitorConveyancer: boolean;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	isTradesPerson: boolean;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	isSurveyor: boolean;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	isMortgageBroker: boolean;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	isPrincipal: boolean;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	isAncillary: boolean;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	transactionRole: TransactionRole | 'admin';

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	professionName: string;

	static async create(uid: string, ref: DocumentReference<User>, docSnap: DocumentSnapshot<User>) {
		const self = Object.create(UserData.prototype) as UserData;
		// this is verified before creation so...
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const data = docSnap.data()!;
		const [role, userProfessions, verificationSessionSnapshot, onboarding, changeEmailRequest] = await Promise.all([
			data.role ? getDataByRef<RRole>(data.role) : null,
			Promise.all(data.professions.map((p) => getDataByRef(p))),
			findUserVerificationSession(uid),
			findUserOnboarding(ref.id),
			findUserChangeEmailRequest(uid),
		]);
		const professionIds = userProfessions.map((p) => p.id);

		const isAdmin = role?.id === roles.admin;
		const isTradesPerson = professionIds.includes(PROFESSION_TRADES_PERSON.id);
		const isSurveyor = professionIds.includes(PROFESSION_SURVEYOR.id);
		const isMortgageBroker = professionIds.includes(PROFESSION_MORTGAGE_BROKER.id);
		const isEstateAgent = professionIds.includes(PROFESSION_ESTATE_AGENT.id);
		const isSolicitorConveyancer = professionIds.includes(PROFESSION_SOLICITOR.id);
		const isPrincipal = professionIds.includes(PROFESSION_PRINCIPAL.id);
		const verificationSession = verificationSessionSnapshot ? { ...verificationSessionSnapshot.data() } : null;
		const verificationSessionRef = verificationSessionSnapshot ? verificationSessionSnapshot.ref : null;

		const currentOnboardingStep = onboarding ? getCurrentUserOnboardingStep(onboarding.data) : null;

		Object.assign(self, {
			...data,
			uid,
			ref,
			role,
			onboarding: onboarding
				? {
						...onboarding.data,
						currentPage: routesByUserOnboardingSteps[getCurrentUserOnboardingStep(onboarding.data)],
						currentStep: currentOnboardingStep,
				  }
				: null,
			professions: userProfessions,
			roleName: role?.name,
			defaultRoleId: role?.id,
			isAdmin,
			isTradesPerson,
			isSurveyor,
			isMortgageBroker,
			isEstateAgent,
			isSolicitorConveyancer,
			isPrincipal,
			isAncillary: isTradesPerson || isSurveyor || isEstateAgent || isSolicitorConveyancer || isMortgageBroker,
			transactionRole: isAdmin
				? 'admin'
				: professionIds.length
				? convertProfessionIdToTransactionRole(professionIds[0])
				: null,
			professionName: isAdmin ? 'Admin' : userProfessions[0]?.name,
			verificationStatus: data.verificationStatus ?? VerificationSessionStatus.PENDING,
			verificationSession,
			verificationSessionRef,
			settings: {
				summariesFilters: data.settings?.summariesFilters ?? {},
				pinnedProperties: data.settings?.pinnedProperties ?? [],
				pinnedTransactions: data.settings?.pinnedTransactions ?? [],
				summariesColumns:
					data.settings?.summariesColumns
						?.filter((column) => transactionSummaryColumnsKeys.includes(column))
						?.sort((a, b) => transactionSummaryColumnsKeys.indexOf(a) - transactionSummaryColumnsKeys.indexOf(b)) ??
					transactionSummaryColumnsKeys,
			},
			changeEmailRequest: changeEmailRequest ? { ...changeEmailRequest.data(), id: changeEmailRequest.id } : null,
		});

		return self;
	}

	async getAddress() {
		const isBusinessRole: boolean = this.isEstateAgent || this.isSolicitorConveyancer;

		if (isBusinessRole && this.branch instanceof DocumentReference) {
			const branch = (await getDoc(this.branch)).data() as Branch;

			return {
				street: branch.address.formattedAddress,
				postcode: branch.address.postcode,
				country: branch.address.country,
				county: branch.address.countyProvince,
				city: branch.address.townCity,
				state: branch.address.stateRegion,
				telephone: this.telephone,
			};
		}

		return {
			country: this.address?.country,
			street: this.address?.street,
			postcode: this.address?.postcode,
			city: this.address?.city,
			county: this.address?.county,
			state: this.address?.state,
			telephone: this.telephone,
		};
	}

	async getBusiness() {
		let business: { id: string; data: Partial<RBusiness> } | null = null;
		let branch: { id: string; data: Partial<RBranch> } | null = null;

		if (this.business instanceof DocumentReference) {
			const businessDoc = await getDoc(this.business);

			business = {
				id: businessDoc.id,
				data: businessDoc.data() as RBusiness,
			};
		} else if (typeof this.business === 'string') {
			business = {
				id: '',
				data: { tradingName: this.business },
			};
		}

		if (this.branch instanceof DocumentReference) {
			const branchDoc = await getDoc(this.branch);

			branch = {
				id: branchDoc.id,
				data: branchDoc.data() as RBranch,
			};
		} else if (typeof this.branch === 'string') {
			branch = {
				id: '',
				data: { name: this.branch },
			};
		}

		return { business, branch };
	}
}

export default async function userData(uid: string) {
	const user = await getUser(uid).catch(() => null);

	if (!user) return null;

	return await UserData.create(uid, user.ref, user.snapshot);
}

export type { UserData };
