import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { QuestionnaireTypeEnum } from 'src/types';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { sellerFinance } from 'src/constants/surveyJson/sellerFinance';
import { selectTransactionFinance, selectTransactionParticipants } from 'src/redux/slices/transaction';
import { selectUser } from 'src/redux/slices/auth';
import { useQuestionnaire } from '../useQuestionnaire';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { DescriptionBlock } from '../styles';
import { modalSx } from './styled';

const SellerFinanceCard = () => {
	const { fullQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const participants = useSelector(selectTransactionParticipants);
	const finance = useSelector(selectTransactionFinance);
	const user = useSelector(selectUser);
	const { onSubmit, onComplete, onPageChanged, isHidden, isNotAllowed, notAllowedMessage } = useQuestionnaire({
		type: QuestionnaireTransactionEnum.seller_finance,
		questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
	});

	if (!fullQuestionnaire || isHidden || !finance.data) return null;

	const completedTransactionSellerFinance = fillQuestionnaireJsonWithCompletedData(
		sellerFinance(participants.participants, participants.placeholders, fullQuestionnaire, user, finance.data),
		fullQuestionnaire?.seller_finance,
	);

	return (
		<>
			<QuestionnarieModal
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				initialCurrentPage={fullQuestionnaire?.seller_finance?.currentPage ?? 0}
				questionnaireKey={QuestionnaireTransactionEnum.seller_finance}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				surveyJson={completedTransactionSellerFinance}
				modalSx={modalSx}
				status={fullQuestionnaire?.seller_finance.status}
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				title='Seller Finance'
				type={QuestionnaireTransactionEnum.seller_finance}
				notAllowed={isNotAllowed}
				notAllowedMessage={notAllowedMessage}
			>
				{({ details }) => {
					const bankAccountsAmount = details?.banking?.bank_accounts?.length;

					if (!bankAccountsAmount) return null;

					return (
						<DescriptionBlock>
							{bankAccountsAmount} bank account{bankAccountsAmount >= 2 && 's'} provided
						</DescriptionBlock>
					);
				}}
			</QuestionnaireCard>
		</>
	);
};

export default SellerFinanceCard;
