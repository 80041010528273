import { useEffect, useRef, FC } from 'react';
import { RHFTextField } from 'src/components/hook-form';
import { get, useFormContext } from 'react-hook-form';
import { initAutocomplete, initMapScript } from './GooglePlaceAutocomlete';
import { IRHFGoogleAddressAutocomplete } from './types';

/* 
  Prop setValue should come from useForm
  Autocomplete will work with folowing field names: 
  "postcode" for zip code
  "city" for city name
  "country" for country name
  "state" for state name
  "county" for county name
  "street" for street name
*/

const GoogleAddressAutocomplete: FC<IRHFGoogleAddressAutocomplete> = ({
	isDisabled = false,
	name,
	label,
	setValue,
	clearErrors,
	shrink = true,
	...other
}) => {
	const searchInput = useRef(null);
	useEffect(() => {
		initMapScript().then(() => initAutocomplete(searchInput, setValue, clearErrors));
	}, []);

	const form = useFormContext();
	const addressErrors = get(form.formState.errors, 'address') ?? {};

	const hasErrors = Object.keys(addressErrors).length;

	return (
		<RHFTextField
			disabled={isDisabled}
			inputRef={searchInput}
			name={name}
			label={label}
			shrink={shrink}
			{...other}
			{...(hasErrors && { error: true, helperText: 'Address must be valid building address' })}
		/>
	);
};
export default GoogleAddressAutocomplete;
