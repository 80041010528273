import { useSelector } from 'react-redux';
import useLoading from 'src/hooks/useLoading';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { selectUser } from 'src/redux/slices/auth';
import { selectProperty, updatePropertyOnboarding } from 'src/redux/slices/property';
import { dispatch } from 'src/redux/store';
import { OnboardingStepStatusEnum, VerificationSessionStatus } from 'src/types';
import { capitalize } from 'lodash';
import { Alert } from '@mui/material';
import { ConfirmationModal } from '../../common';

const PropertyOwnershipModal = () => {
	const { isLoading, setIsLoading } = useLoading();
	const user = useSelector(selectUser);
	const property = useSelector(selectProperty);

	const handleConfirm = async () => {
		setIsLoading(true);

		await dispatch(
			updatePropertyOnboarding({
				payload: { ownership: OnboardingStepStatusEnum.PASSED },
				moveToNextModal: true,
			}),
		);

		setIsLoading(false);
	};

	const action = property.onboarding?.activeSide === 'sell' ? 'sell' : 'remortgage';

	return (
		<ConfirmationModal
			sx={{ maxWidth: '620px' }}
			modalName={propertyOnboardingSteps.ownership}
			isLoading={isLoading}
			title={`${capitalize(action)} ${property.name}`}
			withCancelButton={false}
			confirmText='Continue'
			description={
				<>
					To help you {action} this property, Conveyo needs to{' '}
					{user.verificationStatus !== VerificationSessionStatus.VERIFIED && (
						<>
							<b>verify your identity</b> and
						</>
					)}{' '}
					<b>obtain the property title</b> from Land Registry. This service comes with a one-time fee of <b>£19.99</b>.
					<br />
					<br />
					Once these steps are complete, Conveyo will guide you through the property questionnaires, ensuring all
					necessary information is provided. Our team will always be available to assist you.
					<br />
					<br />
					<Alert severity='info'>
						If you'd like to work on your transaction with one of our partner solicitors, you can get a quote{' '}
						<a href='https://www.conveyo.io/quotes' target='_blank' rel='noreferrer'>
							here
						</a>
					</Alert>
				</>
			}
			handleConfirm={handleConfirm}
		/>
	);
};

export default PropertyOwnershipModal;
