import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { groupPropertyTitles, handleCloseModal } from 'src/utils/propertyHelpers';
import { RHFCheckboxGroup, RHFRadioGroup, RHFTextField } from 'src/components/hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { selectProperty } from 'src/redux/slices/property';
import { dispatch } from 'src/redux/store';
import Progress from 'src/components/Progress';
import { BrandModal } from 'src/components/common';
import { openModal, selectModal } from 'src/redux/slices/modal';
import { PropertyTitle } from 'src/types';
import useError from 'src/hooks/useError';
import useLoading from 'src/hooks/useLoading';
import searchPropertyTitles from 'src/api/property/search-property-titles';
import { labelByPropertyTitleTenure } from 'src/constants/title';
import { SearchTitleForm } from './types';

const Content = () => {
	const property = useSelector(selectProperty);
	const { payload } = useSelector(selectModal<{ areOfficialCopies?: boolean }>);
	const [titles, setTitles] = useState<PropertyTitle[]>([]);
	const [isSearching, setIsSearching] = useState(true);
	const { error, setError } = useError();
	const { isLoading, setIsLoading } = useLoading();
	const form = useForm<SearchTitleForm>();

	const titlesWatcher = useWatch({ control: form.control, name: 'titles' });

	const selectedTitles = useMemo(
		() =>
			(titlesWatcher ?? []).reduce<Array<{ title: string; tenure: string }>>((acc, item) => {
				if (item.value) {
					const [title, tenure] = item.key.split('-');

					acc.push({ title, tenure });
				}

				return acc;
			}, []),
		[titlesWatcher],
	);

	const handleSearch = async (data: SearchTitleForm) => {
		try {
			setError(null);
			setIsSearching(true);
			setIsLoading(true);

			const foundTitles = await searchPropertyTitles({ ...data, id: property.id });

			setTitles(foundTitles);
			setIsSearching(false);
		} catch (e) {
			if (e instanceof Error) {
				setError(e.message);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleSelect = async (data: SearchTitleForm) => {
		if (payload.areOfficialCopies) {
			if (!selectedTitles.length) {
				form.setError('titles', { message: 'At least one title must be selected' });

				return;
			}

			dispatch(openModal({ name: modals.availableDocuments, payload: { titles: selectedTitles } }));

			return;
		}

		if (!data.title) {
			form.setError('title', { message: 'This field is required' });

			return;
		}

		setIsLoading(true);

		const selectedTitle = titles.find((t) => t.number === data.title);

		if (!selectedTitle) return;

		dispatch(openModal({ name: modals.titleAssignmentConfirmation, payload: selectedTitle }));

		setIsLoading(false);
	};

	const groupedTitles = groupPropertyTitles(titles);

	useEffect(() => {
		if (payload.areOfficialCopies) {
			form.reset({ titles: titles.map((title) => ({ key: `${title.number}-${title.tenure}`, value: false })) });
		}
	}, [payload.areOfficialCopies, titles]);

	useEffect(() => {
		if (payload.areOfficialCopies && selectedTitles.length) form.clearErrors('titles');
	}, [payload.areOfficialCopies, selectedTitles]);

	return (
		<FormProvider {...form}>
			<form>
				<BrandModal.Title title={payload.areOfficialCopies ? 'GET OFFICIAL COPIES' : 'ASSIGN TITLE'} />
				<Box py='15px'>
					{error}
					<Box display='flex' flexDirection='column' gap='15px' my='15px'>
						<RHFTextField name='saon' label='Saon' disabled={!isSearching} />
						<RHFTextField name='paon' label='Paon' disabled={!isSearching} />
						<RHFTextField name='street' label='Street' disabled={!isSearching} />
						<RHFTextField name='city' label='Town' disabled={!isSearching} />
						<RHFTextField name='postcode' label='Postcode' disabled={!isSearching} />
					</Box>
					{isLoading ? (
						<Box py='100px'>
							<Progress />
						</Box>
					) : (
						<div>
							{!isSearching && (
								<Box display='flex' flexDirection='column' gap='10px'>
									{groupedTitles.length ? (
										<>
											{payload.areOfficialCopies ? (
												<RHFCheckboxGroup
													name='titles'
													options={titles.map(({ number, tenure }) => ({
														value: `${number}-${tenure}`,
														label: `${number} (${labelByPropertyTitleTenure[tenure]})`,
													}))}
												/>
											) : (
												<>
													{groupedTitles.map((group) => (
														<Box key={group.address}>
															<Typography fontWeight={600} fontSize='15px'>
																{group.address}
															</Typography>
															<RHFRadioGroup name='title' row={false} sx={{ width: '100%' }} options={group.titles} />
														</Box>
													))}
												</>
											)}
										</>
									) : (
										<Typography>No results found.</Typography>
									)}
								</Box>
							)}
							<ActionButtons mt='20px'>
								<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal}>
									Cancel
								</LoadingButton>
								{isSearching ? (
									<LoadingButton variant='contained' size='large' fullWidth onClick={form.handleSubmit(handleSearch)}>
										Search
									</LoadingButton>
								) : (
									<LoadingButton variant='contained' size='large' fullWidth onClick={form.handleSubmit(handleSelect)}>
										Select
									</LoadingButton>
								)}
							</ActionButtons>
						</div>
					)}
				</Box>
			</form>
		</FormProvider>
	);
};

const SearchTitleModal = () => (
	<Modal name={modals.searchTitle} cardSx={{ overflow: 'visible', maxWidth: '600px' }}>
		<Content />
	</Modal>
);

export default SearchTitleModal;
