import { ChangeEvent, FC, MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
import { useTask } from 'src/components/GeneralTab/components/TasksSection/components/Task/hooks/useTask';
import palette from 'src/theme/palette';
import { Box, Checkbox, Fade, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import MenuPopover from 'src/components/MenuPopover';
import Iconify from 'src/components/Iconify';
import { taskCategory, taskStatus, taskType } from 'src/constants/tasks';
import { getTaskRef } from 'src/utils/firebase';
import { deleteField, updateDoc } from 'firebase/firestore';
import { dispatch } from 'src/redux/store';
import { getTransactionTrackerThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import { DatePicker } from '@mui/x-date-pickers';
import { useSelector } from 'react-redux';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { transactionRoles, transactionUserRelationship } from 'src/pages/transactions/constants';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { call } from 'src/api/@';
import { TaskProps } from './types';
import { SummaryFieldWrapper } from '../../../SummaryField/styled';

const Task: FC<TaskProps> = ({ setIsLoading, ...task }) => {
	const transaction = useSelector(selectTransactionOverview);
	const { enqueueSnackbar } = useSnackbar();
	const { description, statement } = useTask(task);
	const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
	const [date, setDate] = useState<Date | null>(
		task.completedAt ? new Date(task.completedAt) : task.dueDate ? new Date(task.dueDate) : null,
	);

	const isExchangeOrCompleteTask = task.type === taskType.hasExchanged || task.type === taskType.hasCompleted;

	useEffect(() => {
		setDate(task.completedAt ? new Date(task.completedAt) : task.dueDate ? new Date(task.dueDate) : null);
	}, [task.completedAt, task.dueDate]);

	const taskRef = getTaskRef(task.id);

	const handleDelete = async () => {
		setMenuAnchor(null);

		if (task.users.length > 1) {
			dispatch(openModal({ name: modals.deleteTask, payload: task }));

			return;
		}

		await updateDoc(taskRef, { deleted: true });
		dispatch(getTransactionTrackerThunk({ id: transaction.id, withLoading: true }));
	};
	const handleRestore = async () => {
		setMenuAnchor(null);

		await updateDoc<unknown>(taskRef, { deleted: false, deletedForUsers: [] });
		dispatch(getTransactionTrackerThunk({ id: transaction.id, withLoading: true }));
	};
	const handleMove = () => {
		setMenuAnchor(null);
		dispatch(openModal({ name: modals.moveTask, payload: task }));
	};
	const handleCheckboxClick: MouseEventHandler = (e) => {
		if (isExchangeOrCompleteTask) {
			e.preventDefault();

			dispatch(openModal({ name: modals.confirmTaskCompleteModal, payload: task }));
		}
	};
	const handleCheckboxChange = async (e: ChangeEvent<HTMLInputElement>) => {
		try {
			if (isExchangeOrCompleteTask) setIsLoading(true);

			const isChecked = e.target.checked;
			const newDate = isChecked ? new Date() : null;

			await updateDoc<unknown>(taskRef, {
				status: isChecked ? taskStatus.done : taskStatus.toDo,
				completedAt: newDate,
				...(isExchangeOrCompleteTask && !task.dueDate && { dueDate: newDate }),
			});
			setDate(newDate);

			if (isExchangeOrCompleteTask) await call('transaction-syncTasks', { id: transaction.id });

			dispatch(getTransactionTrackerThunk({ id: transaction.id, withLoading: true }));
		} catch (err) {
			console.log(err);

			if (err instanceof Error) enqueueSnackbar(err.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};
	const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>) => setMenuAnchor(e.currentTarget);
	const handleDateChange = async (newDate: Date | null) => {
		try {
			if (isExchangeOrCompleteTask) setIsLoading(true);

			const dateField = isExchangeOrCompleteTask
				? 'dueDate'
				: task.category === 'admin' || task.status === taskStatus.done
				? 'completedAt'
				: 'dueDate';

			setDate(newDate);
			await updateDoc(taskRef, { [dateField]: newDate });

			if (isExchangeOrCompleteTask) await call('transaction-syncTasks', { id: transaction.id });

			dispatch(getTransactionTrackerThunk({ id: transaction.id }));
		} catch (e) {
			console.log(e);

			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};
	const handleSnooze = () => {
		setMenuAnchor(null);
		dispatch(openModal({ name: modals.snoozeTask, payload: task }));
	};
	const handleUnsnooze = async () => {
		setMenuAnchor(null);

		await updateDoc<unknown>(taskRef, { snoozed: false, snoozeDays: deleteField(), snoozedAt: deleteField() });
		dispatch(getTransactionTrackerThunk({ id: transaction.id, withLoading: true }));
	};

	const now = new Date();

	now.setHours(0, 0, 0, 0);

	const mark = task.snoozed
		? '💤'
		: task.status === taskStatus.done && (!task.completedAt || new Date(task.completedAt) < now)
		? '🟢'
		: task.status === taskStatus.done
		? '🟢'
		: task.dueDate && new Date(task.dueDate) < now
		? '🟠'
		: task.status === taskStatus.inProgress || task.dueDate
		? '🟡'
		: '⚪️';

	const hasHiddenCheckbox =
		(task.type === taskType.hasExchanged || task.type === taskType.hasCompleted) &&
		task.dueDate &&
		new Date(task.dueDate) > moment().endOf('day').toDate();

	const title = (
		<Box display='flex' alignItems='center' gap='10px'>
			{task.category !== 'admin' || hasHiddenCheckbox ? (
				<Box width='38px' />
			) : (
				<Box>
					<Checkbox
						checked={task.status === taskStatus.done}
						onChange={handleCheckboxChange}
						disabled={task.deleted || task.snoozed || (isExchangeOrCompleteTask && task.status === taskStatus.done)}
						onClick={handleCheckboxClick}
					/>
				</Box>
			)}
			<Box>
				{mark} {description ?? statement}
			</Box>
		</Box>
	);

	const children =
		task.status === taskStatus.toDo &&
		!task.snoozed &&
		task.type !== taskType.hasExchanged &&
		task.type !== taskType.hasCompleted ? null : (
			<DatePicker
				value={task.snoozed ? task.snoozedAt : date}
				onChange={handleDateChange}
				renderInput={(props) => <TextField fullWidth {...props} sx={{ width: '200px' }} />}
				inputFormat='dd/MM/yyyy'
				disabled={
					(task.category !== taskCategory.admin && task.status === taskStatus.done) ||
					task.deleted ||
					task.snoozed ||
					(isExchangeOrCompleteTask && task.status === taskStatus.done)
				}
			/>
		);

	return (
		<SummaryFieldWrapper
			display='flex'
			alignItems='stretch'
			justifyContent='space-between'
			px='10px'
			sx={{ borderBottom: `2px solid ${palette.light.grey[400]}` }}
		>
			<Box display='flex' alignItems='center' flex={0.45} py='10px' pr='10px'>
				<Typography variant='h6'>{title}</Typography>
			</Box>
			<Box
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
				alignItems='center'
				flex={0.2}
				borderLeft={`2px solid ${palette.light.grey[400]}`}
				pl='7px'
			>
				<Box display='flex' alignItems='center' py='7px'>
					{children}
				</Box>
			</Box>
			<Box
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
				flex={0.4}
				borderLeft={`2px solid ${palette.light.grey[400]}`}
				pl='7px'
			>
				<Box display='flex' alignItems='center' py='7px'>
					<ul style={{ listStyle: 'inside' }}>
						{task.users.map((u) => {
							const user = (
								<>
									<b style={{ display: 'inline' }}>
										{u.givenNames} {u.lastNames}
									</b>{' '}
									- {transactionRoles[u.role]?.name}
									{u.relationship ? ` (${transactionUserRelationship[u.relationship]?.shortName})` : ''}
								</>
							);

							return <li key={u.id}>{user}</li>;
						})}
					</ul>
				</Box>
				<Box display='flex' alignItems='center' borderLeft={`2px solid ${palette.light.grey[400]}`} pl='7px'>
					<IconButton
						id='settings'
						aria-controls={menuAnchor ? 'fade-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={menuAnchor ? 'true' : undefined}
						onClick={handleMenuOpen}
					>
						<Iconify icon='bi:three-dots-vertical' width={20} height={20} />
					</IconButton>
					<MenuPopover
						id='fade-menu'
						anchorEl={menuAnchor}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						open={!!menuAnchor}
						onClose={() => setMenuAnchor(null)}
						TransitionComponent={Fade}
					>
						{task.deleted ? (
							<MenuItem onClick={handleRestore}>Restore</MenuItem>
						) : task.snoozed ? (
							<MenuItem onClick={handleUnsnooze}>Unsnooze</MenuItem>
						) : (
							<>
								<MenuItem onClick={handleMove}>Move</MenuItem>
								<MenuItem onClick={handleSnooze}>Snooze</MenuItem>
								{task.category === 'user' && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
							</>
						)}
					</MenuPopover>
				</Box>
			</Box>
		</SummaryFieldWrapper>
	);
};

export default Task;
