import { ChangeEvent, FC, useRef } from 'react';
import { Box } from '@mui/system';
import useLoading from 'src/hooks/useLoading';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { dispatch } from 'src/redux/store';
import { addBankAccountFiles, uploadUserBankAccountFilesThunk } from 'src/redux/slices/auth';
import { Typography } from '@mui/material';
import { FilesProps } from './types';
import File from '../File';

const Files: FC<FilesProps> = ({ files, id }) => {
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const inputRef = useRef<HTMLInputElement>(null);

	const handleUpload = () => inputRef.current?.click();
	const handleFilesUpload = async (e: ChangeEvent<HTMLInputElement>) => {
		try {
			setIsLoading(true);

			const newFiles = await dispatch(
				uploadUserBankAccountFilesThunk({ files: Array.from(e.target.files ?? []), id }),
			).unwrap();

			dispatch(addBankAccountFiles({ id, files: newFiles }));
			enqueueSnackbar('Bank account files uploaded successfully!');
		} catch (err) {
			if (err instanceof Error) enqueueSnackbar(err.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box mt='7px'>
			<Typography fontSize='12px' color='grey'>
				To comply with UK AML regulations for all active transactions using this bank account, you must provide
				statements for the last 3 months and one statement from at least 12 months ago to confirm the account has been
				active for at least a year.
			</Typography>
			<LoadingButton
				size='small'
				variant='contained'
				onClick={handleUpload}
				loading={isLoading}
				sx={{ mt: '5px', textTransform: 'initial' }}
			>
				Upload bank statements
			</LoadingButton>
			<input type='file' ref={inputRef} hidden onChange={handleFilesUpload} multiple />
			<Box mt='15px' display='flex' flexDirection='column' gap='5px'>
				{files.map((f) => (
					<File key={f.path} {...f} />
				))}
			</Box>
		</Box>
	);
};

export default Files;
