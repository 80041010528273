import { Box, IconButton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import Iconify from 'src/components/Iconify';
import useLoading from 'src/hooks/useLoading';
import { UserBankAccountFile } from 'src/redux/types';
import palette from 'src/theme/palette';
import { downloadBankAccountDocument } from 'src/utils/storage';

const File: FC<UserBankAccountFile> = ({ name, path }) => {
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();

	const handleDownload = async () => {
		try {
			setIsLoading(true);

			await downloadBankAccountDocument(name, path);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box
			px='7px'
			py='3px'
			sx={{ background: palette.light.info.lighter }}
			display='flex'
			justifyContent='space-between'
			alignItems='center'
		>
			<Typography fontWeight={600} display='block' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
				{name}
			</Typography>
			<Box>
				<IconButton disabled={isLoading} onClick={handleDownload}>
					{isLoading ? <Iconify icon='line-md:loading-loop' /> : <Iconify icon='material-symbols:download' />}
				</IconButton>
			</Box>
		</Box>
	);
};

export default File;
