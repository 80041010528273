import { FC, useState } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { MAX_FILE_SIZE } from 'src/constants';
import Image from 'src/components/Image';
import { usePhotoRef } from 'src/hooks/usePhotoRef';
import { dispatch } from 'src/redux/store';
import { login } from 'src/redux/slices/auth';
import { CircularLoader } from 'src/components/CircularLoader';
import { getCurrentUser, deleteProfilePicture, setProfilePicture } from 'src/api';
import { IAccountPhoto } from './types';
import { CommonTypo, LabelWithPhoto, PhotoDashedWrapper, PhotoWrapper, TypoUpload } from './styles';

export const AccountPhoto: FC<IAccountPhoto> = ({ photo }) => {
	const theme = useTheme();
	const link = usePhotoRef(photo);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<null | string>(null);

	const changeUser = async () => {
		const currentUser = await getCurrentUser();
		if (currentUser) dispatch(login(currentUser));
	};

	const onChange = async (e) => {
		setLoading(true);
		if (errorMessage) setErrorMessage(null);
		const file = e.target.files[0];
		if (file.size > MAX_FILE_SIZE) {
			setErrorMessage('The size of the photo is too big');
			setLoading(false);
			return;
		}
		if (file) {
			await setProfilePicture(file);
			await changeUser();
		}
		setLoading(false);
	};

	const onDelete = async () => {
		setLoading(true);
		await deleteProfilePicture();
		await changeUser();
		setLoading(false);
	};

	return (
		<Stack justifyContent='center' alignItems='center'>
			<PhotoDashedWrapper component='label'>
				<input type='file' accept='image/png, image/jpeg, image/jpg, image/gif' onChange={onChange} hidden />
				{loading ? (
					<CircularLoader />
				) : (
					<PhotoWrapper hasPhoto={!!photo}>
						{photo ? (
							<>
								{link && <Image src={link} sx={{ width: '100%', height: '100%' }} />}
								<LabelWithPhoto>
									<Iconify icon='material-symbols:add-a-photo' color={theme.palette.grey[0]} width={40} height={40} />
									<TypoUpload textColor={theme.palette.grey[0]}>Upload Photo</TypoUpload>
								</LabelWithPhoto>
							</>
						) : (
							<>
								<Iconify
									icon='material-symbols:add-a-photo'
									color={theme.palette.custom.bidyText}
									width={40}
									height={40}
								/>
								<TypoUpload textColor={theme.palette.custom.bidyText}>Upload Photo</TypoUpload>
							</>
						)}
					</PhotoWrapper>
				)}
			</PhotoDashedWrapper>
			<Box sx={{ margin: '26px 0 35px' }}>
				{errorMessage && <CommonTypo hasError>{errorMessage}</CommonTypo>}
				<CommonTypo>Allowed *.jpeg, *.jpg, *.png, *.gif</CommonTypo>
				<CommonTypo>Max size of 3.1 MB</CommonTypo>
			</Box>
			{photo && !loading && (
				<Stack flexDirection='row' alignItems='center' sx={{ cursor: 'pointer' }} onClick={onDelete}>
					<Iconify
						icon='material-symbols:delete-outline-rounded'
						color={theme.palette.custom.bidyText}
						width={24}
						height={24}
					/>
					<CommonTypo>Delete Photo</CommonTypo>
				</Stack>
			)}
		</Stack>
	);
};
