import { Box, Checkbox, Typography } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';

import { transactionSummaryColumns, transactionSummaryColumnsKeys } from 'src/pages/dashboard/constants/summary';
import { useSelector } from 'react-redux';
import { updateTransactionSummariesColumns } from 'src/redux/slices/tableSlice';
import { FC, useEffect, useState } from 'react';
import { TransactionSummaryColumn } from 'src/pages/dashboard/types/summary';
import { dispatch } from 'src/redux/store';
import { LoadingButton } from '@mui/lab';
import { selectUserSettings } from 'src/redux/slices/auth';
import { ColumnsForm, ColumnsSelectorProps } from './types';

const ColumnsSelector: FC<ColumnsSelectorProps> = ({ handleClose }) => {
	const [isLoading, setIsLoading] = useState(false);
	const settings = useSelector(selectUserSettings);

	const form = useForm<ColumnsForm>({
		defaultValues: {
			columns: [],
		},
	});

	const { fields, update } = useFieldArray({
		control: form.control,
		name: 'columns',
	});

	useEffect(() => {
		form.reset({
			columns: transactionSummaryColumnsKeys.map((key) => ({
				field: key,
				selected: settings.summariesColumns.includes(key),
			})),
		});
	}, [settings.summariesColumns]);

	const handleCheckboxChange = (field: TransactionSummaryColumn, selected: boolean, index: number) => () => {
		update(index, { field, selected: !selected });
	};

	const areAllSelected = fields.every(({ selected }) => selected);

	const handleSelectAll = () => {
		form.reset({
			columns: transactionSummaryColumnsKeys.map((key) => ({ field: key, selected: !areAllSelected })),
		});
	};

	const handleSave = async (data: ColumnsForm) => {
		setIsLoading(true);
		const selectedColumns = data.columns.reduce<TransactionSummaryColumn[]>((acc, column) => {
			if (column.selected) acc.push(column.field);

			return acc;
		}, []);

		await dispatch(updateTransactionSummariesColumns({ columns: selectedColumns }));

		setIsLoading(false);
		handleClose();
	};

	return (
		<form onSubmit={form.handleSubmit(handleSave)}>
			<Box px='15px' py='10px' maxHeight='300px' overflow='auto'>
				{fields.map(({ field, selected }, index) => (
					<Box key={field} display='flex' gap='5px' alignItems='center'>
						<Checkbox checked={selected} onChange={handleCheckboxChange(field, selected, index)} />
						<Typography>{transactionSummaryColumns[field].name}</Typography>
					</Box>
				))}
			</Box>
			<Box px='17px' pt='5px' pb='10px' display='flex' gap='20px' justifyContent='flex-end'>
				<LoadingButton variant='contained' onClick={handleSelectAll} loading={isLoading}>
					{areAllSelected ? 'Unselect All' : 'Select All'}
				</LoadingButton>
				<LoadingButton variant='contained' type='submit' loading={isLoading}>
					Save
				</LoadingButton>
			</Box>
		</form>
	);
};

export default ColumnsSelector;
