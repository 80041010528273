import { ITransactionParticipants, ITransactionPlaceholder, TransactionQuestionnaireFinance } from 'src/redux/types';
import { IQuestionnaireTransaction } from 'src/types';
import { getTransactionSellersHolders } from 'src/utils/transaction';
import { UserData } from 'src/api/auth/@user-data';

export const sellerFinance = (
	participants: ITransactionParticipants,
	placeholders: ITransactionPlaceholder[],
	transactionQuestionnaire: IQuestionnaireTransaction,
	user: UserData,
	finance: TransactionQuestionnaireFinance,
) => {
	const proprietors = getTransactionSellersHolders(participants, placeholders, transactionQuestionnaire);

	return {
		logoPosition: 'right',
		pages: [
			{
				title: 'Ownership',
				name: 'ownership',
				elements: [
					{
						type: 'radiogroup',
						name: 'ownership_type',
						title: 'How do you own your property? *',
						hideNumber: true,
						isRequired: true,
						choices: ['Owned outright', 'Owned with a mortgage', 'Help-to-buy', 'Shared ownership'],
						showOtherItem: true,
					},
					{
						type: 'html',
						name: 'notice_sale_note',
						visibleIf: "{ownership_type} = 'Owned with a mortgage'",
						html: "Thank you. We'll ask you to upload a copy of your latest <b>Annual Mortgage Statement</b> in the <b>Documents</b> tab.",
					},
				],
			},
			{
				name: 'banking',
				title: 'Banking',
				elements: [
					...finance.sellers.map((s) => {
						const isCurrentUser = user.uid === s.id;

						return {
							type: 'panel',
							name: s.id,
							title: `${s.givenNames} ${s.lastNames}${isCurrentUser ? ' *' : ''}`,
							...(isCurrentUser && { description: 'Please select any bank accounts you intend to use for this sale' }),
							readOnly: !isCurrentUser && !user.isAdmin,
							elements: [
								{
									type: 'tagbox',
									name: `${s.id}_accounts`,
									title: `Bank accounts${isCurrentUser ? ' *' : ''}`,
									description:
										'Select all bank accounts you plan to use for this transaction. To add a bank account, head to your profile and open the Bank Accounts tab.',
									isRequired: true,
									choices: s.bankAccounts.map((a) => ({ text: a.label, value: a.id })),
								},
							],
						};
					}),
					{
						type: 'html',
						name: 'bank_accounts_note',
						html: "To meet compliance requirements, we must ensure that bank accounts belong to the sellers, have been active for at least one year, and that their activity aligns with expected financial norms for these individuals. <br></br>In the <b>Documents</b> tab, you'll be required to provide <b>bank statements</b> for each of the accounts above: one statement for each of the <b>last 3 months</b> and one statement <b>going back at least 12 months</b>.",
					},
				],
			},
			{
				name: 'charges',
				elements: [
					{
						type: 'paneldynamic',
						name: 'mortgages',
						title: 'Mortgages *',
						description: 'Which mortgages are currently associated with this property?',
						hideNumber: true,
						isRequired: true,
						templateElements: [
							{
								type: 'panel',
								name: 'mortgage',
								elements: [
									{
										type: 'tagbox',
										name: 'holders',
										title: 'Mortgage holder(s) *',
										isRequired: true,
										choices: proprietors,
									},
									{
										type: 'text',
										name: 'lender',
										title: 'Lender *',
										titleLocation: 'left',
										isRequired: true,
									},
									{
										type: 'text',
										name: 'mortgage_account_number',
										title: 'Mortgage account number *',
										titleLocation: 'left',
										hideNumber: true,
										isRequired: true,
										validators: [
											{
												type: 'regex',
												text: 'Invalid entry: Please provide a genuine input.',
												regex: '^(?=.*\\d)[A-Za-z\\d@$!%*?&]{8,}$',
											},
										],
									},
									{
										type: 'text',
										name: 'amount_borrowed',
										title: 'Amount borrowed *',
										titleLocation: 'left',
										description: 'In Pounds',
										hideNumber: true,
										isRequired: true,
										maskType: 'numeric',
										min: 1000,
										maskSettings: { allowNegativeValues: false },
									},
									{
										type: 'text',
										name: 'balance',
										title: 'Current mortgage balance *',
										titleLocation: 'left',
										description: 'In Pounds',
										hideNumber: true,
										isRequired: true,
										maskType: 'numeric',
										maskSettings: { allowNegativeValues: false },
									},
								],
								title: 'Mortgage details',
								isRequired: true,
							},
						],
						noEntriesText: '',
						panelAddText: 'Add mortgage',
					},
				],
				title: 'Charges',
				visibleIf: "{ownership_type} = 'Owned with a mortgage'",
			},
		],
		completeText: 'Submit',
	};
};
