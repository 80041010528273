import { FC } from 'react';
import { Box } from '@mui/system';
import { ProgressStatusProps } from './types';

const ProgressStatus: FC<ProgressStatusProps> = ({ status }) => {
	const label = status === 'done' ? '🟢' : status === 'pending' ? '🔴' : status === 'notStarted' ? '⚪️' : null;

	return <Box sx={{ fontSize: '20px' }}>{label}</Box>;
};

export default ProgressStatus;
