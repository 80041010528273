import { FC } from 'react';
import { Button } from '@mui/material';
import { dispatch } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { UserChangeEmailRequestStatusEnum } from 'src/types';
import { StatusFieldProps } from './types';

const StatusField: FC<StatusFieldProps> = ({ user }) => {
	if (!user.changeEmailRequest) return user.status;

	if (user.changeEmailRequest.status === UserChangeEmailRequestStatusEnum.APPROVED) return 'PENDING EMAIL CHANGE';

	if (user.changeEmailRequest.status === UserChangeEmailRequestStatusEnum.ACTIVE) {
		const handleClick = () => {
			dispatch(openModal({ name: modals.manageEmailChangeRequest, payload: user }));
		};

		return <Button onClick={handleClick}>EMAIL CHANGE REQUESTED</Button>;
	}

	return user.status;
};

export default StatusField;
