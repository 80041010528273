import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Stack, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { MESSAGE_INCORRECT_PASS } from 'src/constants/errors';
import { changeUserPassword } from 'src/api';

const ChangePassWordSchema = Yup.object().shape({
	oldPassword: Yup.string().required('Old Password is required'),
	newPassword: Yup.string()
		.required('New Password is required')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
			'At least 8 characters, 1 uppercase, 1 lowercase & 1 number.',
		),
	confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

export default function AccountChangePassword() {
	const { enqueueSnackbar } = useSnackbar();

	const methods = useForm({
		resolver: yupResolver(ChangePassWordSchema),
		defaultValues: { oldPassword: '', newPassword: '', confirmNewPassword: '' },
	});

	const {
		reset,
		handleSubmit,
		setError,
		formState: { isSubmitting },
	} = methods;

	const onSubmit = async ({ oldPassword, newPassword }) => {
		try {
			await changeUserPassword(oldPassword, newPassword);
			reset();
			enqueueSnackbar('Update success!');
		} catch (error) {
			setError('oldPassword', { message: MESSAGE_INCORRECT_PASS });
			console.error(error);
		}
	};

	return (
		<Card sx={{ p: 3 }}>
			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<Stack spacing={3} alignItems='flex-end'>
					<RHFTextField name='oldPassword' type='password' label='Old Password' />

					<RHFTextField name='newPassword' type='password' label='New Password' />

					<RHFTextField name='confirmNewPassword' type='password' label='Confirm New Password' />

					<LoadingButton type='submit' variant='contained' loading={isSubmitting}>
						Save Changes
					</LoadingButton>
				</Stack>
			</FormProvider>
		</Card>
	);
}
