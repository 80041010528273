import Page from 'src/components/Page';
import { BrandCard, RHFOtpInput } from 'src/components';
import { Box, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import useError from 'src/hooks/useError';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import confirmResetPassword from 'src/api/auth/confirm-reset-password';
import { ContinueButton, TextInput, AuthPage } from '../styles';
import { confirmResetPasswordValidationSchema } from './validation';
import { ConfirmResetPasswordFormType } from './types';

const ConfirmResetPassword = () => {
	const { error, setError } = useError();
	const { enqueueSnackbar } = useSnackbar();
	const location = useLocation();
	const navigate = useNavigate();
	const form = useForm<ConfirmResetPasswordFormType>({
		resolver: yupResolver(confirmResetPasswordValidationSchema),
		defaultValues: {},
	});

	useEffect(() => {
		if (!location.state?.email) navigate('/auth/login');
	}, [location.state?.email]);

	const handleSubmit = async (data: ConfirmResetPasswordFormType) => {
		try {
			setError(null);

			await confirmResetPassword(location.state?.email, data.code, data.password);
			enqueueSnackbar('Password changed successfully!');
			navigate('/auth/login');
		} catch (e) {
			console.error(e);

			if (e instanceof Error) setError(e.message);
		}
	};

	return (
		<FormProvider {...form}>
			<Page title='Reset password'>
				<AuthPage>
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<BrandCard title="Can't log in?">
							{error}
							<Box width='100%'>
								<Typography variant='caption' textAlign='left' fontWeight={500} width='100%'>
									Enter verification code you received on <b>{location.state?.email}</b>
								</Typography>
							</Box>
							<RHFOtpInput name='code' />
							<TextInput name='password' placeholder='Enter new password' />
							<ContinueButton variant='contained' fullWidth type='submit' loading={form.formState.isSubmitting}>
								Change password
							</ContinueButton>
							<Box
								display='flex'
								justifyContent='space-around'
								gap='10px'
								alignItems='center'
								color='grey.700'
								mt='5px'
							>
								<Typography
									component={Link}
									to='/auth/login'
									variant='caption'
									color='info.dark'
									sx={{ textDecoration: 'none' }}
								>
									Return to log in
								</Typography>
							</Box>
						</BrandCard>
					</form>
				</AuthPage>
			</Page>
		</FormProvider>
	);
};

export default ConfirmResetPassword;
