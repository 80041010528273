import { Country, UserBankAccount } from 'src/redux/types';

export const checkIsBankAccountValid = (countries: Country[], account) => {
	const country = countries.find((c) => c['2LetterCode'] === account.country);

	if (!country) return '';

	return country.banking.fields.every((f) => !!account[f.key]);
};

export const getBankAccountLabel = (countries: Country[], account: UserBankAccount, withBank = true) => {
	const country = countries.find((c) => c['2LetterCode'] === account.country['2LetterCode']);

	if (!country) return '';

	let label = `${withBank ? `${account.bank} ` : ''}`;

	country.banking.fields.forEach((f) => {
		const value = account[f.key];

		if (value) label += `${value} `;
	});

	return label;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getBankAccountElements = (countries: Country[], additionalElements?: any[]) => {
	const countriesOptions = countries.map((c) => ({ text: c.name, value: c['2LetterCode'] }));

	const elements = [
		{
			type: 'text',
			name: 'id',
			title: 'Id *',
			isRequired: true,
			defaultValueExpression: 'randomId()',
		},
		{
			type: 'dropdown',
			name: 'country',
			title: 'Country *',
			titleLocation: 'left',
			choices: countriesOptions,
			defaultValue: 'GB',
			isRequired: true,
		},
		...(additionalElements ?? []),
		{
			type: 'text',
			name: 'bank',
			title: 'Bank *',
			titleLocation: 'left',
			isRequired: true,
		},
	];

	countries.forEach((c) => {
		c.banking.fields.forEach((f) => {
			const isExistingElementKeyIndex = elements.findIndex((e) => e.name === f.key);

			if (isExistingElementKeyIndex !== -1) {
				elements[isExistingElementKeyIndex].visibleIf += `OR {panel.country} = ${c['2LetterCode']}`;
				elements[isExistingElementKeyIndex].requiredIf += `OR {panel.country} = ${c['2LetterCode']}`;

				return;
			}

			elements.push({
				type: 'text',
				name: f.key,
				visibleIf: `{panel.country} = ${c['2LetterCode']}`,
				title: `${f.name} *`,
				titleLocation: 'left',
				hideNumber: true,
				isRequired: true,
				requiredIf: `{panel.country} = ${c['2LetterCode']}`,
				validators: [{ type: 'regex', text: f.error, regex: f.regex }],
				placeholder: f.placeholder,
			});
		});
	});

	return elements;
};
