import { Box, Table, TableBody, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import HeadCell from 'src/components/HeadCell';
import Progress from 'src/components/Progress';
import { TableCell, TableHead, TableRow } from 'src/pages/dashboard/styles';
import { getMonthlyReportsThunk, selectMonthlyReports, sortMonthlyReports } from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import Iconify from 'src/components/Iconify';
import useLoading from 'src/hooks/useLoading';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { downloadCsv } from 'src/utils/csv';
import moment from 'moment';
import { getMonthlyReportSortableRow } from './utils';

const MonthlyTable = () => {
	const { data: reports, isLoading: areReportsLoading, error } = useSelector(selectMonthlyReports);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();

	const handleSortChange = ({ order, orderBy }) => dispatch(sortMonthlyReports({ order, orderBy }));

	useEffect(() => {
		dispatch(getMonthlyReportsThunk());
	}, []);

	if (areReportsLoading) return <Progress />;
	if (error || !reports.data) {
		return (
			<Box p='15px'>
				<Typography color='red' variant='h4'>
					{error?.message ?? 'Something went wrong'}
				</Typography>
			</Box>
		);
	}

	const totalRow = getMonthlyReportSortableRow(reports.data.total);

	const handleDownload = async () => {
		try {
			if (!reports.data) return;

			setIsLoading(true);

			const rows = [
				[
					'Month',
					'New transactions',
					// 'Live transactions',
					'Withdrawals',
					'Completions',
					'Defunct',
					'Open cash (incl VAT)',
					'Closed cash (incl VAT)',
					'Old cash (incl VAT)',
				],
				...(reports.data.rows.map((r) => [
					r.date.label,
					r.newTransactions.label,
					r.withdrawals.label,
					r.completions.label,
					r.defunct.label,
					r.openCash.label,
					r.closedCash.label,
					r.oldCash.label,
				]) ?? []),
				[
					'TOTAL',
					totalRow.newTransactions.label,
					totalRow.withdrawals.label,
					totalRow.completions.label,
					totalRow.defunct.label,
					totalRow.openCash.label,
					totalRow.closedCash.label,
					totalRow.oldCash.label,
				],
			];

			const date = moment().format('YYYY_MM_DD');

			downloadCsv(`monthly_report_${date}.csv`, rows);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box>
			<Table>
				<TableHead>
					<TableRow>
						<HeadCell
							name='Month'
							columnKey='date'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
						/>
						<HeadCell
							name='New transactions'
							columnKey='newTransactions'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
						/>
						{/* <HeadCell
							name='Live transactions'
							columnKey='liveTransactions'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
						/> */}
						<HeadCell
							name='Withdrawals'
							columnKey='withdrawals'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
						/>
						<HeadCell
							name='Completions'
							columnKey='completions'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
						/>
						{/* <HeadCell
							name='New instructions'
							columnKey='newInstructions'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
						/> */}
						<HeadCell
							name='Open cash (incl VAT)'
							columnKey='openCash'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
						/>
						<HeadCell
							name='Closed cash (incl VAT)'
							columnKey='closedCash'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
						/>
						<HeadCell
							name='Old cash (incl VAT)'
							columnKey='closedCash'
							handleSortChange={handleSortChange}
							order={reports.order}
							orderBy={reports.orderBy}
						/>
					</TableRow>
				</TableHead>
				<TableBody>
					{reports.data.rows.map((row) => (
						<TableRow key={row.date.value?.toString()}>
							<TableCell>{row.date.label}</TableCell>
							<TableCell>{row.newTransactions.label}</TableCell>
							<TableCell>{row.withdrawals.label}</TableCell>
							<TableCell>{row.completions.label}</TableCell>
							{/* <TableCell>{row.newInstructions.label}</TableCell> */}
							<TableCell>{row.openCash.label}</TableCell>
							<TableCell>{row.closedCash.label}</TableCell>
							<TableCell>{row.oldCash.label}</TableCell>
						</TableRow>
					))}
					{!!reports.data.rows.length && (
						<TableRow isActive>
							<TableCell>TOTAL</TableCell>
							<TableCell>{totalRow.newTransactions.label}</TableCell>
							<TableCell>{totalRow.withdrawals.label}</TableCell>
							<TableCell>{totalRow.completions.label}</TableCell>
							{/* <TableCell>{totalRow.newInstructions.label}</TableCell> */}
							<TableCell>{totalRow.openCash.label}</TableCell>
							<TableCell>{totalRow.closedCash.label}</TableCell>
							<TableCell>{totalRow.oldCash.label}</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<Box pt='10px'>
				<LoadingButton variant='contained' onClick={handleDownload} loading={isLoading}>
					Download CSV
					<Iconify icon='mdi:file' fontSize={20} ml='5px' />
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default MonthlyTable;
