import { call } from '../@';

export type AskForCompanyBranchHelpDto = {
	companyId: string;
	branchId: string;
};

export default async function askForCompanyBranchHelp(dto: AskForCompanyBranchHelpDto) {
	return await call('company-branch-ask-for-help', dto);
}
