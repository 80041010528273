import { Box, Tab, Tabs } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { getStringAfterSymbols } from 'src/utils/strings';
import { tabs } from './constants';

const ReportsTable = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const currentPathTab = getStringAfterSymbols(location.pathname, `dashboard/reports/`);

	useEffect(() => {
		if (!currentPathTab) navigate('/dashboard/reports/monthly', { replace: true });
	}, [currentPathTab]);

	const handleTabChange = (_, tab: string) => navigate(`/dashboard/reports/${tab}`);

	return (
		<>
			<Box pt='10px'>
				<Tabs
					allowScrollButtonsMobile
					variant='scrollable'
					scrollButtons='auto'
					value={currentPathTab}
					onChange={handleTabChange}
				>
					{tabs.map((tab) => (
						<Tab disableRipple key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
					))}
				</Tabs>
			</Box>
			<Box height='100%' width='100%'>
				<Outlet />
			</Box>
		</>
	);
};

export default ReportsTable;
