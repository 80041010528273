import { Box, styled, Typography } from '@mui/material';
import DocumentOverview from '../DocumentOverview';
import { DocumentOverviewProps } from '../DocumentOverview/types';

export const Title = styled(Typography)(({ theme }) => ({
	fontSize: '17px',
	fontWeight: 700,
	wordBreak: 'break-word',
	color: theme.palette.grey[800],
	display: '-webkit-box',
	'-webkit-line-clamp': '3',
	'-webkit-box-orient': 'vertical',
	overflow: 'hidden',
}));

export const NestedDocumentOverview = styled((props: DocumentOverviewProps) => <DocumentOverview {...props} />)({
	paddingTop: '10px',
	display: 'flex',
	flexDirection: 'row',
	gap: '20px',
	flexWrap: 'wrap',
});

export const Section = styled(Box)({
	flex: 1,
	flexBasis: '100%',
});

const Base = styled(Box)(({ theme }) => ({
	position: 'relative',
	width: '230px',
	height: '130px',
	backgroundColor: theme.palette.primary.lighter,
	borderRadius: '7px',
	padding: '10px 14px',
}));

export const Folder = styled(Base)(({ theme }) => ({
	backgroundColor: theme.palette.warning.lighter,
	cursor: 'pointer',
}));

export const File = styled(Base)({});

export const EmptyIndicator = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: '-3px',
	right: '-3px',
	width: '10px',
	height: '10px',
	borderRadius: '50%',
	background: theme.palette.error.main,
}));

export const Actions = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	position: 'absolute',
	bottom: '8px',
	right: '10px',
	left: '15px',
});
