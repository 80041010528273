import { Box } from '@mui/material';
import { FC } from 'react';
import { LoadingViewProps } from './types';
import Progress from '../Progress';

const LoadingView: FC<LoadingViewProps> = ({ isLoading, children, ...other }) => {
	if (!isLoading)
		return (
			<Box height='100%' width='100%' {...other}>
				{children}
			</Box>
		);

	return (
		<Box height='100%' width='100%' {...other}>
			<Progress />
		</Box>
	);
};

export default LoadingView;
