import { Table, TableBody } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ErrorView from 'src/components/ErrorView';
import LoadingView from 'src/components/LoadingView';
import { getProperties, selectProperties } from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import { Link } from 'react-router-dom';
import { PATH_PROPERTIES } from 'src/routes/paths';
import { formatUsers } from 'src/utils/transaction/summary';
import { selectUserSettings } from 'src/redux/slices/auth';
import { TableCell, TableRow, TableHead } from '../../styles';
import PinCell from '../PinCell';

const PropertiesTable = () => {
	const properties = useSelector(selectProperties);
	const settings = useSelector(selectUserSettings);

	useEffect(() => {
		dispatch(getProperties({ withLoading: true }));
	}, []);

	if (properties.error) return <ErrorView error={properties.error} />;

	return (
		<LoadingView isLoading={properties.isLoading}>
			<Table sx={{ width: '600px' }}>
				<TableHead>
					<TableRow>
						<TableCell padding='none' width={50} />
						<TableCell>Name</TableCell>
						<TableCell>Postcode</TableCell>
						<TableCell>Proprietors</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{properties.data.map((row) => {
						const isPinned = settings.pinnedProperties.some(({ id }) => id === row.id);

						return (
							<TableRow isActive={isPinned} key={row.id} whiteSpace='nowrap'>
								<PinCell id={row.id} isPinned={isPinned} />
								<TableCell>
									<Link to={PATH_PROPERTIES.view(row.id)}>{row.address.displayAddress}</Link>
								</TableCell>
								<TableCell>{row.address?.postcode}</TableCell>
								<TableCell>{formatUsers(row.proprietors)}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</LoadingView>
	);
};

export default PropertiesTable;
