import modals from 'src/constants/modals';
import Modal from 'src/components/customModal';
import { noop } from 'lodash';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectModal } from 'src/redux/slices/modal';

const HelpRequestedModal = () => {
	const { payload } = useSelector(selectModal<{ companyName: string; branchName?: string }>);

	return (
		<Modal name={modals.helpRequested} cardSx={{ overflow: 'visible', maxWidth: '600px' }} onClose={noop}>
			<Typography sx={styledMainTitle} pt={0}>
				HELP IS ON THE WAY
			</Typography>
			<Typography pt={0} fontWeight={500} mb='15px'>
				Thank you for reaching out. Our team will contact you shortly to assist. <br />
				<br />
				This message will remain visible until the restriction on{' '}
				{payload.branchName ? `branch ${payload.companyName} of ${payload.companyName}` : `${payload.companyName}`} is
				lifted.
			</Typography>
		</Modal>
	);
};

export default HelpRequestedModal;
