import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const CenterBox = styled(Box)<{ height?: string }>(({ height = '100%' }) => ({
	display: 'flex',
	height,
	width: '100%',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const LoadingButtonStyle = styled(LoadingButton)(() => ({
	height: '56px',
	maxWidth: '204px',
	width: '100%',
	boxShadow: 'none',
}));

export const BoxAccountGeneralStyle = styled(Box)({
	display: 'grid',
	rowGap: '35px',
	columnGap: '15px',
	gridTemplateColumns: 'repeat(2, 1fr)',
	marginBottom: '50px',
	width: '100%',
});
