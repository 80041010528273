import { TransactionSummaryByColumns, TransactionSummaryColumn } from 'src/pages/dashboard/types/summary';
import { formatDaysDuration, formatPercent, formatPrice } from 'src/utils/transaction/summary';

export const calculateSummariesMedian = (
	summaries: TransactionSummaryByColumns[],
	getter: (s: TransactionSummaryByColumns) => unknown,
) => {
	const numbers = summaries.reduce<number[]>((acc, summary) => {
		const value = getter(summary);
		if (typeof value === 'number') acc.push(value);

		return acc;
	}, []);

	if (!numbers.length) return null;

	const sorted = numbers.sort((a, b) => a - b);
	const middle = Math.floor(sorted.length / 2);

	if (sorted.length % 2 === 0) {
		return (sorted[middle - 1] + sorted[middle]) / 2;
	}

	return sorted[middle];
};

export const calculateSummariesSum = (
	summaries: TransactionSummaryByColumns[],
	getter: (s: TransactionSummaryByColumns) => unknown,
) =>
	summaries.reduce<number>((acc, summary) => {
		const value = getter(summary);

		// eslint-disable-next-line no-param-reassign
		if (typeof value === 'number') acc += value as number;

		return acc;
	}, 0);

export const calculateTransactionSummariesTotals = (
	summaries: TransactionSummaryByColumns[],
): Partial<Record<TransactionSummaryColumn, number | string>> => ({
	timeFromInstructionToSaleAgreed: formatDaysDuration(
		calculateSummariesMedian(summaries, (s) => s.timeFromInstructionToSaleAgreed.value),
	),
	timeFromSaleAgreedToExchange: formatDaysDuration(
		calculateSummariesMedian(summaries, (s) => s.timeFromSaleAgreedToExchange.value),
	),
	duration: formatDaysDuration(calculateSummariesMedian(summaries, (s) => s.duration.value)),
	askPrice: formatPrice(calculateSummariesSum(summaries, (s) => s.askPrice.value)),
	ourMargin: formatPrice(calculateSummariesSum(summaries, (s) => s.ourMargin.value)),
	sellerQuote: formatPrice(calculateSummariesSum(summaries, (s) => s.sellerQuote.value)),
	sellSideAgencyRate: formatPercent(calculateSummariesMedian(summaries, (s) => s.sellSideAgencyRate.value)),
	sellSideAgencyFee: formatPrice(calculateSummariesSum(summaries, (s) => s.sellSideAgencyFee.value)),
	sellSideReferralFee: formatPrice(calculateSummariesSum(summaries, (s) => s.sellSideReferralFee.value)),
	sellSideLegalFee: formatPrice(calculateSummariesSum(summaries, (s) => s.sellSideLegalFee.value)),
	buyerQuote: formatPrice(calculateSummariesSum(summaries, (s) => s.buyerQuote.value)),
	buySideAgencyRate: formatPercent(calculateSummariesMedian(summaries, (s) => s.buySideAgencyRate.value)),
	buySideAgencyFee: formatPrice(calculateSummariesSum(summaries, (s) => s.buySideAgencyFee.value)),
	buySideReferralFee: formatPrice(calculateSummariesSum(summaries, (s) => s.buySideReferralFee.value)),
	buySideLegalFee: formatPrice(calculateSummariesSum(summaries, (s) => s.buySideLegalFee.value)),
});
