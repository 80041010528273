import Iconify from 'src/components/Iconify';
import MonthlyTable from './components/Monthly';
import SolicitorsTable from './components/Solicitors';
import ProgressTable from './components/Progress';

export const tabs = [
	{
		value: 'monthly',
		label: 'Monthly',
		icon: <Iconify icon='material-symbols:calendar-month-outline' width={20} height={20} />,
		component: MonthlyTable,
	},
	{
		value: 'solicitors',
		label: 'Solicitors',
		icon: <Iconify icon='material-symbols:person' width={20} height={20} />,
		component: SolicitorsTable,
	},
	{
		value: 'progress',
		label: 'Progress',
		icon: <Iconify icon='ri:progress-1-line' width={20} height={20} />,
		component: ProgressTable,
	},
];
