import { VerificationSession, VerificationSessionDocument } from 'src/types';

export const getNameVerificationDocument = (
	session: VerificationSession,
): VerificationSessionDocument | null | undefined => {
	if (!session.documents) return null;

	const documents = [
		...(session.documents.passports ?? []),
		...(session.documents.drivingLicences ?? []),
		...(session.documents.other ?? []),
	];

	return documents.find((d) => !!d.full_name);
};

export const getAddressVerificationDocument = (
	session: VerificationSession,
): VerificationSessionDocument | null | undefined => {
	if (!session.documents) return null;

	const documents = [...(session.documents.other ?? [])];

	const matchingDocument = documents.find((d) => !!d.structured_postal_address);

	if (!matchingDocument) return null;

	const extendedDocument = { ...matchingDocument };
	const otherDocument = session.documents.other?.find((d) => !!d.structured_postal_address);

	if (otherDocument && !matchingDocument.structured_postal_address?.street) {
		const address = otherDocument.structured_postal_address;
		let street = address?.street;

		if (address && !street && address.building_number) {
			if (address.address_line1 && address.address_line1.includes(address.building_number)) {
				street = address.address_line1.replace(address.building_number, '');
			} else if (address.address_line2 && address.address_line2.includes(address.building_number)) {
				street = address.address_line2.replace(address.building_number, '');
			}
		}

		extendedDocument.structured_postal_address = {
			...matchingDocument.structured_postal_address,
			street: street || matchingDocument.structured_postal_address?.street,
		};
	}

	return extendedDocument as VerificationSessionDocument;
};
